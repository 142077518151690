import { useEffect, useState } from "react"
import { Button, Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import { useGameContext } from "../../../Commons/GameContext"
import { shuffleArray } from "../../../Commons/Functions"
import { useNavigate } from "react-router"

export default function DeckPickerModal(props) {
    const [selectedDeck, setSelectedDeck] = useState(false)
    const [availableDecks, setAvailableDecks] = useState([])
    const [showMulliganSection, setShowMulliganSection] = useState(false)
    const navigate = useNavigate()

    useEffect(() => {
        const currentGame = props.currentGame
        if (!currentGame || !currentGame.selected) { return }

        const key = "user_decks_" + currentGame.selected
        let decksSavedData = localStorage.getItem(key);

        let newAvailableDecks = []

        if (decksSavedData) {
            const decksSaved = JSON.parse(decksSavedData);

            decksSaved.forEach(deckId => {
                const newDeckData = localStorage.getItem(key + '_' + deckId);
                let parsedDeckData = JSON.parse(newDeckData)
                if (parsedDeckData.format == props.gameOptions.format.title) {
                    newAvailableDecks.push(parsedDeckData)
                }
            })
            if (newAvailableDecks.length > 0 && !selectedDeck) {
                setSelectedDeck(newAvailableDecks[0].id)
            }
        } else {
            console.log("ERROR: No deck selected");
        }
        setAvailableDecks(newAvailableDecks)
    }, [props.gameOptions])

    if (showMulliganSection) {
        return (<MulliganSection
            currentGame={props.currentGame}
            gameData={props.gameData}
            createGameCardFromCardData={props.createGameCardFromCardData}
            sendToHistory={props.sendToHistory}
            selectedDeck={selectedDeck}
            show={props.show}
            handleClose={props.handleClose}
            start={props.start}
            gameOptions={props.gameOptions}
            onExited={() => {
                setShowMulliganSection(false)
                props.started()
            }}
        />)
    }

    return (
        <Modal className="" backdrop="static" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header>
                <Modal.Title>Deck selection</Modal.Title>
            </Modal.Header>
            {props.gameOptions && (<Modal.Body className='d-flex justify-content-between'>
                <p><b>Format:</b> {props.gameOptions.format.title}</p>
                <p><b>Starting player:</b> {props.gameOptions.startingPlayer.name}</p>
            </Modal.Body>)}
            {availableDecks.length == 0 && (
                <Modal.Body>
                    <p>You don't have any decks for this format.</p>
                </Modal.Body>
            )}
            {availableDecks.length > 0 && (
                <Modal.Body>
                    <FloatingLabel className="" controlId={"format-selection"} label={"Choose your deck"}>
                        <Form.Select aria-label="Format select"
                            defaultValue={selectedDeck}
                            onChange={(e) => {
                                setSelectedDeck(e.currentTarget.value)
                            }}
                        >
                            {availableDecks.map((v, j) => {
                                return (<option key={v.id} value={v.id}>{v.title}</option>)
                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Modal.Body>
            )}
            <Modal.Footer>
                {availableDecks.length == 0 ?
                    (
                        <Button variant="danger" onClick={() => {
                            navigate("/")
                            //props.handleClose()
                        }}>
                            Back to main menu
                        </Button>
                    )
                    : (
                        <Button variant="primary" onClick={() => {
                            setShowMulliganSection(true)
                            //props.handleClose()
                        }}>
                            Continue
                        </Button>
                    )}
            </Modal.Footer>
        </Modal>
    )
}

function MulliganSection(props) {
    const { gameCards } = useGameContext()
    const [deck, setDeck] = useState([])
    const [hand, setHand] = useState([])
    const [cardsAlreadyOnBoard, setCardsAlreadyOnBoard] = useState([])
    const [selectedCardIndexes, setSelectedCardIndexes] = useState([])
    const mulliganInfo = props.gameOptions.format.mulligan ? props.gameOptions.format.mulligan : {
        startingHandSize: 7,
        info: "ERROR Loading mulligan info"
    }
    const handSize = mulliganInfo.startingHandSize

    useEffect(() => {
        const currentGame = props.currentGame
        const key = "user_decks_" + currentGame.selected
        const newDeckData = localStorage.getItem(key + '_' + props.selectedDeck);
        if (newDeckData) {
            const sectionsFile = props.gameOptions.format.sections
            const deckData = JSON.parse(newDeckData);

            deckData.deckList.categoriesOrder.forEach(cat => {
                let newCat = []
                deckData.deckList[cat].forEach((card, i) => {
                    if (gameCards[card.id]) {
                        let cardData = { ...gameCards[card.id] }
                        cardData.count = card.count
                        newCat.push(cardData)
                    }
                })
                deckData.deckList[cat] = newCat
            })

            let newDeck = []
            let newCardsOnBoard = []

            deckData.deckList.categoriesOrder.forEach((cat) => {
                let startOnBoard = false
                if (sectionsFile.categoriesAlreadyOnBoard && sectionsFile.categoriesAlreadyOnBoard.includes(cat)) {
                    startOnBoard = true
                }
                deckData.deckList[cat].forEach((c) => {
                    for (let i = 0; i < c.count; i++) {
                        const newCard = props.createGameCardFromCardData(c)
                        if (startOnBoard) {
                            newCardsOnBoard.push({ card: newCard, section: cat })
                        } else {
                            newDeck.push(newCard)
                        }
                    }
                })
            })

            shuffleArray(newDeck)
            let newCards = newDeck.splice(-handSize)
            setHand(newCards)
            setDeck(newDeck)
            setCardsAlreadyOnBoard(newCardsOnBoard)
        }
    }, [])

    function toggleSelectCard(index) {
        // Dont do anything if there are no action for selected cards
        if (!mulliganInfo.drawNewSelectedCards && !mulliganInfo.putSelectionAtBottom) {
            return
        }

        let tmp = [...selectedCardIndexes]
        let indexInTheIndexesFromTheArrayFullOfIndexes = tmp.findIndex(a => a == index)
        if (indexInTheIndexesFromTheArrayFullOfIndexes != -1) {
            tmp.splice(indexInTheIndexesFromTheArrayFullOfIndexes, 1)
        } else {
            tmp.push(index)
        }
        setSelectedCardIndexes(tmp)
    }

    return (<Modal className="fullscreen-modal" backdrop="static" show={props.show} onHide={props.handleClose} centered onExited={props.onExited}>
        <Modal.Header>
            <Modal.Title>Mulligan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>{mulliganInfo.info}</p>
            <div className='d-flex flex-column mulligan-sections w-100 position-relative'>
                <Row className='pe-none mulligan-section w-100 position-absolute start-0 top-0 m-0'>
                    {hand.slice(0, 4).map((c, i) => {
                        return (<Col xs="3" key={c.id}
                            className={"h-100 wrapper mx-auto d-flex justify-content-center" + (selectedCardIndexes.includes(i) ? " selected" : "")}
                            onClick={() => {
                                toggleSelectCard(i)
                            }}
                        >
                            <div>
                                <img
                                    className="h-100"
                                    src={c.cardData.face.front.image}
                                />
                            </div>
                        </Col>)
                    })}
                </Row>
                <Row className="pe-none mulligan-section position-absolute start-0 bottom-0" style={{ width: "75%", marginLeft: "12.5%", marginRight: "12.5%" }}>
                    {hand.slice(4, hand.length).map((c, i) => {
                        return (<Col xs="3" key={c.id}
                            className={"h-100 wrapper mx-auto d-flex justify-content-center" + (selectedCardIndexes.includes(i + 4) ? " selected" : "")}
                            onClick={() => {
                                toggleSelectCard(i + 4)
                            }}
                        >
                            <div>
                                <img
                                    className="h-100"
                                    src={c.cardData.face.front.image}
                                />
                            </div>
                        </Col>)
                    })}
                    {[...Array(7 - hand.length)].map(n => (
                        <Col xs="3" className="mx-auto" key={n}></Col>
                    ))}
                </Row>
            </div>
            <div className="d-flex my-3">
                {selectedCardIndexes.length > 0 && mulliganInfo.drawNewSelectedCards && (<Button variant="secondary" onClick={() => {
                    props.sendToHistory("drew again " + selectedCardIndexes.length + " cards")
                    const indexesToRemove = [...selectedCardIndexes]
                    indexesToRemove.sort((a, b) => b - a);

                    let newHand = [...hand]
                    let removed = []
                    indexesToRemove.forEach(index => {
                        removed.push(newHand.splice(index, 1)[0]);
                    });
                    let newDeck = [...deck]
                    let newCards = newDeck.splice(-selectedCardIndexes.length)
                    setHand([...newHand, ...newCards])

                    newDeck = [...newDeck, ...removed]
                    shuffleArray(newDeck)
                    setDeck(newDeck)
                    setSelectedCardIndexes([])
                }}>
                    Draw those cards again ({selectedCardIndexes.length})
                </Button>)}
                {mulliganInfo.drawNewHand && (<Button variant="secondary" onClick={() => {
                    props.sendToHistory("drew a new hand")
                    let newDeck = [...deck, ...hand]
                    shuffleArray(newDeck)
                    let newCards = newDeck.splice(-handSize)
                    setHand(newCards)
                    setDeck(newDeck)
                    setSelectedCardIndexes([])
                }}>
                    Draw new hand
                </Button>)}
            </div>
        </Modal.Body>
        <Modal.Footer>
            {selectedCardIndexes.length > 0 && mulliganInfo.putSelectionAtBottom && (<Button onClick={() => {
                props.sendToHistory("put back " + selectedCardIndexes.length + " cards at the bottom of its deck")
                const indexesToRemove = [...selectedCardIndexes]
                indexesToRemove.sort((a, b) => b - a);

                let newHand = [...hand]
                let removed = []
                indexesToRemove.forEach(index => {
                    removed.push(newHand.splice(index, 1)[0]);
                });
                let newDeck = [...removed, ...deck, ...newHand]
                props.start(newDeck, handSize - indexesToRemove.length, cardsAlreadyOnBoard)
                props.handleClose()
            }}>
                Put selected cards at the bottom and start ({selectedCardIndexes.length})
            </Button>)}
            <Button variant="primary" onClick={() => {
                let newDeck = [...deck, ...hand]
                props.start(newDeck, handSize, cardsAlreadyOnBoard)
                props.handleClose()
            }}>
                Keep hand and start
            </Button>
        </Modal.Footer>
    </Modal>)
}