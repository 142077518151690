import { Button, Modal } from "react-bootstrap";

export default function HiddenSectionModal(props) {
    return (
        <Modal className="fullscreen-modal modal-game-panel" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{props.section}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex w-100 overflow-x-scroll ">
                    {props.cards.filter(card => card.position.section === props.section)
                        .map(card => (
                            <img className="modal-panel-card mb-3" key={card.id} src={card.cardData.face.front.image} />
                        ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}