import { Button, Form, Modal } from "react-bootstrap"
import { FeedbackButton } from "../../Commons/Functions"

export default function MultiplayerModal(props) {
    if (!props.peerInstance.current) { return }

    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Multiplayer</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Connect with other players</h5>
                <div className="box-container my-4" style={{ backgroundColor: "var(--main-color)" }}>If you have issues connecting with your friends, please make sure that you are all using Chrome or Firefox.</div>
                <div className='d-flex flex-column align-items-center'>
                    <p className='m-0 w-100'>Send your id to other players</p>
                    <div className="box-container w-100">
                        <p className='d-flex align-items-center brighter px-2 w-75 text-nowrap'>{props.peerInstance.current.id}</p>
                        <FeedbackButton className="w-25" feedBack={"Copied !"} onClick={() => {
                            navigator.clipboard.writeText(props.peerInstance.current.id).then(function () {
                            }).catch(function (err) {
                                console.error("Erreur lors de la copie : ", err);
                            });
                        }}>Copy</FeedbackButton>
                    </div>
                </div>

                <div className='d-flex flex-column align-items-center'>
                    <p className='m-0 w-100'>Or enter their id</p>
                    <div className="box-container w-100">
                        <Form.Control
                            type="text"
                            id="peer-connect-id"
                            defaultValue={""}
                            className='w-75'
                        />
                        <FeedbackButton className="w-25" onClick={() => {
                            let callingId = document.getElementById("peer-connect-id").value
                            props.call(callingId, true)
                            setTimeout(() => {
                                props.handleClose()
                            }, 500)
                        }}
                            feedBack="Connecting"
                            disabled={props.gameOptions || props.playerTurnOrder.length > 1}
                        >Connect</FeedbackButton>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}