import { useState } from 'react'
import './History.scss'

export const HISTORY_MESSAGE_TYPE = {
    GLOBAL: "GLOBAL",
    ERROR: "ERROR"
}

export default function History(props) {
    const [focusedCard, setFocusedCard] = useState(false)
    return (
        <div className="history position-fixed end-0 bottom-0">
            <div className='background position-absolute top-0 start-0 w-100 h-100'></div>
            <div className='blur position-absolute top-0 start-0 w-100 h-100'></div>
            <div className='content position-absolute top-0 start-0 w-100 h-100 overflow-y-scroll'>
                {props.history.map((h, i) => {
                    return (<HistoryMessage content={h} key={i} setFocusedCard={setFocusedCard} />)
                })}
            </div>
            {focusedCard && (<div className={'history-focused-card position-absolute' + (focusedCard.cardData.face.front.isHorizontal ? " horizontal" : "")}>
                <img className="w-100 h-100" src={focusedCard.cardData.face.front.image} />
            </div>)}
        </div>
    )
}

function HistoryMessage(props) {
    if (props.content.type == HISTORY_MESSAGE_TYPE.ERROR) {
        return (<div>
            <p className='full-width error'>{props.content.text}</p>
        </div>)
    }

    if (props.content.type == HISTORY_MESSAGE_TYPE.GLOBAL) {
        return (<div>
            <p className='full-width'>{props.content.text}</p>
        </div>)
    }

    if (props.content.card) {
        return (<div>
            <p className='text-start'>- {props.content.player} {props.content.text} <CardName card={props.content.card} setFocusedCard={props.setFocusedCard} /></p>
        </div>)
    }

    return (<div>
        <p className='text-start'>- {props.content.player} {props.content.text}</p>
    </div>)
}

function CardName(props) {
    let data = props.card.cardData
    return (<b className="history-card-name"
        onPointerEnter={() => props.setFocusedCard(props.card)}
        onPointerLeave={() => props.setFocusedCard(false)}
    >{data.name}</b>)
}