import { useLayoutEffect, useState } from "react"
import CardCounters from "./Card/CardCounters"
import { useGameContext } from "../Commons/GameContext"
import { Form } from "react-bootstrap"
import { BlurredBackground } from "../Commons/Functions"

export default function FocusedCard(props) {
    const { cardSize } = useGameContext()
    const [pos, setPos] = useState({ left: 0, top: 0 })
    const cardHeight = 15 * 3 * cardSize.vh
    const cardWidth = cardHeight * 0.7

    let focusedCard = props.focusedCard
    let isHorizontal = focusedCard && (focusedCard.card.isFlipped ? focusedCard.card.cardData.face.back.isHorizontal : focusedCard.card.cardData.face.front.isHorizontal)

    useLayoutEffect(() => {
        if (focusedCard) {
            const limits = {
                minT: 0,
                maxT: window.innerHeight,
                minL: 0,
                maxL: window.screen.width,
            }

            let el = document.getElementById(focusedCard.card.id);
            if (el) {
                let rect = el.getBoundingClientRect();

                let processedCardSize = {
                    width: isHorizontal ? cardHeight : cardWidth,
                    height: isHorizontal ? cardWidth : cardHeight
                }
                let halfHeightDiff = (processedCardSize.height - rect.height) / 2

                let newPos = {
                    left: rect.x + (isHorizontal ? rect.height : rect.width),
                    top: rect.y - halfHeightDiff
                }

                if (newPos.left < limits.minL) {
                    newPos.left = limits.minL
                }
                if (newPos.top < limits.minT) {
                    newPos.top = limits.minT
                }
                if (newPos.left > limits.maxL - processedCardSize.width) {
                    newPos.left -= focusedCard.card.position.cardSize.width * cardSize.vh + processedCardSize.width * (1 + focusedCard.card.position.reversedIndex * 0.06)
                }
                if (newPos.top > limits.maxT - processedCardSize.height) {
                    newPos.top = limits.maxT - processedCardSize.height
                }
                setPos(newPos)
            }
        }
    }, [props.focusedCard])

    if (!focusedCard) { return }

    return (
        <div className={"focused-card position-fixed" + (isHorizontal ? " horizontal" : "")}
            style={{ ...pos }}>
            <img className="w-100 h-100" src={focusedCard.card.isFlipped ? focusedCard.card.cardData.face.back.image : focusedCard.card.cardData.face.front.image} />
            <CardCounters card={focusedCard.card} isHorizontal={isHorizontal} />
            {focusedCard.card.isToken && (<h1 style={{ filter: "drop-shadow(0 0 1vh rgba(0, 0, 0, 1))" }} className='token-count position-absolute top-50 start-50 translate-middle'>x{focusedCard.card.tokenCount}</h1>)}
            <div className={"card-notes position-absolute bottom-0 start-0" + (focusedCard.card.notes.length == 0 ? " empty" : "")}>
                <BlurredBackground />
                <Form.Control
                    className="text-start position-relative"
                    as="textarea"
                    placeholder="Notes..."
                    value={focusedCard.card.notes}
                    rows={3}
                    readOnly
                />
            </div>
        </div>
    )
}