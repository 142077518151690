import { Container } from "react-bootstrap";
import '../Commons.scss'

export default function Footer() {
    return (
        <div className="footer py-5">
            <Container className="text-start">
                <p>TCG Arena is not affiliated with, endorsed, sponsored, or specifically approved by any TCG playable on it. This is a free educational tool, it does not include tournaments or rankings. All features are accessible for free and are not intended for commercial use. This app is still a work in progress, if you have issues or suggestions, feel free to reach me on Discord.</p>
                <p>Current version 1.2.3.3</p>
                <p>App icon by Md Tanvirul Haque, other icons by Freepik. Homepage image by Zishan Liu.</p>
            </Container>
        </div>
    )
}