import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import Navigation from '../../Commons/Navigation/Navigation'
import './GameBuilder.scss'
import Footer from '../../Commons/Footer/Footer'
import { useRef, useState } from 'react'

export default function GameBuilder() {
    return (
        <div className="w-100 game-build">
            <Navigation currentPage="Build" />
            <div className="below-navbar">
                <Container className="p-5" style={{ minHeight: "85vh" }}>
                    <GameFileEdit />
                </Container>
            </div>
            <Footer></Footer>
        </div>
    )
}

function Intro() {
    return (
        <div className='text-start'>
            <h1>Game build</h1>
            <p>To play your own game in this app, you will need 3 things:<br />
                - A <b>game data file</b> in the .json format hosted on the website of your choice. You can create or edit it using the <b>game file</b> button below.<br />
                - A <b>card list data file</b> in the .json format hosted on the website of your choice. You can create or edit it using the <b>card file</b> button below.<br />
                - <b>Card images</b> hosted on the website of your choice. <br />
                <br />
                Check the Discord to find more about the recommended way to host those files for free.
            </p>
            <div className='row w-75 mx-auto my-5'>
                <Col>
                    <Button className="w-100" size="lg">Game file</Button>
                </Col>
                <Col>
                    <Button className="w-100" size="lg">Card list file</Button>
                </Col>
            </div>
        </div>
    )
}

function GameFileIni() {
    return (
        <div>
            <Button>New</Button>
            <Button>Import</Button>
        </div>
    )
}

function GameFileEdit() {
    const gameFileRef = useRef(
        {
            "name": "Star wars unlimited",
            "menuBackgroundImage": "https://i.imgur.com/FMrbNER.jpeg",
            "deckBuilding": {
                "mainFilters": [
                    "aspects",
                    "type",
                    "cost"
                ],
                "formats": [
                    {
                        "title": "Premier",
                        "gameplay": "Classic"
                    },
                    {
                        "title": "Twin Suns",
                        "gameplay": "Classic"
                    }
                ]
            },
            "gameplay": {
                "Classic": {
                    "mulligan": {
                        "info": "You can draw a new hand once.",
                        "startingHandSize": 6,
                        "drawNewHand": true
                    },
                    "newTurn": {
                        "drawOnStart": false,
                        "sharedTurn": true,
                        "firstPlayerToken": {
                            "name": "Initiative",
                            "image": "https://i.imgur.com/Zr2oV9p.png"
                        },
                        "firstPlayerTokenName": "Initiative",
                        "drawPerTurn": 2
                    },
                    "tokens": [
                        {
                            "name": "Initiative",
                            "image": "https://i.imgur.com/Zr2oV9p.png",
                            "isUnique": true,
                            "leavesAtEndOfTurn": false
                        },
                        {
                            "name": "Blast",
                            "image": "https://i.imgur.com/gW5E0z1.png",
                            "isUnique": true,
                            "leavesAtEndOfTurn": true
                        },
                        {
                            "name": "Plan",
                            "image": "https://i.imgur.com/hDR7qTd.png",
                            "isUnique": true,
                            "leavesAtEndOfTurn": true
                        }
                    ],
                    "countersStartingValues": [
                        0
                    ],
                    "sections": {
                        "customSections": [
                            "Ground",
                            "Space",
                            "Leader",
                            "Base",
                            "Mana"
                        ],
                        "layout": {
                            "direction": "row",
                            "isSymetricalForOpponents": true,
                            "content": [
                                {
                                    "direction": "column",
                                    "content": [
                                        {
                                            "section": "Ground"
                                        },
                                        {
                                            "section": "Mana"
                                        }
                                    ]
                                },
                                {
                                    "direction": "column",
                                    "style": {
                                        "width": "22vh",
                                        "justify-content": "start"
                                    },
                                    "content": [
                                        {
                                            "section": "Base"
                                        },
                                        {
                                            "section": "Leader"
                                        }
                                    ]
                                },
                                {
                                    "section": "Space"
                                }
                            ]
                        },
                        "categoriesAlreadyOnBoard": [
                            "Leader",
                            "Base"
                        ],
                        "autoPlayFromHand": {
                            "Unit - Ground": "Ground",
                            "Unit - Space": "Space",
                            "Leader": "Leader",
                            "Base": "Base",
                            "Event": "Stack",
                            "Upgrade": "Stack"
                        },
                        "autoPlayFromStack": {
                            "Unit - Ground": "Ground",
                            "Unit - Space": "Space",
                            "Leader": "Leader",
                            "Base": "Base",
                            "Event": "Discard"
                        },
                        "sectionsDict": {
                            "Stack": {
                                "isDefaultSection": true,
                                "title": "Stack",
                                "isHidden": "no",
                                "height": "HUGE",
                                "alignment": "NONE",
                                "isHorizontalAllowed": false,
                                "isGroupForbidden": true,
                                "displayedTitle": "Pile"
                            },
                            "Ground": {
                                "title": "Ground",
                                "isHidden": "no",
                                "height": "MEDIUM",
                                "alignment": "CENTER",
                                "isHorizontalAllowed": true,
                                "enterTapped": true,
                                "displayedTitle": "Ground units"
                            },
                            "Space": {
                                "title": "Space",
                                "isHidden": "no",
                                "height": "MEDIUM",
                                "alignment": "CENTER",
                                "isHorizontalAllowed": true,
                                "enterTapped": true,
                                "displayedTitle": "Space units"
                            },
                            "Leader": {
                                "title": "Leader",
                                "isHidden": "no",
                                "height": "DEFAULT",
                                "alignment": "CENTER",
                                "isHorizontalAllowed": true,
                                "noQuickActions": true
                            },
                            "Base": {
                                "title": "Base",
                                "isHidden": "no",
                                "height": "DEFAULT",
                                "alignment": "CENTER",
                                "isHorizontalAllowed": true,
                                "noQuickActions": true
                            },
                            "Mana": {
                                "title": "Mana",
                                "isHidden": "yes",
                                "height": "SMALL",
                                "alignment": "START",
                                "quickActionButtons": [
                                    "reveal",
                                    "tap-one"
                                ],
                                "opponentAlignment": "END",
                                "noAutoPayTo": "true",
                                "isHorizontalAllowed": false,
                                "displayedTitle": "Ressources"
                            }
                        }
                    }
                }
            },
            "defaultRessources": {
                "backgrounds": [
                    "https://i.imgur.com/KmXSVQp.jpeg",
                    "https://i.imgur.com/CFOwlKx.jpeg",
                    "https://i.imgur.com/n2EYbyu.jpeg",
                    "https://i.imgur.com/C3LAixE.jpeg",
                    "https://i.imgur.com/EZ9z8z6.jpeg",
                    "https://i.imgur.com/CKuiePb.jpeg"
                ]
            },
            "customHelp": "To put a card in your ressources, drag and drop the card in the ressources area. You can take the initiative, blast and plan token with the '+' button on the bottom left corner.",
            "cards": {
                "dataUrl": "https://valcur.github.io/JSON/SWUCards.json",
                "cardBack": "https://i.imgur.com/alTEVLx.jpeg"
            }
        }
    )
    return (
        <div className='d-flex flex-column text-start w-75 mx-auto'>
            <h1>Game file</h1>

            <FormObject section={gameFileRef.current} />

            <Button onClick={() => {
                console.log(gameFileRef.current)
            }}>Download</Button>
        </div>
    )
}

function FormObject(props) {
    return (
        <div className='form-group'>
            <Form.Label>{props.keyName}</Form.Label>
            {Object.entries(props.section).map(([key, value]) => {
                if (Array.isArray(value)) {
                    return <FormArray keyName={key} section={props.section[key]} />
                } else if (value !== null && typeof value === "object") {
                    return <FormObject keyName={key} section={props.section[key]} />
                } else {
                    return <FormValue keyName={key} setProperty={(v) => props.section[key] = v} defaultValue={value} />
                }
            })}
        </div>
    )
}

function FormArray(props) {
    return (
        <div className='form-group'>
            <Form.Label>{props.keyName}</Form.Label>
            {Object.entries(props.section).map(([key, value]) => {
                if (Array.isArray(value)) {
                    return (<div className='d-flex flex-column'>
                        <FormArray keyName={key} section={props.section[key]} />
                        <Button>-</Button>
                    </div>)
                } else if (value !== null && typeof value === "object") {
                    return (<div className='d-flex flex-column'>
                        <FormObject keyName={key} section={props.section[key]} />
                        <Button>-</Button>
                    </div>)
                } else {
                    return (<div className='d-flex'>
                        <FormValue keyName={key} setProperty={(v) => props.section[key] = v} defaultValue={value} />
                        <Button>-</Button>
                    </div>)
                }
            })}
            <Button>+</Button>
        </div>
    )
}

function FormValue(props) {
    return (
        <div className='form-line'>
            <Form.Label>{props.keyName}</Form.Label>
            <Form.Control
                type="url"
                id="game-image"
                placeholder={""}
                defaultValue={props.defaultValue}
                onChange={(e) => {
                    props.setProperty(e.currentTarget.value)
                }}
            />
        </div>
    )
}