import { Button, Col, Container, Row } from 'react-bootstrap'
import './FullscreenDecklist.scss'

export default function FullscreenDecklist(props) {
    const decklist = props.deckList
    if (!decklist) { return }

    return (
        <Container className='position-relative mb-5 fullscreen-decklist'>
            <div className='d-flex align-items-center'>
                <Button onClick={() => props.toggleFullscreenView()}><img src={require('../../Commons/Assets/Icons/return.png')} /></Button>
                <p className='m-0 ms-2'>Total cards <b>{props.cardCount}</b></p>
            </div>
            {decklist.categoriesOrder.map((cat, i) => {
                return (
                    <Row key={cat}>
                        <h5 className='text-start mt-4 mb-2'>{cat}</h5>
                        {decklist[cat].map((card, j) => {
                            return (<Col xs="2" key={card.id}>
                                <div className='fullscreen-card position-relative my-1'
                                    id={card.id}
                                    onPointerEnter={() => {
                                        let tmp = { ...card }
                                        delete tmp.count
                                        props.setFocusedCard(tmp)
                                    }}
                                    onPointerLeave={() => props.setFocusedCard(false)}
                                >
                                    <div className='count d-flex justify-content-center align-items-center position-absolute top-0 start-0'>
                                        <h4 className='m-0'>{card.count}</h4>
                                    </div>
                                    <img className="h-100 w-100" src={card.face.front.image} />
                                </div>
                            </Col>)
                        })}
                    </Row>
                )
            })}
        </Container>
    )
}