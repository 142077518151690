import { Button, FloatingLabel, Form, Modal, ModalBody } from "react-bootstrap"
import { FeedbackButton } from "../../Commons/Functions"
import { useState } from "react"

export default function TurnOrderModal(props) {
    const [extraTurns, setExtraTurns] = useState([])

    if (!props.peerInstance.current) { return }

    function movePlayerInTurnOrder(index, direction) {
        let newIndex = index + direction
        if (newIndex < 0 || newIndex >= props.playerTurnOrder.length) { return }

        let tmpPlayerTurnOrder = [...props.playerTurnOrder]
        let tmp = tmpPlayerTurnOrder[index]
        tmpPlayerTurnOrder[index] = tmpPlayerTurnOrder[newIndex]
        tmpPlayerTurnOrder[newIndex] = tmp

        props.setPlayerTurnOrder(tmpPlayerTurnOrder)
        props.sendData({
            playerTurnOrder: tmpPlayerTurnOrder
        })
    }

    function removeExtraTurn(index) {
        let tmp = [...extraTurns]
        tmp.splice(index, 1)
        setExtraTurns(tmp)
    }

    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Turn management</Modal.Title>
            </Modal.Header>
            {/*<ModalBody>
                <h5>Add extra turns</h5>
                <p>Those players will be playing an extra when the current player will have ended his turn. Once all extra turn have been played, the game will return where it stopped.</p>
                <div className="d-flex">
                    <FloatingLabel className="w-75 me-3" controlId={"extra-turn-player-selection"} label={"Choose player"}>
                        <Form.Select aria-label="Format select"
                            defaultValue={props.currentPlayer}
                            id={"extra-turn-player-selection"}
                        >
                            {props.playerTurnOrder.map((p, j) => {
                                if (props.peerInstance.current.id === p) {
                                    return (<option key={p} value={p}>{props.playerData.profileData.name}</option>)
                                } else if (props.opponentsData[p]) {
                                    return (<option key={p} value={p}>{props.opponentsData[p].profileData.name}</option>)
                                }
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    <Button className="w-25" onClick={() => {
                        let input = document.getElementById('extra-turn-player-selection')
                        if (input) {
                            let tmp = [...extraTurns]
                            tmp.push(input.value)
                            setExtraTurns(tmp)
                        }
                    }}>Add turn</Button>
                </div>
                <div className="d-flex flex-column pt-2">
                    {extraTurns.map((p, i) => {
                        if (props.peerInstance.current.id === p) {
                            return (
                                <div key={p + "-" + i} className="box-container justify-content-between">
                                    <p className='d-flex align-items-center brighter px-3'>{i + 1}</p>
                                    <p className='brighter d-flex align-items-center px-3 w-100'>{props.playerData.profileData.name}</p>
                                    <Button onClick={() => {
                                        removeExtraTurn(i)
                                    }}>Remove</Button>
                                </div>
                            )
                        } else if (props.opponentsData[p]) {
                            return (
                                <div key={p + "-" + i} className="box-container justify-content-between">
                                    <p className='d-flex align-items-center brighter px-3'>{i + 1}</p>
                                    <p className='brighter d-flex align-items-center px-3 w-100'>{props.opponentsData[p].profileData.name}</p>
                                    <Button onClick={() => {
                                        removeExtraTurn(i)
                                    }}>Remove</Button>
                                </div>
                            )
                        }
                    })}
                </div>
                </ModalBody>*/}
            <Modal.Body>
                <h5>Change order</h5>
                <div className='d-flex flex-column'>
                    {props.playerTurnOrder.map((p, i) => {
                        return (
                            <div className='box-container justify-content-between' key={p} style={p == props.peerInstance.current.id ? { boxShadow: "0 0 0.15rem 0.15rem var(--main-color)" } : {}}>
                                <p className='d-flex align-items-center brighter px-3'>{i + 1}</p>
                                {p == props.peerInstance.current.id && (<p className='brighter d-flex align-items-center px-3 w-100'>{props.playerData.profileData.name}</p>)}
                                {p != props.peerInstance.current.id && props.opponentsData[p] && (<p className='brighter d-flex align-items-center px-3 w-100'>{props.opponentsData[p].profileData.name}</p>)}
                                <div className='d-flex flex-row'>
                                    <Button onClick={() => movePlayerInTurnOrder(i, -1)} disabled={i == 0}>Up</Button>
                                    <Button onClick={() => movePlayerInTurnOrder(i, 1)} disabled={i == props.playerTurnOrder.length - 1}>Down</Button>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}