import { Accordion, Button, Container, Dropdown, DropdownButton } from 'react-bootstrap'
import './DeckListPanel.scss'

export default function DeckListPanel(props) {
    let deckList = props.deckList

    return (
        <Container className="deck-list-panel mb-5">
            <Accordion defaultActiveKey={deckList.categoriesOrder} alwaysOpen>
                <div className="d-flex flex-row align-items-center total-cards position-relative">
                    <p className="card-count">{props.cardCount}</p>
                    <p class="m-0">Total cards</p>
                    <Button style={{ marginRight: "0.7rem" }} onClick={props.toggleFullscreenView} className='position-absolute end-0'><img src={require('../../Commons/Assets/Icons/maximize.png')} /></Button>
                </div>
                {deckList.categoriesOrder.map((cat, i) => {
                    return (<TypeSection
                        key={cat}
                        cards={deckList[cat]}
                        catName={cat}
                        addOne={props.addOne}
                        removeOne={props.removeOne}
                        moveToCategorie={props.moveToCategorie}
                        setFocusedCard={props.setFocusedCard}
                        formatData={props.formatData}
                    />)
                })}
            </Accordion>

        </Container>
    )
}

function TypeSection(props) {
    return (
        <Accordion.Item eventKey={props.catName}>
            <Accordion.Header>
                <div className="d-flex flex-row align-items-center">
                    <p className="card-count type">{props.cards.reduce((a, b) => a + b.count, 0)}</p>
                    <p class="m-0 fw-bold">{props.catName}</p>
                </div>
            </Accordion.Header>
            <Accordion.Body>
                {props.cards.map((c, i) => {
                    return (<CardElement
                        key={c.id}
                        card={c} index={i}
                        catName={props.catName}
                        addOne={props.addOne}
                        removeOne={props.removeOne}
                        moveToCategorie={props.moveToCategorie}
                        setFocusedCard={props.setFocusedCard}
                        formatData={props.formatData}
                    />)
                })}
            </Accordion.Body>
        </Accordion.Item>
    )
}

function CardElement(props) {
    let card = props.card
    return (
        <div className='card-element position-relative'
            id={"decklist-" + props.card.id}
            onPointerEnter={() => props.setFocusedCard(card)}
            onPointerLeave={() => props.setFocusedCard(false)}
        >
            <img className={"image-background position-absolute top-0 start-0 w-100 pe-none" + (card.isHorizontal ? " horizontal" : "")} src={card.face.front.image} />
            <div className="background-cover position-absolute top-0 start-0 w-100 h-100 pe-none"></div>
            <div className="content position-relative width-100 d-flex flex-row align-items-center">
                <p className='card-count'>{card.count}</p>
                <p className="w-100 text-start m-0 text-truncate">{card.name}</p>
                <DropdownButton onClick={() => {props.setFocusedCard(false)}} autoClose="outside" className="" title="">
                    <Dropdown.Item onClick={() => props.removeOne(card, props.index, props.catName)}>Remove one</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.addOne(card, props.index, props.catName)}>Add one</Dropdown.Item>
                    {props.formatData && props.formatData.customCategories && props.formatData.customCategories.map(c => {
                        if (c != props.catName) {
                            return(<Dropdown.Item key={c} onClick={() => props.moveToCategorie(card, props.index, c)}>Move to {c}</Dropdown.Item>)
                        }
                    })}
                </DropdownButton>
            </div>
        </div>
    )
}