import { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { SECTIONS } from "./PlayerSection/PlayerSection";

export default function MouseDropableSection(props) {
    const [isInBound, setIsInBound] = useState(false)
    const isInBoundRef = useRef(false)
    const [dragEnded, setDragEnded] = useState(0)
    const dragEndedRef = useRef(0)

    useEffect(() => {
        const isMouseInBound = e => {
            if (props.sectionRef.current && props.cardDraggedRef.current && props.cardDraggedRef.current.position.section != props.section) {
                let sectionBounds = props.sectionRef.current.getBoundingClientRect()
                if (e.pageX > sectionBounds.left && e.pageX < sectionBounds.right &&
                    e.pageY > sectionBounds.top && e.pageY < sectionBounds.bottom
                ) {
                    setIsInBound(true)
                    isInBoundRef.current = true
                } else {
                    setIsInBound(false)
                    isInBoundRef.current = false
                }
            } else {
                setIsInBound(false)
                isInBoundRef.current = false
            }
        };

        const dragEnd = e => {
            if (isInBoundRef.current && props.cardDraggedRef.current) {
                setDragEnded(dragEndedRef.current + 1)
            }
        }

        window.addEventListener('pointermove', isMouseInBound);
        window.addEventListener('pointerup', dragEnd);

        return () => {
            window.removeEventListener(
                'pointermove',
                isMouseInBound,
            );
            window.removeEventListener(
                'pointerup',
                dragEnd,
            );
        };
    }, [props.sectionRef]);

    useEffect(() => {
        if (dragEnded > 0) {
            let oldSection = props.cardDraggedRef.current.position.section
            props.moveCard(props.cardDraggedRef.current, props.section)

            props.repositionCards([oldSection, props.section])
            dragEndedRef.current = dragEnded
        }
    }, [dragEnded])

    // A refaire mieux
    let sectionTitle = props.displayedTitle ? props.displayedTitle : props.section
    if (sectionTitle == "Discard") {
        sectionTitle = ""
    }

    return (
        <div className={(props.className ? props.className + " " : "") + "mouse-dropable-section d-flex flex-row" + (isInBound ? " bound" : "")}
            ref={props.sectionRef}>
                <h4 className="section-title position-absolute pe-none start-50 top-50 translate-middle">{sectionTitle}</h4>
            {props.children}
        </div>
    )
}