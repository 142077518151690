import React, { useEffect, useRef, useState } from 'react'
import './Card.scss'
import { SECTIONS } from '../PlayerSection/PlayerSection'
import CardQuickActions from './CardQuickActions'
import CardCounters from './CardCounters'
import { Form } from 'react-bootstrap'
import { useGameContext } from '../../Commons/GameContext'
import { ACTION_MODES } from '../ActionMode'

export default function Card(props) {
    const [cardPos, setCardPos] = useState({ top: 0, left: 0 })
    const cardRef = useRef(null)
    const [isDragged, setIsDragged] = useState(false)
    const isDraggedRef = useRef(false)
    const [isMouseOver, setIsMouseOver] = useState(false)
    const clickTimeRef = useRef(false)
    const cardDraggedSize = useRef({ width: 0, height: 0 })
    const { cardSize, gameData } = useGameContext()
    const holdDelayToDragDrop = 200
    const draggedCardMovementRef = useRef({ x: 0, y: 0, xValues: [], yValues: [] })
    const [draggedCardMovement, setDraggedCardMovement] = useState(draggedCardMovementRef.current)
    const [clickInfo, setClickInfo] = useState("")

    let card = props.card

    useEffect(() => {
        cardDraggedSize.current = { width: 30 * 0.7 * cardSize.vh, height: 30 * cardSize.vh }
    }, [cardSize.vh])

    useEffect(() => {
        const mode = props.currentActionMode
        let newClickInfo = ""

        if (props.isOpponentCard) {
            newClickInfo = "Ping"
        }

        if (mode) {
            // OPPONENT CAN DO
            if (mode.action == ACTION_MODES.LINK_TO) {
                newClickInfo = "Target"
            } else if (mode.action == ACTION_MODES.PING || props.isOpponentCard) {
                newClickInfo = "Ping"
            } else {
                // PLAYER ONLY
                if (mode.action == ACTION_MODES.GROUP) {
                    if (props.card.grouppedToId) {
                        newClickInfo = ""
                    } else {
                        newClickInfo = "Group with"
                    }
                } else if (mode.action == ACTION_MODES.PRESENT) {
                    newClickInfo = "Present"
                } else if (mode.action == ACTION_MODES.DISCARD) {
                    newClickInfo = "Discard"
                } else if (mode.action == ACTION_MODES.REMOVE) {
                    newClickInfo = "Remove"
                } else if (mode.action == ACTION_MODES.CARD_EFFECT) {
                    newClickInfo = "Add effect"
                } else {
                    if (props.card.position.section == SECTIONS.HAND) {
                        newClickInfo = "Play"
                    } else {
                        newClickInfo = props.card.isTapped ? "Untap" : "Tap"
                    }
                }
            }
        }
        if (clickInfo == "Play") {
            setTimeout(() => {
                setClickInfo(newClickInfo)
            }, 500)
        } else {
            setClickInfo(newClickInfo)
        }
    }, [props.currentActionMode.action, props.card.position.section, props.card.isTapped])

    function onPointerDown(e) {
        if (props.isOpponentCard) { props.clickCard(); return }
        const clickTime = new Date().getTime()
        clickTimeRef.current = clickTime

        const handleWindowMouseMove = (e) => {
            if (isDraggedRef.current) {
                setCardPos({ top: e.pageY, left: e.clientX })
                let rotateX = e.movementY * -1
                let rotateY = e.movementX
                let tmp = { ...draggedCardMovementRef.current }
                const scaleFactor = 0.2
                const maxAngle = 20

                tmp.xValues.push(rotateX)
                tmp.yValues.push(rotateY)
                const minMax = (val) => {
                    if (val > 0) {
                        return Math.min(val / scaleFactor, maxAngle)
                    } else {
                        return Math.max(val / scaleFactor, -maxAngle)
                    }
                }
                const getValFromArray = (array) => {
                    return minMax(array.reduce((acc, v) => acc + v, 0) / array.length)
                }
                tmp.x = getValFromArray(tmp.xValues)
                tmp.y = getValFromArray(tmp.yValues)
                setDraggedCardMovement(tmp)
                draggedCardMovementRef.current = tmp


                setTimeout(() => {
                    let tmp = { ...draggedCardMovementRef.current }
                    if (tmp.xValues.length > 0) {
                        tmp.xValues.shift()
                        tmp.yValues.shift()
                        tmp.x = getValFromArray(tmp.xValues)
                        tmp.y = getValFromArray(tmp.yValues)
                        setDraggedCardMovement(tmp)
                        draggedCardMovementRef.current = tmp
                    }
                }, 200)
            }
        }

        const handleClickUp = () => {
            if (props.isOpponentCard) { return }
            if (clickTimeRef.current && new Date().getTime() - clickTimeRef.current < holdDelayToDragDrop) {
                props.clickCard()
            } else {
                if (isDraggedRef.current) {
                    isDraggedRef.current = false
                    setIsDragged(false)
                    setIsMouseOver(false)
                    setTimeout(() => {
                        props.cardDraggedRef.current = false
                    }, holdDelayToDragDrop)
                }
            }
            clickTimeRef.current = false
            window.removeEventListener(
                'pointermove',
                handleWindowMouseMove,
            );
            window.removeEventListener(
                'pointerup',
                handleClickUp,
            );
        }
        window.addEventListener('pointerup', handleClickUp);

        setTimeout(() => {
            if (clickTimeRef.current && clickTimeRef.current === clickTime && !card.isTriggerEffect) {
                isDraggedRef.current = true
                setIsDragged(true)
                props.setFocusedCard(false)

                setCardPos({ top: e.pageY, left: e.clientX })
                window.addEventListener('pointermove', handleWindowMouseMove);
                props.cardDraggedRef.current = card
                draggedCardMovementRef.current = { x: 0, y: 0, xValues: [], yValues: [] }
                setDraggedCardMovement(draggedCardMovementRef.current)
            }
        }, holdDelayToDragDrop)
    }

    let cardWidth = card.position.cardSize ? (card.isHorizontal ? card.position.cardSize.height : card.position.cardSize.width) : false
    let cardHeight = card.position.cardSize ? (card.isHorizontal ? card.position.cardSize.width : card.position.cardSize.height) : false
    let cardBorderRadius = card.position.cardSize ? card.position.cardSize.height / 25 : 1

    if (isDragged) {
        cardWidth *= 2
        cardHeight *= 2
    }

    let posX = cardPos.left
    let posY = cardPos.top

    if (card.position.pos && !isDragged) {
        posX = card.position.pos.left
        posY = card.position.pos.top
    }

    return (
        <div className={"game-card" + (isDragged ? " dragged" : "") + (card.isTapped ? " tapped" : "") + " " + card.position.section + (card.hiddenTo[props.playerId] === true ? " card-hidden-yes" : " card-hidden-no") + (card.isFlipped ? " card-flipped" : "") + (card.isHorizontal ? " card-horizontal" : "") + " reversed-index-" + card.position.reversedIndex + (props.isOpponentCard ? " opponent-card" : "") + (card.isPresented ? " presented" : "") + (card.isManaCostHightlight ? " mana-highlight" : "") + (card.isTriggerEffect ? " trigger-effect" : "") + (isMouseOver ? " hover" : "")}
            style={{
                ...{ left: posX, top: posY },
                ...(isDragged ? { transform: "rotateX(" + draggedCardMovement.x + "deg) rotateY(" + draggedCardMovement.y + "deg)" } : {}),
                ...(card.position.cardSize ? { width: cardWidth * cardSize.vh, height: cardHeight * cardSize.vh } : {})
            }}
            id={card.id}
            ref={cardRef}
        >
            {card.isPresented && !isDragged && (<img src={require("../Assets/present-card.png")} className='position-absolute start-50 presented-image translate-middle pe-none' />)}

            <div className='card-images card-images-and-wrapper w-100 h-100 position-absolute top-0 start-0'
                style={{ zIndex: card.position.index, borderRadius: cardBorderRadius + "vh" }}
                id={card.id + "-images"}
                onPointerEnter={() => {
                    if (!isDraggedRef.current) {
                        if (card.hiddenTo[props.playerId] !== true) {
                            props.setFocusedCard({
                                card: card,
                                position: card.position.pos
                            })
                        } else if (!props.isOpponentCard && props.gameOptions && !props.gameOptions.format.hideFacedDownCards) {
                            props.setFocusedCard({
                                card: card,
                                position: card.position.pos
                            })
                        }
                    }
                    if (card.position.section == SECTIONS.HAND && props.setHoverCardCost) {
                        props.setHoverCardCost(card.cardData.cost)
                    }
                    setIsMouseOver(true)
                }}
                onPointerLeave={() => {
                    props.setFocusedCard(false)
                    if (card.position.section == SECTIONS.HAND && props.setHoverCardCost) {
                        props.setHoverCardCost(0)
                    }
                    setIsMouseOver(false)
                }}
            >
                <div className="card-front-and-back w-100 h-100 position-absolute top-0 start-0" >
                    <div className='position-absolute top-0 start-0 w-100 h-100 sleeve pe-none'></div>
                    <img src={card.cardData.face.front.image} className='card-front w-100 h-100 position-absolute top-0 start-0' style={{ zIndex: card.position.index, borderRadius: cardBorderRadius + "vh" }} />
                    {card.cardData.face.back && (<img src={card.cardData.face.back.image} className='card-back w-100 h-100 position-absolute top-0 start-0' style={{ zIndex: card.position.index, borderRadius: cardBorderRadius + "vh" }} />)}
                </div>
                <img src={gameData.cards.cardBack} className='card-hidden-back w-100 h-100 position-absolute top-0 start-0 pe-auto' style={{ zIndex: card.position.index, borderRadius: cardBorderRadius + "vh" }} />
                {/*<div className='w-100 h-100 position-absolute top-0 start-0'
                    onPointerDown={onPointerDown}
                >
            </div>*/}
                <div className='card-click-info position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center' style={{ opacity: 0, borderRadius: cardBorderRadius + "vh", zIndex: Math.abs(card.position.index) }}
                    onPointerDown={onPointerDown}
                >
                    <p>{clickInfo}</p>
                </div>
                <CardCounters card={card} repositionCards={props.repositionCards} isHorizontal={props.card.isHorizontal} isOpponentCard={props.isOpponentCard} />
                {card.isToken && (
                    <Form.Control
                        className='token-count position-absolute bottom-0 end-0'
                        type="number"
                        style={{ zIndex: card.position.index }}
                        value={card.tokenCount}
                        readOnly={props.isOpponentCard}
                        onChange={(e) => {
                            if (props.repositionCards) {
                                card.tokenCount = e.target.value
                                props.repositionCards() // FAIRE MIEUX
                            }
                        }}
                    />
                )}
                {props.opponentIds && Object.keys(card.hiddenTo).length < props.opponentIds.length && !card.hiddenTo[props.playerId] && (<img className="card-revealed-indicator d-none position-absolute top-0 start-0" src={require('../Assets/card-visibility.png')} />)}
            </div>
            {
                !props.isOpponentCard && (<CardQuickActions
                    card={card}
                    deck={props.deck}
                    cards={props.cards}
                    index={props.index}
                    repositionCards={props.repositionCards}
                    setCurrentActionMode={props.setCurrentActionMode}
                    onClick={() => props.setFocusedCard(false)}
                    opponentIds={props.opponentIds}
                    opponentsData={props.opponentsData}
                    giveCardAtIndexTo={props.giveCardAtIndexTo}
                    moveCard={props.moveCard}
                    sendToHistory={props.sendToHistory}
                    playerId={props.playerId}
                    setIsTextInputFocused={props.setIsTextInputFocused}
                />)
            }
        </div >
    )
}