import { Button, Modal } from "react-bootstrap";

export default function HelpModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Help</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h5>Basics</h5>
                <p>Click a card in your hand to play it in its default area. Click a card in play to tap it. Hold your click to drag and drop a card to the desired area.</p>
                <p>Click a card in the opponent board to ping it.</p>
            </Modal.Body>
            {props.gameData && props.gameData.customHelp && (<Modal.Body>
                <h5>Info about this game</h5>
                <p>{props.gameData.customHelp}</p>
            </Modal.Body>)}
            <Modal.Body>
                <h5>Shortcuts</h5>
                <Shortcut keybind="L" description="Create a link between two cards to show an interraction." />
                <Shortcut keybind="F" description="Play cards without paying their cost." />
                <Shortcut keybind="H" description="Ping any card (visible for a short time)." />
                <Shortcut keybind="P" description="Present one of your card forward (visible until the end of the turn)." />
                <Shortcut keybind="D" description="Send the card to the discard." />
                <Shortcut keybind="R" description="Remove the card from the game." />
                <Shortcut keybind="T" description="Add the card effect to the pile." />
                <Shortcut keybind="Up/Down" description="Untap/Tap a card from the ressource area." />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function Shortcut(props) {
        return (
            <div className='box-container align-items-center'>
                <p className='brighter px-3 py-2'>{props.keybind}</p>
                <p className='my-0'>{props.description}</p>
            </div>
        )
    }
}