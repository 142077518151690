import { Button, Dropdown, DropdownButton, Form, Modal, Offcanvas } from 'react-bootstrap'
import './Deck.scss'
import { useEffect, useState } from 'react';
import { SECTIONS } from '../PlayerSection/PlayerSection';
import { hideCard, shuffleArray } from '../../Commons/Functions';
import { useGameContext } from '../../Commons/GameContext';
import { ACTION_MODES } from '../ActionMode';

export default function Deck(props) {
    let deckCount = props.deck.length
    const isOpponentDeck = props.opponentIds === undefined
    const [showDeckSearch, setShowDeckSearch] = useState(false);
    const [showTopDeckManager, setShowTopDeckManager] = useState(false);

    const [topCardsRevealedCount, setTopCardsRevealedCount] = useState(0)
    const [topCardsRevealedTotal, setTopCardsRevealedTotal] = useState(0)

    const [hasMovedCard, setHasMovedCard] = useState(false)
    const { gameData } = useGameContext()

    const [ationModeText, setActionModeText] = useState("Draw")

    function moveCard(i, direction) {
        let index = props.deck.length - i - 1
        if (index + direction < 0 || index + direction > props.deck.length - 1) { return }

        let tmpDeck = [...props.deck]

        const tmpCard = tmpDeck[index]
        tmpDeck[index] = tmpDeck[index + direction]
        tmpDeck[index + direction] = tmpCard

        props.setDeck(tmpDeck)
        setHasMovedCard(true)
    }

    function toSection(i, section) {
        let index = props.deck.length - i - 1
        if (index < 0 || index > props.deck.length - 1) { return }

        let newDeck = [...props.deck]  // A virer quand le draw initial sera avec un bouton
        let newCard = newDeck.splice(index, 1)[0]
        props.setDeck(newDeck)

        let rect = props.deckRef.current.getBoundingClientRect()
        let sectionData = props.sections.sectionsDict[SECTIONS.DECK]

        newCard.position = {
            section: SECTIONS.DECK,
            pos: { left: rect.left, top: rect.top },
            cardSize: {
                width: sectionData.cardWidth,
                height: sectionData.cardHeight
            }
        }
        hideCard(newCard, "yes", props.playerId, props.opponentIds)

        props.visibleCardsRef.current.push(newCard)
        setTimeout(() => {
            props.moveCard(newCard, section)
            props.repositionCards()
        }, 100)
    }

    function shuffleDeck() {
        if (props.deck && props.setDeck) {
            let tmp = [...props.deck]
            shuffleArray(tmp)
            props.setDeck(tmp)
            props.sendToHistory("shuffled his deck")
        }
    }

    function onDeckSearchExit() {
        if (props.deck && props.setDeck) {
            let tmp = [...props.deck]
            shuffleArray(tmp)
            props.setDeck(tmp)
        }
    }

    function toDeckBottom(i) {
        if (props.deck && props.setDeck) {
            let index = props.deck.length - i - 1
            if (index < 0 || index > props.deck.length - 1) { return }
            let newDeck = [...props.deck]
            let newCard = newDeck.splice(index, 1)[0]
            newDeck.unshift(newCard)
            props.setDeck(newDeck)
            props.sendToHistory("put a card from the top to the bottom of its deck")
        }
    }

    function revealCard(card) {
        // Ajouter un truc visuel cote joueur ?
        props.sendToHistory("revealed from his deck", card)
    }

    function deckClick() {
        if (props.currentActionMode.action == ACTION_MODES.DISCARD) {
            toSection(0, SECTIONS.DISCARD)
        } else if (props.currentActionMode.action == ACTION_MODES.REMOVE) {
            toSection(0, SECTIONS.EXILE)
        } else {
            props.draw(1)
        }
    }

    function hideTopCard(val) {
        let newTopCardHiddenTo = { ...props.topCardHiddenTo }
        const keepVisibility = newTopCardHiddenTo["KEEP"]
        if (val == "yes") {
            [...props.opponentIds, props.playerId].forEach(id => {
                newTopCardHiddenTo[id] = true
            })
            props.sendToHistory("hid his deck top card")
        } else if (val == "opponent-only") {
            newTopCardHiddenTo = {}
            props.opponentIds.forEach(id => {
                newTopCardHiddenTo[id] = true
            })
            props.sendToHistory("hid his deck top card from other players")
        } else if (val == "no") {
            newTopCardHiddenTo = {}
            props.sendToHistory("revealed from the top of his deck ", props.card)
        } else {
            if (newTopCardHiddenTo[val]) {
                delete newTopCardHiddenTo[val]
                props.sendToHistory("revealed his deck top card to one player", props.card)
            } else {
                newTopCardHiddenTo[val] = true
                props.sendToHistory("hid his deck top card from one player", props.card)
            }
        }
        newTopCardHiddenTo["KEEP"] = keepVisibility
        props.setTopCardHiddenTo(newTopCardHiddenTo)
    }

    function toggleKeepTopCardHideen() {
        let newTopCardHiddenTo = { ...props.topCardHiddenTo }
        if (newTopCardHiddenTo["KEEP"]) {
            delete newTopCardHiddenTo["KEEP"]
            props.sendToHistory("removed his deck top card visibility from permanent")
        } else {
            newTopCardHiddenTo["KEEP"] = true
            props.sendToHistory("set his deck top card visibility to permanent")
        }
        props.setTopCardHiddenTo(newTopCardHiddenTo)
    }

    useEffect(() => {
        if (props.currentActionMode) {
            if (props.currentActionMode.action == ACTION_MODES.DISCARD) {
                setActionModeText("Mill")
            } else if (props.currentActionMode.action == ACTION_MODES.REMOVE) {
                setActionModeText("Remove")
            } else {
                setActionModeText("Draw")
            }
        } else {
            setActionModeText("Draw")
        }
    }, [props.currentActionMode])

    useEffect(() => {
        setTopCardsRevealedCount(0)
        setTopCardsRevealedTotal(0)
        setHasMovedCard(false)
    }, [showTopDeckManager])

    return (
        <div className={'position-absolute deck' + (deckCount == 0 ? " empty" : "")} ref={props.deckRef}>
            <div className='deck-pile position-absolute top-0 start-0 w-100 h-100'>
                {[...Array(deckCount)].map((c, i) => {
                    return (<img key={i} style={{ transform: "translateZ(" + (i - deckCount) * 2 + "px) translateY(" + (i - deckCount) * 0.25 + "px)" }} className='position-absolute top-0 start-0 w-100 h-100' src={gameData.cards.cardBack} />)
                })}
            </div>
            {deckCount > 0 && props.playerId && (
                <div className={"top-card position-absolute top-0 start-0 w-100 h-100" + (props.topCardHiddenTo[props.playerId] === true ? " hidden" : "")}>
                    <img id={props.deck.at(-1).id} className="position-absolute top-0 start-0 w-100 h-100" src={props.deck.at(-1).cardData.face.front.image} />
                </div>
            )}
            <p className='position-absolute top-50 start-50 translate-middle section-card-count'>{deckCount}</p>
            <div className="interaction-zone position-absolute top-0 start-0 w-100 h-100">
                <div className='click-area position-absolute top-0 start-0 w-100 h-100 pe-auto'
                    onClick={() => {
                        if (!isOpponentDeck) {
                            deckClick()
                        }
                    }}
                    onPointerEnter={() => {
                        if (props.topCardHiddenTo[props.playerId] !== true && deckCount > 0) {
                            let tmp = { card: props.deck.at(-1) }
                            tmp.card.position = {
                                cardSize: {
                                    width: 8,
                                    height: 15
                                },
                                section: SECTIONS.DECK
                            }
                            props.setFocusedCard(tmp)
                        }
                    }}
                    onPointerLeave={() => props.setFocusedCard(false)}
                >
                    <div className='deck-background position-absolute top-0 start-0 h-100 w-100'></div>
                    <p className='position-absolute top-0 w-100'>{ationModeText}</p>
                </div>
                {props.opponentIds && props.deck.length > 0 && (<DropdownButton className="position-absolute top-0 start-0 quick-actions" title="">
                    <DropdownButton drop="start" title="Top card visibility" className="submenu w-100">
                        <Dropdown.Item href="" onClick={() => hideTopCard("no")}>All</Dropdown.Item>
                        <Dropdown.Item href="" onClick={() => hideTopCard("yes")}>No one </Dropdown.Item>
                        <Dropdown.Item href="" onClick={() => hideTopCard("opponent-only")}>Only you</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className={props.topCardHiddenTo["KEEP"] ? "selected" : ""} href="" onClick={() => toggleKeepTopCardHideen()}>Keep on deck change</Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item className={!props.topCardHiddenTo[props.playerId] ? "selected" : ""} href="" onClick={() => hideTopCard(props.playerId)}>You</Dropdown.Item>
                        {props.opponentIds.map((o, i) => {
                            if (props.opponentsData && props.opponentsData[o]) {
                                return (<Dropdown.Item className={!props.topCardHiddenTo[o] ? "selected" : ""} key={o} onClick={() => hideTopCard(o)}>{props.opponentsData[o].profileData.name}</Dropdown.Item>)
                            }
                        })}
                    </DropdownButton>
                    <Dropdown.Divider />
                    <Dropdown.Item href="" onClick={() => toSection(0, SECTIONS.DISCARD)}>Discard top</Dropdown.Item>
                    {/*<Dropdown.Item href="" onClick={() => props.draw(1)}>Exile</Dropdown.Item>*/}
                    <Dropdown.Item href="" onClick={() => { setShowTopDeckManager(true); setShowDeckSearch(false) }}>Manage top cards</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => { setShowDeckSearch(true); setShowTopDeckManager(false) }}>Search</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => shuffleDeck()}>Shuffle</Dropdown.Item>
                </DropdownButton>)}
            </div>
            <TopDeckManager
                deck={props.deck}
                show={showTopDeckManager}
                onHide={() => {
                    setShowTopDeckManager(false)
                }}
                onExit={() => {
                    let historyText = "has looked the top " + topCardsRevealedTotal + " card" + (topCardsRevealedCount > 1 ? "s" : "")
                    if (hasMovedCard) {
                        historyText += " and reorganized them"
                    }
                    props.sendToHistory(historyText)
                    setTopCardsRevealedCount(0)
                }}
                moveCard={moveCard}
                toSection={toSection}
                cardsRevealedCount={topCardsRevealedCount}
                setCardsRevealedCount={setTopCardsRevealedCount}
                increaseCardsRevealedTotal={() => setTopCardsRevealedTotal(topCardsRevealedTotal + 1)}
                toDeckBottom={toDeckBottom}
                revealCard={revealCard}
            />
            <DeckSearch
                deck={props.deck}
                show={showDeckSearch}
                onHide={() => setShowDeckSearch(false)}
                toSection={toSection}
                onDeckSearchExit={onDeckSearchExit}
            />
        </div>
    )
}

function TopDeckManager(props) {
    return (
        <Modal className="fullscreen-modal modal-game-panel" onExit={props.onExit} backdrop="static" show={props.show} onHide={props.onHide} centered>
            <Modal.Header className='d-flex justify-content-between'>
                <Modal.Title>Look and manage the top of your deck (currently {props.cardsRevealedCount})</Modal.Title>
                <Button variant="primary" className="my-auto" onClick={() => { props.setCardsRevealedCount(props.cardsRevealedCount + 1); props.increaseCardsRevealedTotal() }}>Show one more</Button>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-row h-100 w-100 overflow-x-scroll">
                    {props.cardsRevealedCount > 0 && (<div className="d-flex flex-row position-relative h-100">
                        {props.cardsRevealedCount && props.deck.slice(-1 * props.cardsRevealedCount).reverse().map((c, i) => {
                            return (<DeckCard
                                key={c.id}
                                card={c}
                                index={i}
                                cardsRevealedCount={props.cardsRevealedCount}
                                moveCard={props.moveCard}
                                toSection={(i, s) => { props.toSection(i, s); props.setCardsRevealedCount(props.cardsRevealedCount - 1) }}
                                toDeckBottom={() => { props.toDeckBottom(i); props.setCardsRevealedCount(props.cardsRevealedCount - 1) }}
                                revealCard={() => { props.revealCard(c) }}
                            />)
                        })}
                    </div>)}
                    {props.cardsRevealedCount == 0 && (
                        <p className='box-container my-auto mx-5'>You are not looking at any card yet</p>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function DeckSearch(props) {
    const [searchQuery, setSearchQuery] = useState("")

    return (
        <Modal className="fullscreen-modal modal-game-panel" backdrop="static" show={props.show} onHide={props.onHide} centered
            onExited={() => {
                setSearchQuery("")
                props.onDeckSearchExit()
            }}>
            <Modal.Header className='d-flex justify-content-between'>
                <Modal.Title>Search for cards in your deck</Modal.Title>
                <Form.Control
                    type="text"
                    defaultValue={searchQuery}
                    placeholder="Card name"
                    className='w-25 mx-0'
                    onChange={(e) => {
                        setSearchQuery(e.target.value.toLowerCase())
                    }}
                />
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-row h-100 w-100 overflow-x-scroll">
                    <div className="d-flex flex-row position-relative h-100">
                        {props.deck.map((c, i) => {
                            if (c.cardData.name.toLowerCase().includes(searchQuery)) {
                                return (<DeckCard
                                    key={c.id}
                                    card={c}
                                    index={props.deck.length - 1 - i}
                                    toSection={props.toSection}
                                />)
                            }
                        })}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Close and shuffle
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function DeckCard(props) {
    return (
        <div className="modal-panel-card position-relative d-flex flex-column justify-content-between pb-4">
            <div className='position-relative h-100 w-100 mb-2'>
                <img src={props.card.cardData.face.front.image} className='h-100' />
                <div className="position-absolute top-0 start-0 h-100 w-100 pe-none">
                    {props.moveCard && props.index != 0 && (<Button onClick={() => props.moveCard(props.index, 1)} className="position-absolute top-50 translate-y-middle pe-auto" style={{ left: "-1rem" }}>{"<"}</Button>)}
                    {props.moveCard && props.index != props.cardsRevealedCount - 1 && (<Button onClick={() => props.moveCard(props.index, -1)} className="position-absolute top-50 translate-y-middle pe-auto" style={{ right: "-1rem" }}>{">"}</Button>)}
                </div>
            </div>
            <div className="box-container flex-column m-0 position-absolute start-50 top-50 translate-middle d-none">
                <div className='d-flex w-100'>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.HAND)}>Hand</Button>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.STACK)}>Play</Button>
                </div>
                <div className='d-flex w-100 mx-0'>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.EXILE)}>Remove</Button>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.EXILE_HIDDEN)}>Remove hidden</Button>
                </div>
                {props.revealCard && props.toDeckBottom && (<div className='d-flex w-100 mx-0'>
                    <Button className="w-100 m-1" onClick={() => props.revealCard()}>Reveal</Button>
                    <Button className="w-100 m-1" onClick={() => props.toDeckBottom()}>To deck bottom</Button>
                </div>)}
            </div>
        </div>
    )
}