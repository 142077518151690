import { Col, Container, Dropdown, Form, Image, Row } from 'react-bootstrap'
import './Deckbuilder.scss'
import CardSearch from './CardSearch/CardSearch'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import DeckListPanel from './DeckListPanel/DeckListPanel'
import DeckBuilderTopSection from './DeckBuilderTopSection'
import Footer from '../Commons/Footer/Footer'
import Navigation from '../Commons/Navigation/Navigation'
import { useParams } from 'react-router'
import { useGameContext } from '../Commons/GameContext'
import FullscreenDecklist from './FullscreenDecklist/FullscreenDecklist'

export default function Deckbuilder(props) {
    const cardsListRef = useRef([])
    const [deck, setDeck] = useState(false)
    const [focusedCard, setFocusedCard] = useState(false)
    const { deckId } = useParams();
    const { currentGame, gameData, gameCards } = useGameContext()
    const [formatData, setFormatData] = useState(false)
    const [fullscreenView, setFullscreenView] = useState(false)
    const hasUnsavedChangesRef = useRef(false)

    // INI
    useEffect(() => {
        if (deckId) {
            let deckData = localStorage.getItem("user_decks_" + currentGame.selected + "_" + deckId)
            if (deckData) {
                const deckTmp = JSON.parse(deckData);

                deckTmp.deckList.categoriesOrder.forEach(cat => {
                    let newCat = []
                    deckTmp.deckList[cat].forEach((card, i) => {
                        if (gameCards[card.id]) {
                            let cardData = { ...gameCards[card.id] }
                            cardData.count = card.count
                            newCat.push(cardData)
                        }
                    })
                    deckTmp.deckList[cat] = newCat
                })

                setDeck(deckTmp)

                let deckFormat = deckTmp.format
                gameData.deckBuilding.formats.forEach((f) => {
                    if (f.title === deckFormat) {
                        setFormatData(f)
                    }
                })
            } else {
                console.error("Aucun deck trouvée dans le localStorage.");
            }
        } else {
            console.error("No deck id")
        }
    }, [])

    useEffect(() => {
        if (deck.deckList && deck.deckList.categoriesOrder) {
            let count = 0

            deck.deckList.categoriesOrder.forEach((cat) => {
                deck.deckList[cat].forEach((c) => {
                    count += c.count
                })
            })

            console.log(count)
            let tmp = { ...deck }
            tmp.cardCount = count
            setDeck(tmp)
        }
    }, [deck.deckList])

    function addCardToDeckList(card) {
        let tmpDeckList = { ...deck.deckList }
        pushCardIntoDecklist(card, 1, tmpDeckList)

        let tmp = { ...deck }
        tmp.deckList = tmpDeckList
        setDeck(tmp)
    }

    function addCardsToDeckList(cardsList, deckList) {
        cardsList.forEach((c) => {
            pushCardIntoDecklist(c.card, c.count, deckList)
        })

        let tmp = { ...deck }
        tmp.deckList = deckList
        setDeck(tmp)
    }

    function pushCardIntoDecklist(card, count, decklist) {
        if (!decklist[card.type]) {
            decklist[card.type] = []
            decklist.categoriesOrder.push(card.type)
        }

        let cardIndex = -1
        decklist[card.type].forEach((c, i) => {
            if (card.id == c.id) {
                cardIndex = i
            }
        })

        if (cardIndex == -1) {
            let newCard = { ...card }
            newCard.count = count
            decklist[card.type].push(newCard)
        } else {
            decklist[card.type][cardIndex].count += count
        }

        // ORDER BY NAME
    }

    function addOneToDeckList(card, index, categorie) {
        let tmpDeckList = { ...deck.deckList }
        tmpDeckList[categorie][index].count += 1

        let tmp = { ...deck }
        tmp.deckList = tmpDeckList
        setDeck(tmp)
    }

    function removeOneFromDeckList(card, index, categorie) {
        let tmpDeckList = { ...deck.deckList }
        tmpDeckList[categorie][index].count -= 1
        if (tmpDeckList[categorie][index].count <= 0) {
            tmpDeckList[categorie].splice(index, 1)
            if (tmpDeckList[categorie].length <= 0) {
                delete tmpDeckList[categorie]
                tmpDeckList.categoriesOrder.forEach((c, i) => {
                    if (c == categorie) {
                        tmpDeckList.categoriesOrder.splice(i, 1)
                    }
                })
            }
        }

        let tmp = { ...deck }
        tmp.deckList = tmpDeckList
        setDeck(tmp)
    }

    function moveToCategorie(card, index, categorie) {
        let decklist = { ...deck.deckList }
        if (!decklist[categorie]) {
            decklist[categorie] = []
            decklist.categoriesOrder.unshift(categorie)
        }

        // Remove in exisitng categoie
        decklist[card.type].splice(index, 1)
        if (decklist[card.type].length <= 0) {
            delete decklist[card.type]
            decklist.categoriesOrder.forEach((c, i) => {
                if (c == card.type) {
                    decklist.categoriesOrder.splice(i, 1)
                }
            })
        }

        // Add in new categorie
        let cardIndex = -1
        decklist[categorie].forEach((c, i) => {
            if (card.id == c.id) {
                cardIndex = i
            }
        })

        if (cardIndex == -1) {
            let newCard = { ...card }
            newCard.count = card.count
            decklist[categorie].push(newCard)
        } else {
            decklist[categorie][cardIndex].count += card.count
        }

        let tmp = { ...deck }
        tmp.deckList = decklist
        setDeck(tmp)
    }

    if (!deck) { return (<p>LOADING</p>) }

    return (
        <div className="deck-builder w-100">
            <Navigation currentPage="DeckList" hasUnsavedChangesRef={hasUnsavedChangesRef} />
            <DeckBuilderTopSection
                deck={deck} setDeck={setDeck}
                cardsListRef={cardsListRef}
                addCardsToDeckList={addCardsToDeckList}
                hasUnsavedChangesRef={hasUnsavedChangesRef}
            />
            {fullscreenView && (<FullscreenDecklist
                deckList={deck.deckList}
                cardCount={deck.cardCount}
                setFocusedCard={setFocusedCard}
                toggleFullscreenView={() => setFullscreenView(false)}
            />)}
            <Container className={'position-relative' + (fullscreenView ? " d-none" : "")}>
                <Row>
                    <Col xs={9}>
                        <CardSearch
                            addCardToDeckList={addCardToDeckList}
                            setFocusedCard={setFocusedCard}
                            cardsListRef={cardsListRef}
                        />
                    </Col>
                    <Col xs={3}>
                        <DeckListPanel
                            deckList={deck.deckList}
                            cardCount={deck.cardCount}
                            addOne={addOneToDeckList}
                            removeOne={removeOneFromDeckList}
                            moveToCategorie={moveToCategorie}
                            setFocusedCard={setFocusedCard}
                            formatData={formatData}
                            toggleFullscreenView={() => setFullscreenView(true)}
                        />
                    </Col>
                </Row>
            </Container>
            <Footer />
            <FocusedCard focusedCard={focusedCard} />
        </div>
    )
}

function FocusedCard(props) {
    let focusedCard = props.focusedCard
    const [showFront, setShowFront] = useState(true)
    const [isFlipping, setIsFlipping] = useState(false)
    const [toggleShowFront, setToggleShowFront] = useState(false)
    const toggleShowFrontRef = useRef(false)

    const { cardSize } = useGameContext()
    const [pos, setPos] = useState({ left: 0, top: 0 })
    const [cardHeight, setCardHeight] = useState(50 * cardSize.vh)
    const cardWidth = cardHeight * 0.7

    useEffect(() => {
        const handleKeyUp = e => {
            if (e.key == "f") {
                toggleShowFrontRef.current = !toggleShowFrontRef.current
                setToggleShowFront(toggleShowFrontRef.current)
            }
        }
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener(
                'keyup',
                handleKeyUp,
            );
        };
    }, []);

    useEffect(() => {
        setCardHeight(50 * cardSize.vh)
    }, [cardSize]);

    useEffect(() => {
        if (focusedCard) {
            if (showFront === true && focusedCard.face.back) {
                setShowFront(false)
            } else {
                setShowFront(true)
            }
            setIsFlipping(true)
            setTimeout(() => {
                setIsFlipping(false)
            }, 150)
        }
    }, [toggleShowFront])

    let isHorizontal = focusedCard && (showFront ? focusedCard.face.front.isHorizontal : focusedCard.face.back.isHorizontal)

    const isInDecklist = focusedCard && focusedCard.count ? true : false

    useLayoutEffect(() => {
        if (focusedCard) {
            let el = document.getElementById((isInDecklist ? "decklist-" : "") + focusedCard.id);
            if (el) {
                let rect = el.getBoundingClientRect();

                let processedCardSize = {
                    width: isHorizontal ? cardHeight : cardWidth,
                    height: isHorizontal ? cardWidth : cardHeight
                }
                let halfHeightDiff = (processedCardSize.height - rect.height) / 2

                let newPos = {
                    left: rect.x + rect.width,
                    top: rect.y - halfHeightDiff
                }

                if (isInDecklist) {
                    newPos = {
                        left: rect.x - rect.width,
                        top: rect.y - halfHeightDiff
                    }
                }

                const limits = {
                    minT: 0,
                    maxT: window.innerHeight,
                    minL: -processedCardSize.width,
                    maxL: window.innerWidth,
                }

                if (newPos.left < limits.minL) {
                    newPos.left = limits.minL
                }
                if (newPos.top < limits.minT) {
                    newPos.top = limits.minT
                }
                if (newPos.left > limits.maxL - processedCardSize.width) {
                    newPos.left = limits.maxL - processedCardSize.width
                }
                if (newPos.top > limits.maxT - processedCardSize.height) {
                    newPos.top = limits.maxT - processedCardSize.height
                }
                setPos(newPos)
            }
        }
    }, [props.focusedCard, showFront])

    useEffect(() => {
        setShowFront(true)
        setIsFlipping(false)
    }, [focusedCard])

    if (!focusedCard) { return }

    return (
        <div key={focusedCard.id} class={"focused-card position-fixed" + (showFront ? "" : " show-back") + (isHorizontal ? " horizontal" : "") + (isInDecklist ? " decklist" : "") + (isFlipping ? " flipping" : "")} style={{ ...pos }}>
            <Image src={focusedCard.face.front.image} className="card-front w-100 h-100 position-absolute top-0 start-0" />
            {focusedCard.face.back && (<Image src={focusedCard.face.back.image} className="card-back w-100 h-100 position-absolute top-0 start-0" />)}
            {focusedCard.face.back && (<div className='position-absolute flip-indicator translate-middle'>F</div>)}
        </div>
    )
}