import { Button } from "react-bootstrap"

export default function LeftBarButton(props) {
    return (
        <TootltipOverlay tooltip={props.tooltip}>
            <Button variant="primary" className={props.className} onClick={props.onClick}>{props.children}</Button>
        </TootltipOverlay>)

    function TootltipOverlay(props) {
        return (
            <div className='position-relative tooltip-overlay'>
                {props.children}
                <div className='position-absolute overlay'><p className='m-0'>{props.tooltip}</p></div>
            </div>)
    }
}