import { Button, Dropdown, DropdownButton, Modal, Offcanvas } from 'react-bootstrap'
import MouseDropableSection from '../MouseDropableSection'
import { SECTIONS } from '../PlayerSection/PlayerSection'
import './Discard.scss'
import { useState } from 'react'

export default function Discard(props) {
    const [showDiscardModal, setShowDiscardModal] = useState(false)
    const [showExileModal, setShowExileModal] = useState(false)
    const [showExileHiddenModal, setShowExileHiddenModal] = useState(false)
    
    return (
        <MouseDropableSection
            className="discard position-absolute"
            sectionRef={props.discardRef}
            section={SECTIONS.DISCARD}
            cardDraggedRef={props.cardDraggedRef}
            moveCard={props.moveCard}
            repositionCards={props.repositionCards}
        >
            <div>
                <div className="interaction-zone position-absolute top-0 start-0 w-100 h-100">
                    <DropdownButton className="position-absolute top-0 start-0 quick-actions" title="">
                        <Dropdown.Item onClick={() => setShowDiscardModal(true)}>Show discard</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowExileModal(true)}>Show removed</Dropdown.Item>
                        <Dropdown.Item onClick={() => setShowExileHiddenModal(true)}>Show removed hidden</Dropdown.Item>
                    </DropdownButton>
                </div>
                <p className='position-absolute top-50 z-1 start-50 translate-middle pe-none section-card-count'>{props.sectionCards ? props.sectionCards.length : 0}</p>
            </div>
            <DiscardModal
                show={showDiscardModal}
                onHide={() => setShowDiscardModal(false)}
                sectionCards={props.sectionCards}
                title="Cards in your discard"
                emptyMessage="Your discard is empty"
                section={SECTIONS.DISCARD}
                moveCard={props.moveCard}
                repositionCards={props.repositionCards}
            />
            <DiscardModal
                show={showExileModal}
                onHide={() => setShowExileModal(false)}
                sectionCards={props.exileSectionCard}
                title="Cards removed from the game"
                emptyMessage="Your don't have any card removed from the game"
                section={SECTIONS.EXILE}
                moveCard={props.moveCard}
                repositionCards={props.repositionCards}
            />
            <DiscardModal
                show={showExileHiddenModal}
                onHide={() => setShowExileHiddenModal(false)}
                sectionCards={props.exileHiddenSectionCard}
                title="Cards removed from the game and hidden from your opponents"
                emptyMessage="Your don't have any card removed from the game"
                section={SECTIONS.EXILE_HIDDEN}
                moveCard={props.moveCard}
                repositionCards={props.repositionCards}
            />
        </MouseDropableSection>
    )
}

function DiscardModal(props) {
    function toSection(i, section) {
        let c = props.sectionCards[i]
        props.moveCard(c.card, section)
        props.repositionCards()
    }

    return (
        <Modal className="fullscreen-modal modal-game-panel" backdrop="static" show={props.show} onHide={props.onHide} centered>
            <Modal.Header>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-row overflow-x-scroll overflow-y-hidden">
                    <div className="d-flex flex-row position-relative h-100 w-100 align-items-center justify-content-start">
                        {props.sectionCards && props.sectionCards.map((c, i) => {
                            return (<DiscardCard
                                key={c.card.id}
                                card={c.card}
                                index={i}
                                toSection={toSection}
                                section={props.section}
                            />)
                        })}
                        {props.sectionCards && props.sectionCards.length == 0 && (
                            <div className='box-container p-5'>{props.emptyMessage}</div>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function DiscardCard(props) {
    return (
        <div className="modal-panel-card position-relative d-flex flex-column justify-content-between pb-4">
            <div className='position-relative h-100 w-100 mb-2'>
                <img src={props.card.cardData.face.front.image} className='h-100' />
            </div>
            <div className="box-container flex-column m-0 position-absolute start-50 top-50 translate-middle d-none">
                <div className='d-flex w-100'>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.HAND)}>Hand</Button>
                    <Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.STACK)}>Play</Button>
                </div>
                <div className='d-flex w-100 mx-0'>
                    {props.section != SECTIONS.DISCARD && (<Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.DISCARD)}>Discard</Button>)}
                    {props.section != SECTIONS.EXILE && (<Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.EXILE)}>Remove</Button>)}
                    {props.section != SECTIONS.EXILE_HIDDEN && (<Button className="w-100 m-1" onClick={() => props.toSection(props.index, SECTIONS.EXILE_HIDDEN)}>Remove hidden</Button>)}
                </div>
            </div>
        </div>
    )
}