import React, { createContext, useContext, useEffect, useRef, useState } from 'react';

const GameContext = createContext();

export const useGameContext = () => {
    return useContext(GameContext);
};

export const GameProvider = ({ children }) => {
    const [currentGame, setCurrentGame] = useState(false);
    const [isLoadingGameData, setIsLoadingGameData] = useState(false)
    const [gameData, setGameData] = useState(false)
    const [gameCards, setGameCards] = useState({})
    const hasBeenInitialized = useRef(false)
    const [readyToDisplay, setReadyToDisplay] = useState(false)
    const gamesStorageKey = "loaded_game_files"
    const [cardSize, setCardSize] = useState({ vh: 1 })

    useEffect(() => {
        if (!hasBeenInitialized.current) {
            hasBeenInitialized.current = true

            let loadedGamesJSON = localStorage.getItem(gamesStorageKey);

            if (loadedGamesJSON) {
                const loadedGames = JSON.parse(loadedGamesJSON);
                setCurrentGame(loadedGames)
                // current game il est vide a ce moment la
                if (loadedGames.selected) {
                    loadGame(loadedGames.selected, loadedGames)
                } else {
                    setReadyToDisplay(true)
                }
            } else {
                const defaultValue = {
                    selected: false,
                    available: []
                }
                setCurrentGame(defaultValue)
                localStorage.setItem(gamesStorageKey, JSON.stringify(defaultValue));
                setReadyToDisplay(true)
            }
        }

        // Vh calculation
        const handleResize = () => {
            const newVh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.01
            setCardSize({
                vh: newVh
            })
        };
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    useEffect(() => {
        if (currentGame) {
            localStorage.setItem(gamesStorageKey, JSON.stringify(currentGame));
        }
    }, [currentGame])

    function switchGame(gameTitle) {
        let tmp = { ...currentGame }
        tmp.selected = gameTitle
        setCurrentGame(tmp)
        loadGame(gameTitle)
    }

    async function loadGame(gameTitle, customCurrentGame) {
        let cGame = customCurrentGame ? customCurrentGame : currentGame
        setIsLoadingGameData(true)
        const availableGameIndex = cGame.available.findIndex(a => a.name === gameTitle)
        if (availableGameIndex !== -1) {
            let gameDataUrl = cGame.available[availableGameIndex].url

            // Load game data json
            let gameFileData = await fetchJSON(gameDataUrl)
            // Load game card json
            let cardsFileData = await fetchJSON(gameFileData.cards.dataUrl)

            setGameData(gameFileData)
            setGameCards(cardsFileData)
            console.log("Game data and cards loaded")
            setIsLoadingGameData(false)
            setReadyToDisplay(true)
        } else {
            setIsLoadingGameData(false)
            console.error("Can't find game to load")
            setReadyToDisplay(true)
        }
    }

    async function fetchJSON(url) {
        const response = await fetch(url);

        if (!response.ok) {
            throw new Error(`Erreur de téléchargement pour ${url}`);
        }

        const data = await response.json();

        return data
    }

    return (
        <GameContext.Provider value={{ currentGame, setCurrentGame, switchGame, gameData, gameCards, isLoadingGameData, cardSize }}>
            {readyToDisplay && (<div>{children}</div>)}
        </GameContext.Provider>
    );
};
