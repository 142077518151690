import { Button, Col, Container, FloatingLabel, Form, Modal, Row } from "react-bootstrap"
import Navigation from "../../Commons/Navigation/Navigation"
import { useEffect, useRef, useState } from "react"
import './Decks.scss'
import DeckTopSection from "./DeckTopSection"
import Footer from "../../Commons/Footer/Footer"
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from "react-router"
import { useGameContext } from "../../Commons/GameContext"
import { CustomTimeAgo } from "../../Commons/Functions"


const defaultDeck = {
    title: "my deck with a long namr",
    id: "zerezrzer",
    game: "Lorcana",
    format: "Classic",
    cardCount: "0",
    createdAt: "ezrzt",
    lastModifiedAt: "",
    deckList: {
        "categoriesOrder": []
    }
}

export default function Decks() {
    const { currentGame, gameData, gameCards } = useGameContext()
    const navigate = useNavigate()
    const [userDecks, setUserDecks] = useState([])
    const [displayedUserDecks, setDisplayedUserDecks] = useState([])
    const [selectedFormat, setSelectedFormat] = useState("None")

    const [showCreateDeckModal, setShowCreateDeckModal] = useState(false)
    const [showDeleteDeckModal, setShowDeleteDeckModal] = useState({ val: false, deckId: false })
    const userDecksStorageKey = useRef("")

    const [navigationToggle, setNavigationToggle] = useState(0)

    useEffect(() => {
        if (!currentGame.selected) { return }
        userDecksStorageKey.current = "user_decks_" + currentGame.selected
        let decksSavedData = localStorage.getItem(userDecksStorageKey.current);

        if (decksSavedData) {
            const decksSaved = JSON.parse(decksSavedData);
            let tmp = []
            decksSaved.forEach((d) => {
                const newDeckData = localStorage.getItem(userDecksStorageKey.current + '_' + d);
                if (newDeckData) {
                    const newDeck = JSON.parse(newDeckData);
                    tmp.push(newDeck)
                } else {
                    console.log("Error loading deck " + d)
                }
            })
            setUserDecks(tmp)
        } else {
            localStorage.setItem(userDecksStorageKey.current, JSON.stringify([]));
        }
    }, [currentGame.selected])

    useEffect(() => {
        if (gameData) {
            setSelectedFormat(gameData.deckBuilding.formats[0].title)
        }
    }, [gameData])

    useEffect(() => {
        updateDisplayedUserDecks(selectedFormat)
    }, [userDecks, selectedFormat])

    function updateDisplayedUserDecks(format) {
        let tmp = []
        userDecks.forEach((d) => {
            if (d.format == format) {
                tmp.push(d)
            }
        })
        setDisplayedUserDecks(tmp)
    }

    function createNewDeck() {
        const newDeck = { ...defaultDeck }
        newDeck.id = uuidv4()
        newDeck.title = document.getElementById('deck-title').value
        newDeck.format = document.getElementById('deck-format').value
        let currentDate = new Date()
        newDeck.createdAt = currentDate
        newDeck.lastModifiedAt = currentDate

        let decksSavedData = localStorage.getItem(userDecksStorageKey.current);

        if (decksSavedData) {
            const decksSaved = JSON.parse(decksSavedData);

            const tmpUserDecks = [...userDecks, newDeck]
            setUserDecks(tmpUserDecks)

            const tmpDecksSaved = [...decksSaved, newDeck.id]
            localStorage.setItem(userDecksStorageKey.current, JSON.stringify(tmpDecksSaved));

            localStorage.setItem(userDecksStorageKey.current + '_' + newDeck.id, JSON.stringify(newDeck));
        }
        setNavigationToggle(navigationToggle + 1)
    }

    function deleteDeck() {
        if (!showDeleteDeckModal.deckId) { return }
        let decksSavedData = localStorage.getItem(userDecksStorageKey.current);
        let index = userDecks.findIndex(d => d.id === showDeleteDeckModal.deckId)

        if (decksSavedData && index != -1) {
            let decksSaved = JSON.parse(decksSavedData);

            decksSaved.splice(index, 1)
            localStorage.setItem(userDecksStorageKey.current, JSON.stringify(decksSaved));

            let tmpUserDecks = [...userDecks]
            let deck = tmpUserDecks.splice(index, 1)[0]
            setUserDecks(tmpUserDecks)

            localStorage.removeItem(userDecksStorageKey.current + '_' + deck.id)
        }
        setNavigationToggle(navigationToggle + 1)
    }

    return (
        <div className="w-100 decks">
            <Navigation currentPage="Decks" toggle={navigationToggle} />
            <DeckTopSection>
                <div className="d-flex flex-row w-100 justify-content-between">
                    <FloatingLabel className="w-50" controlId={"floatingSelect-format-selection"} label={"Choose format"}>
                        <Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                setSelectedFormat(e.currentTarget.value)
                            }}
                        >
                            {gameData && gameData.deckBuilding.formats.map((v, j) => {
                                return (<option key={v.title} value={v.title}>{v.title}</option>)
                            })}
                        </Form.Select>
                    </FloatingLabel>
                    <Button variant="secondary" onClick={() => setShowCreateDeckModal(true)}>Create deck</Button>
                </div>
            </DeckTopSection>
            <Container style={{ minHeight: "70vh" }} className="position-relative">
                <Row>
                    {displayedUserDecks.map((deck, i) => {
                        return (
                            <Col xs={6} key={deck.id}>
                                <div className="box-container position-relative">
                                    <DeckBackground deck={deck} />
                                    <div className="d-flex flex-column posiiton-relative z-1 h-100 w-100">
                                        <h3 className="text-start m-2">{deck.title}</h3>
                                        <div className="d-flex flex-row justify-content-between align-items-center">
                                            <p className="my-0">Cards: {deck.cardCount}</p>
                                            <p className="my-0">Last modified <CustomTimeAgo date={deck.lastModifiedAt} /></p>
                                            <div className="d-flex">
                                                <Button variant="secondary" onClick={() => setShowDeleteDeckModal({ val: true, deckId: deck.id })}><img src={require("../../Commons/Assets/Icons/delete.png")} /></Button>
                                                <Button onClick={() => navigate("/deck/" + deck.id)} className="ms-2">Edit deck</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>)
                    })}
                </Row>
            </Container>
            <Footer></Footer>
            {gameData && (<CreateDeckModal show={showCreateDeckModal} handleClose={() => { setShowCreateDeckModal(false) }} createNewDeck={createNewDeck} formats={gameData.deckBuilding.formats} selectedFormat={selectedFormat} />)}
            <DeleteDeckModal show={showDeleteDeckModal.val} handleClose={() => { setShowDeleteDeckModal({ val: false, deckId: false }) }} deleteDeck={deleteDeck} />
        </div>
    )

    function DeckBackground({ deck }) {
        if (!deck.deckList || deck.deckList.categoriesOrder.length == 0) { return }
        const cardId = deck.deckList[deck.deckList.categoriesOrder[0]][0].id
        
        if (cardId) {
            const card = gameCards[cardId]
            if (card) {
                return (
                    <div className="position-absolute top-0 start-0 deck-background w-100 h-100 d-flex justify-content-end">
                        <img src={card.face.front.image} />
                    </div>
                )
            }
        }
    }
}

function DeleteDeckModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Delete deck</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>This action cannot be undone.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {

                    props.handleClose()
                }}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    props.deleteDeck()
                    props.handleClose()
                }}>
                    Delete deck
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function CreateDeckModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>New deck</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <p className="m-0">Name</p>
                    <div className="d-flex flex-row box-container w-75">
                        <Form.Control
                            type="text"
                            id="deck-title"
                            defaultValue={""}
                            placeholder="Deck name"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                    props.createNewDeck()
                                    props.handleClose()
                                }
                            }}
                        />
                    </div>
                </div>

                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <p className="m-0">Format</p>
                    <div className="d-flex flex-row box-container w-75">
                        <Form.Select
                            aria-label="Default select example"
                            id="deck-format"
                            defaultValue={props.selectedFormat}
                            onChange={(e) => {
                                //e.currentTarget.value
                            }}
                        >
                            {props.formats.map((f, i) => {
                                return (<option key={f.title} value={f.title}>{f.title}</option>)
                            })}
                        </Form.Select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    props.createNewDeck()
                    props.handleClose()
                }}>
                    Create deck
                </Button>
            </Modal.Footer>
        </Modal>
    )
}