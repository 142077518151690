import { Button, Dropdown, DropdownButton, Form } from 'react-bootstrap'
import './PlayerCounters.scss'
import { useEffect, useState } from 'react'

export default function PlayerCounters(props) {
    const isOpponent = props.opponentIndex !== undefined
    const [notes, setNotes] = useState("")

    useEffect(() => {
        setNotes(props.notes)
    }, [props.notes])

    return (
        <div className={'d-flex flex-column position-relative player-counters-wrapper' + (isOpponent ? " opponent" : "")}>
            <div className='blur pe-none position-absolute w-100 h-100 start-0 top-0'></div>
            <div className='background pe-none position-absolute w-100 h-100 start-0 top-0'></div>
            {props.tokensAvailable && (<div className='tokens w-100 d-flex position-relative'>
                <div className='w-100 h-100 d-flex p-1'>
                    {props.tokens && props.tokensAvailable.map((t, i) => {
                        const isEnabled = props.tokens[t.name]
                        if (isEnabled) {
                            return (<Token key={i} image={t.image} />)
                        }
                    })}
                </div>
                {props.toggleToken && (<DropdownButton title="+" onClick={() => { }} className="ms-auto toggle-tokens m-1">
                    {props.tokens && props.tokensAvailable.map((t, i) => {
                        const isEnabled = props.tokens[t.name]
                        return (<Dropdown.Item key={i} className={isEnabled ? "selected" : ""} onClick={() => { props.toggleToken(t) }}>{isEnabled ? "Remove" : "Take"} {t.name}</Dropdown.Item>)
                    })}
                </DropdownButton>)}
            </div>)}
            <div className={'player-counters position-relative d-flex flex-row' + (props.changeCounterValue ? "" : " opponent-counters")}>
                {props.profileData.avatar != "" && (<img src={props.profileData.avatar} className='position-relative top-0 start-0 h-100 w-25' />)}
                <div className='position-relative d-flex flex-row w-100 h-100'>
                    {props.counters.map((counter, i) => {
                        return (<Counter
                            changeCounterValue={props.changeCounterValue}
                            index={i}
                            value={counter.value}
                            key={counter.id}
                        />)
                    })}
                </div>
                {isOpponent && (<DropdownButton onClick={props.onClick} className="player-menu d-none position-absolute top-0 start-0 quick-actions" title="">
                    <Dropdown.Item onClick={() => props.setShowOpponentModal("discard", props.opponentIndex, true)}>Show discard</Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setShowOpponentModal("exile", props.opponentIndex, true)}>Show removed</Dropdown.Item>
                </DropdownButton>)}
            </div>
            <div className={'notes position-absolute top-0 start-0 w-100 d-flex flex-column' + (isOpponent ? " opponent" : "") + (notes.length == 0 ? " empty" : "")}>
                <div className='blur position-absolute w-100 h-100 start-0 top-0'></div>
                <div className='background position-absolute w-100 h-100 start-0 top-0'></div>
                <p className='w-100 m-0 position-relative'>Notes</p>
                {!isOpponent && (
                    <Form.Control
                        className="text-start position-relative"
                        id="notes"
                        as="textarea"
                        placeholder="Notes..."
                        value={notes}
                        autoFocus
                        rows={5}
                        onChange={(e) => {
                            setNotes(e.currentTarget.value)
                        }}
                        onPointerLeave={() => {
                            if (!isOpponent && props.updateNotes) {
                                const input = document.getElementById('notes');
                                if (input) {
                                    props.updateNotes(input.value)
                                }
                            }
                        }}
                        onFocus={() => props.setIsTextInputFocused(true)}
                        onBlur={() => props.setIsTextInputFocused(false)}
                    />
                )}
                {isOpponent && (
                    <Form.Control
                        className="text-start position-relative"
                        as="textarea"
                        placeholder="Notes..."
                        value={props.notes}
                        rows={5}
                        readOnly
                    />
                )}
            </div>
        </div>
    )
}

function Token(props) {
    return (
        <div className="token position-relative h-100 w-100">
            <div className='wrapper h-100'>
                <img className='position-absolute start-0 top-0 object-fit-cover' src={props.image} />
            </div>
        </div>
    )
}

function Counter(props) {
    return (
        <div className="d-flex flex-row w-100 h-100">
            <Form.Control
                type="number"
                value={props.value}
                onChange={(e) => {
                    if (props.changeCounterValue) (
                        props.changeCounterValue(props.index, e.target.value)
                    )
                }}
                readOnly={!props.changeCounterValue}
            />
        </div>
    )
}