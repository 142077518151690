import { useEffect, useRef, useState } from "react"
import Card from "../Card/Card"
import './Hand.scss'
import { SECTIONS } from "../PlayerSection/PlayerSection"
import MouseDropableSection from "../MouseDropableSection"
import { Dropdown, DropdownButton } from "react-bootstrap"
import { hideCard } from "../../Commons/Functions"

export default function Hand(props) {

    function setAllIsHiddenTo(val) {
        const sectionCards = props.sectionCards
        sectionCards.forEach((c, i) => {
            hideCard(c.card, val, props.playerId, props.opponentIds)
        })

        if (val == "opponent-only") {
            props.sendToHistory("hid his hand")
        } else if (val == "no") {
            props.sendToHistory("revealed his hand")
        }
        props.repositionCards()
    }

    return (
        <div className="hand-board-section">
            <MouseDropableSection
                className="hand"
                sectionRef={props.handRef}
                section={SECTIONS.HAND}
                cardDraggedRef={props.cardDraggedRef}
                moveCard={props.moveCard}
                repositionCards={props.repositionCards}
            >
            </MouseDropableSection>
            <div className={"buttons d-flex flex-row position-absolute"}>
                <DropdownButton onClick={props.onClick} className="" title="">
                    <Dropdown.Item className="reveal-all" onClick={() => setAllIsHiddenTo("no")}>Reveal all</Dropdown.Item>
                    <Dropdown.Item className="hide-all" onClick={() => setAllIsHiddenTo("opponent-only")}>Hide all</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    )
}