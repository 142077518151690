//import data from '../CardData/Lorcana.json' assert {type: 'json'};

import './Game.scss'
import { Stack } from "react-bootstrap"
import PlayerSection, { SECTIONS } from "./PlayerSection/PlayerSection"
import { useEffect, useRef, useState } from "react"
import OpponentSection from "./OpponentSection/OpponentSection"
import FocusedCard from './FocusedCard'
import PlayerCounters from './PlayerCounters/PlayerCounters'
import Peer from 'peerjs'
import { v4 as uuidv4 } from 'uuid';
import History, { HISTORY_MESSAGE_TYPE } from './History/History'
import { defaultOptionsData, defaultPlayerData } from '../Home/Preference'
import CardsLinks from './CarsLinks'
import ActionMode, { ACTION_MODES } from './ActionMode'
import DiceModal from './GameModals/DiceModal'
import HiddenSectionModal from './OpponentSection/HiddenSectionModal'
import { useGameContext } from '../Commons/GameContext'
import HelpModal from './GameModals/HelpModal'
import ExitModal from './GameModals/ExitModal'
import MultiplayerModal from './GameModals/MultiplayerModal'
import PreferenceModal from './GameModals/PreferenceModal'
import FormatPickerModal from './GameModals/FormatPickerModal'
import LeftBarButton from './GameElements/LeftBarButton'
import NewTurnMessage from './GameElements/NewTurnMessage'
import TurnOrderModal from './GameModals/TurnOrderModal'


export default function Game() {
    const { gameData } = useGameContext()

    // MULTIPLAYER
    const peerInstance = useRef(null)
    const playersRef = useRef([])
    const playerDataRef = useRef({
        visibleCards: [],
        deck: [],
        deckTopCardHiddenTo: {},
        cardsLinks: [],
        profileData: { stillLoading: true },
        playerCounters: [
            {
                id: uuidv4(),
                value: 0
            },
            {
                id: uuidv4(),
                value: 0
            }
        ],
        playerTokens: {},
        playerNotes: ""
    })
    const [playerData, setPlayerData] = useState(playerDataRef.current)
    const opponentsDataRef = useRef({})
    const [opponentsData, setOpponentsData] = useState({})
    const commonDataRef = useRef({
        playerTurnOrder: [],
        history: []
    })
    const [playerTurnOrder, setPlayerTurnOrder] = useState([])
    const [opponentsDisplayOrder, setOpponentsDisplayOrder] = useState([])

    // PANELS
    const [showMultiplayerPanel, setShowMultiplayerPanel] = useState(false)
    const [showTurnOrderModal, setShowTurnOrderModal] = useState(false)
    const [showPreferencePanel, setShowPreferencePanel] = useState(false)
    const [showTokensPanel, setShowTokensPanel] = useState(false)
    const [showDiceModal, setShowDiceModal] = useState(false)
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showExitModal, setShowExitModal] = useState(false)
    const [showFormatPickerModal, setShowFormatPickerModal] = useState(false)
    const [showOpponentModals, setShowOpponentModals] = useState([{
        discard: false,
        exile: false
    },
    {
        discard: false,
        exile: false
    },
    {
        discard: false,
        exile: false
    }])

    // GAME DATA
    const [history, setHistory] = useState([])
    const [turnCount, setTurnCount] = useState(0)
    const [gameOptions, setGameOptions] = useState(false)
    const [focusedCard, setFocusedCard] = useState(false)
    const [pings, setPings] = useState([])
    const [opponentStackCards, setOpponentStackCards] = useState([])
    const [givenCard, setGivenCard] = useState(false)
    const [hasPassed, setHasPassed] = useState(false)
    const [currentPlayer, setCurrentPlayer] = useState(false)
    const [cardsLinks, setCardsLinks] = useState([])
    const [allCardsLinks, setAllCardsLinks] = useState([])
    const [currentActionMode, setCurrentActionMode] = useState({
        action: ACTION_MODES.NONE,
        actionData: false
    })
    const [tokensAvailable, setTokensAvailable] = useState(false)
    const [options, setOptions] = useState(false)

    // OTHER
    const [toggleRemovePresented, setToggleRemovePresented] = useState(false)
    const stackRef = useRef(null)
    const isYourTurn = gameOptions && ((gameOptions.format.newTurn.sharedTurn) || (!gameOptions.format.newTurn.sharedTurn && peerInstance.current && currentPlayer == peerInstance.current.id))
    const isActivePlayer = peerInstance.current && currentPlayer == peerInstance.current.id
    const [isTextInputFocused, setIsTextInputFocused] = useState(false);


    /*------------------------------- USE EFFECTS -------------------------------*/

    useEffect(() => {
        if (peerInstance.current == null) {
            iniPeer()
        }


        let profileJSON = localStorage.getItem('user_preference');
        let tmpPlayerData = { ...playerData }
        if (profileJSON) {
            const profileData = JSON.parse(profileJSON);
            tmpPlayerData.profileData = profileData
        } else {
            let defaultData = defaultPlayerData()
            localStorage.setItem('user_preference', JSON.stringify(defaultData));
            tmpPlayerData.profileData = defaultData
        }

        if (tmpPlayerData.profileData.background == "") {
            tmpPlayerData.profileData.background = gameData.defaultRessources.backgrounds[Math.floor(Math.random() * gameData.defaultRessources.backgrounds.length)];
            localStorage.setItem('user_preference', JSON.stringify(tmpPlayerData.profileData));
        }
        setPlayerData(tmpPlayerData)
        playerDataRef.current = tmpPlayerData

        let optionsJSON = localStorage.getItem('user_options')

        if (optionsJSON) {
            const optionsData = JSON.parse(optionsJSON);
            setOptions(optionsData)
        } else {
            let defaultData = defaultOptionsData()
            localStorage.setItem('user_options', JSON.stringify(defaultData));
            setOptions(defaultData)
        }

        const unload = async (e) => {
            closeCall()
        }
        const beforeUnload = async (e) => {
            e.preventDefault()
            return (e.returnValue = "Game progress will be lost.")
        }
        window.addEventListener('beforeunload', beforeUnload)
        window.addEventListener('unload', unload)
        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
            window.removeEventListener("unload", unload);
        };
    }, [])

    useEffect(() => {
        sendData({
            playerData: playerData
        })
        playerDataRef.current = playerData
    }, [playerData])

    useEffect(() => {
        opponentsDataRef.current = { ...opponentsData }
        let stackCards = []
        opponentsDisplayOrder.forEach((p) => {
            if (opponentsData[p]) {
                opponentsData[p].visibleCards.forEach((c) => {
                    if (c.position.section == SECTIONS.STACK) {
                        stackCards.push({ ...c })
                    }
                })
            }
        })
        setOpponentStackCards(stackCards)
    }, [opponentsData])

    useEffect(() => {
        let all = [...cardsLinks]
        opponentsDisplayOrder.forEach((p) => {
            if (opponentsData[p]) {
                all = [...all, ...opponentsData[p].cardsLinks]
            }
        })
        setAllCardsLinks(all)
    }, [cardsLinks, opponentsData])

    useEffect(() => {
        if (!playerDataRef.current.profileData.stillLoading) {
            let tmp = { ...playerDataRef.current }
            tmp.cardsLinks = [...cardsLinks]
            setPlayerData(tmp)
        }
    }, [cardsLinks])

    useEffect(() => {
        commonDataRef.current.playerTurnOrder = playerTurnOrder

        if (peerInstance.current.id) {
            let oppOrder = []
            let index = playerTurnOrder.findIndex(a => a == peerInstance.current.id)
            if (index >= 0) {
                if (index + 1 < playerTurnOrder.length) {
                    oppOrder = [...oppOrder, ...playerTurnOrder.slice(index + 1)]
                }
                oppOrder = [...oppOrder, ...playerTurnOrder.slice(0, index)]
            }
            setOpponentsDisplayOrder(oppOrder)
        }
    }, [playerTurnOrder])

    useEffect(() => {
        if (gameOptions) {
            let pData = { ...playerDataRef.current }
            const newCounters = []
            gameOptions.format.countersStartingValues.forEach((c) => {
                newCounters.push({
                    id: uuidv4(),
                    value: c
                })
            })
            pData.playerCounters = newCounters
            pData.playerNotes = ""
            if (gameOptions.format.defaultNotes) {
                pData.playerNotes = gameOptions.format.defaultNotes
            }
            pData.playerTokens = {}

            // First player token
            if (gameOptions.format.newTurn.sharedTurn) {
                let tokenName = gameOptions.format.newTurn.firstPlayerTokenName
                if (tokenName) {
                    if (peerInstance.current.id == currentPlayer) {
                        pData.playerTokens[tokenName] = true
                    }
                }
            }

            setTokensAvailable(gameOptions.format.tokens)

            playerDataRef.current = pData
            setPlayerData(pData)
            setHistory([])
            commonDataRef.current.history = []
        }
    }, [gameOptions])

    /*------------------------------- MULTIPLAYER -------------------------------*/

    const closeCall = () => {
        peerInstance.current.destroy()
        playersRef.current = []
    }

    const iniPeer = () => {
        const peer = new Peer(undefined, {
            config: {
                iceServers: [
                    {
                        urls: "turn:tcg-arena.fr:3478",
                        username: "turnuser",
                        credential: "turnuser123456"
                    }
                ]
            }
        });

        // Listening
        peer.on('open', (id) => {
            console.log('My peer ID is: ' + id);
            playersRef.current.push({ connection: null, id: id })

            setPlayerTurnOrder([id])
            commonDataRef.current.playerTurnOrder = [id]

            peer.on("connection", (conn) => {
                // Handle connection
                conn.on("open", () => {
                    if (commonDataRef.current.playerTurnOrder.findIndex(a => a == conn.peer) == -1) {
                        if (playersRef.current.length >= 4) {
                            conn.send({ errorMessage: "You have been kicked: server full" })
                            conn.close()
                            sendToHistory("Connection refused: server full", false, HISTORY_MESSAGE_TYPE.ERROR)
                        }

                        if (commonDataRef.current.gameOptions) {
                            conn.send({ errorMessage: "You have been kicked: game already started" })
                            conn.close()
                            sendToHistory("Connection refused: game already started", false, HISTORY_MESSAGE_TYPE.ERROR)
                        }

                        playersRef.current.push({ connection: conn, id: conn.peer })

                        const tmp = [...commonDataRef.current.playerTurnOrder]
                        tmp.push(conn.peer)
                        setPlayerTurnOrder(tmp)
                        commonDataRef.current.playerTurnOrder = tmp

                        sendData({
                            playerTurnOrder_discover: commonDataRef.current.playerTurnOrder,
                            playerData: playerDataRef.current,
                        })
                    } else {
                        sendData({
                            playerData: playerDataRef.current
                        })
                    }
                })
                conn.on("close", () => {
                    aPlayerHasLeftTheGame(conn.peer)
                });

                // Handle game data
                conn.on("data", (data) => {
                    handleRecievedData(data, conn.peer)
                });
            });
        });

        peerInstance.current = peer;
    }

    const call = (peerId, isFromCallButton) => {
        if (peerId === peerInstance.current.id) {
            sendToHistory("You can't connect with yourself. Enter another player's id", false, HISTORY_MESSAGE_TYPE.ERROR, true)
            return
        }
        let conn = peerInstance.current.connect(peerId);
        conn.on("open", () => {
            playersRef.current.push({ connection: conn, id: conn.peer })
            if (isFromCallButton) {
                commonDataRef.current.playerTurnOrder.push(peerId)
            }

            sendData({
                playerData: playerDataRef.current,
                selectedGame: gameData.name
            })
            console.log("Connection succesfull to the host")
            if (isFromCallButton) {
                setTimeout(() => {
                    sendToHistory(playerData.profileData.name + " joined the game", false, HISTORY_MESSAGE_TYPE.GLOBAL)
                }, 1000)
            }
        })
        conn.on("close", () => {
            aPlayerHasLeftTheGame(peerId)
        })
        conn.on("data", (data) => {
            handleRecievedData(data, conn.peer)
        })
    }

    function aPlayerHasLeftTheGame(playerId) {
        let connIndex = playersRef.current.findIndex(a => a.id == playerId)

        // Remove connection
        if (connIndex != -1) {
            playersRef.current.splice(connIndex, 1)
        }

        // Display leaving message
        if (opponentsDataRef.current[playerId]) {
            sendToHistory(opponentsDataRef.current[playerId].profileData.name + " has left the game", false, HISTORY_MESSAGE_TYPE.GLOBAL, true)
        }

        // Remove from turn order
        let tmpTurnOrder = [...commonDataRef.current.playerTurnOrder]

        let index = tmpTurnOrder.findIndex(a => a == playerId)
        if (index != -1) {
            tmpTurnOrder.splice(index, 1)
            setPlayerTurnOrder(tmpTurnOrder)
        }

        // Remove opponent data
        let tmpOpponentsData = { ...opponentsDataRef.current }
        delete tmpOpponentsData[playerId]
        setOpponentsData(tmpOpponentsData)
    }

    const sendData = (data) => {
        playersRef.current.forEach((ref) => {
            if (ref.connection) {
                ref.connection.send(data)
            }
        })
    }

    const handleRecievedData = (data, senderId) => {
        const connIndex = playersRef.current.findIndex(a => a.id == senderId)
        if (connIndex == -1) { return }
        if (data.errorMessage) {
            sendToHistory(data.errorMessage, false, HISTORY_MESSAGE_TYPE.ERROR, true)
        }
        if (data.selectedGame) {
            if (data.selectedGame != gameData.name) {
                const conn = playersRef.current[connIndex].connection
                conn.send({ errorMessage: `You have been kicked: you are playing ${data.selectedGame} and not ${gameData.name}. Change game in the main menu"` })
                conn.close()
                sendToHistory(`Player failed to join: playing ${data.selectedGame} instead of ${gameData.name}`, false, HISTORY_MESSAGE_TYPE.ERROR)
                return
            }
        }

        if (data.playerTurnOrder) {
            setPlayerTurnOrder([...data.playerTurnOrder])
            commonDataRef.current.playerTurnOrder = data.playerTurnOrder
        }
        if (data.playerTurnOrder_discover) {
            data.playerTurnOrder_discover.forEach((p) => {
                let index = commonDataRef.current.playerTurnOrder.findIndex(a => a == p)
                if (index == -1 && p != peerInstance.current.id) {
                    call(p)
                }
            })

            setPlayerTurnOrder([...data.playerTurnOrder_discover])
            commonDataRef.current.playerTurnOrder = data.playerTurnOrder_discover
        }

        if (data.ping) {
            createPingAtCard(data.ping)
        }


        if (data.playerData) {
            let tmpOpponentsData = { ...opponentsDataRef.current }
            tmpOpponentsData[senderId] = { ...data.playerData }
            setOpponentsData(tmpOpponentsData)
            opponentsDataRef.current = tmpOpponentsData
        }

        if (data.newToHistory) {
            if (data.newToHistory.id != commonDataRef.current.history.at(-1)) {
                commonDataRef.current.history = [data.newToHistory, ...commonDataRef.current.history]
                setHistory(commonDataRef.current.history)
            }
        }

        if (data.currentPlayer) {
            setCurrentPlayer(data.currentPlayer)
        }

        if (data.turnCount) {
            setTurnCount(data.turnCount)
            delete commonDataRef.current.endTurnInfo
            setHasPassed(false)
        }

        if (data.gameOptions) {
            commonDataRef.current.gameOptions = { ...data.gameOptions }
            setGameOptions(data.gameOptions)
        }

        if (data.giveCard && data.giveCard.playerId == peerInstance.current.id) {
            setGivenCard(data.giveCard.card)
        }

        if (data.endTurnInfo) {
            commonDataRef.current.endTurnInfo = { ...data.endTurnInfo }
        }

        if (data.claimedToken) {
            let tmp = { ...playerDataRef.current }
            if (tmp.playerTokens[data.claimedToken]) {
                delete tmp.playerTokens[data.claimedToken]
                playerDataRef.current = tmp
                setPlayerData(tmp)
            }
        }
    }





    /*------------------------------- GAME FUNCTIONS -------------------------------*/

    function sendToHistory(text, card, messageType, localOnly) {
        let message = {
            id: uuidv4(),
            player: playerDataRef.current.profileData.name,
            text: text,
            card: card ? { ...card } : false,
            type: messageType
        }

        commonDataRef.current.history = [message, ...commonDataRef.current.history]
        setHistory(commonDataRef.current.history)

        if (!localOnly) {
            sendData({
                newToHistory: message
            })
        }
    }

    function updateGameOptions(gameOptions) {
        commonDataRef.current.gameOptions = { ...gameOptions }
        setGameOptions(commonDataRef.current.gameOptions)
        sendData({
            gameOptions: gameOptions
        })
        let startingPlayerId = gameOptions.startingPlayer.id
        if (gameOptions.startingPlayer.id === "RANDOM_STARTING_PLAYER") {
            let randomIndex = Math.floor(Math.random() * playerTurnOrder.length);
            startingPlayerId = playerTurnOrder[randomIndex]
        }
        setPlayerTurnTo(startingPlayerId, true)
    }

    function endTurn() {
        if (gameOptions.format.newTurn.sharedTurn && playerTurnOrder.length != 1) {
            sendToHistory(playerData.profileData.name + " ended its turn", false, HISTORY_MESSAGE_TYPE.GLOBAL)
            let currentEndTurnInfo = commonDataRef.current.endTurnInfo

            if (currentEndTurnInfo) {
                if (currentEndTurnInfo.total == playerTurnOrder.length - 1) {
                    let newPlayer = commonDataRef.current.endTurnInfo.firtToPass
                    setTimeout(() => {
                        setPlayerTurnTo(newPlayer)
                    }, 100)
                    delete commonDataRef.current.endTurnInfo
                    setHasPassed(false)
                } else {
                    sendData({
                        endTurnInfo: {
                            total: currentEndTurnInfo.total + 1,
                            firtToPass: currentEndTurnInfo.firtToPass
                        }
                    })
                    setHasPassed(true)
                }
            } else {
                sendData({
                    endTurnInfo: {
                        total: 1,
                        firtToPass: peerInstance.current.id
                    }
                })
                setHasPassed(true)
            }
        } else {
            nextTurn()
        }
    }

    function nextTurn() {
        let nextPlayer = playerTurnOrder[0]
        let index = playerTurnOrder.findIndex((e) => e == currentPlayer)
        if (index != -1) {
            index += 1
            if (index >= playerTurnOrder.length) {
                index = 0
            }
            nextPlayer = playerTurnOrder[index]
        }
        setPlayerTurnTo(nextPlayer)
    }

    function setPlayerTurnTo(playerId, isRestart) {
        setTurnCount(isRestart ? 1 : turnCount + 1)
        setCurrentPlayer(playerId)
        sendData({
            currentPlayer: playerId,
            turnCount: isRestart ? 1 : turnCount + 1
        })

        let playerName = playerData.profileData.name
        if (opponentsData[playerId]) {
            playerName = opponentsData[playerId].profileData.name
        }

        let turn = gameOptions ? (gameOptions.format.newTurn.sharedTurn ? (turnCount + 1) : (Math.ceil((turnCount + 1) / playerTurnOrder.length))) : 0
        if (isRestart) {
            turn = 1
        }
        sendToHistory(playerName + " starting turn " + turn, false, HISTORY_MESSAGE_TYPE.GLOBAL)
    }

    function pingCard(card) {
        sendData({
            ping: card
        })
        createPingAtCard(card)
    }

    function createPingAtCard(card) {
        let el = document.getElementById(card.id + "-images");
        if (el) {
            let rect = el.getBoundingClientRect();
            // UTILISER LA TAILLE DE CARD ?

            let newPings = [...pings, {
                id: uuidv4(),
                time: new Date(),
                x: rect.x + rect.width / 2,
                y: rect.y + rect.height / 2
            }]

            const now = new Date()
            for (let i = 0; i < newPings.length - 1; i++) {
                if (now - newPings[i].time > 2000) {
                    newPings.splice(i, 1)
                    i -= 1
                }
            }

            setPings(newPings)
        }
    }

    function updatePlayerData(data) {
        if (!playerDataRef.current.profileData.stillLoading) {
            let tmp = { ...playerDataRef.current }
            tmp.visibleCards = [...data.visibleCards]
            tmp.deck = [...data.deck]
            tmp.deckTopCardHiddenTo = { ...data.deckTopCardHiddenTo }
            tmp.cardsLinks = [...cardsLinks]
            setPlayerData(tmp)
        }
    }

    function changeCounterValue(counterIndex, newValue) {
        let pData = { ...playerDataRef.current }
        pData.playerCounters[counterIndex].value = newValue
        setPlayerData(pData)
    }

    function toggleToken(token) {
        let pData = { ...playerDataRef.current }
        if (pData.playerTokens[token.name]) {
            delete pData.playerTokens[token.name]
            sendToHistory("has lost the " + token.name + " token")
        } else {
            pData.playerTokens[token.name] = true
            if (token.isUnique) {
                sendData({
                    claimedToken: token.name
                })
            }
            sendToHistory("has taken the " + token.name + " token")
        }
        setPlayerData(pData)
    }

    function updateNotes(newNotes) {
        let pData = { ...playerDataRef.current }
        pData.playerNotes = newNotes
        setPlayerData(pData)
    }

    function giveCardTo(card, destination) {
        sendData({
            giveCard: {
                card: card,
                playerId: destination
            }
        })
        sendToHistory("has given card", card)
    }

    function setShowOpponentModal(modal, index, value) {
        let tmp = [...showOpponentModals]
        tmp[index][modal] = value
        setShowOpponentModals(tmp)
    }

    if (!peerInstance.current) { return }

    return (
        <Stack className={"game position-relative" + (options ? (options.visiblePlayArea ? "" : " hide-play-area") : "") + (gameOptions && gameOptions.format.newTurn.sharedTurn ? " shared-turn" : "")} style={{ height: "100vh" }} direction="horizontal">
            <div style={{ width: "4rem" }} className="h-100 d-flex flex-column justify-content-between align-items-center left-bar">
                {gameOptions ? (<div style={{ height: "12vh" }}>
                    <p>Turn {gameOptions.format.newTurn.sharedTurn ? (turnCount) : (Math.ceil(turnCount / playerTurnOrder.length))}</p>
                    {isYourTurn && !hasPassed && (<LeftBarButton variant="primary" onClick={() => { endTurn() }} tooltip="End your turn"><img src={require('../Commons/Assets/Icons/right-arrow.png')} /></LeftBarButton>)}
                </div>) : (<div style={{ height: "12vh" }}></div>)}
                <div className="d-flex flex-column">
                    {!gameOptions && (<LeftBarButton variant="primary" onClick={() => { setShowMultiplayerPanel(true) }} tooltip="Connect with other players"><img src={require('../Commons/Assets/Icons/connect.png')} /></LeftBarButton>)}
                    <LeftBarButton variant="primary" onClick={() => { setShowTurnOrderModal(true) }} tooltip="Turn order"><img src={require('./Assets/turn-order.png')} /></LeftBarButton>
                    <LeftBarButton variant="primary" onClick={() => { setShowFormatPickerModal(true) }} tooltip="Start a new game"><img src={require(gameOptions ? './Assets/refresh.png' : './Assets/play.png')} /></LeftBarButton>
                    <LeftBarButton variant="primary" onClick={() => { setShowPreferencePanel(true) }} tooltip="Preferences"><img src={require('../Commons/Assets/Icons/settings.png')} /></LeftBarButton>
                    <LeftBarButton variant="primary" onClick={() => { setShowHelpModal(true) }} tooltip="Help"><img src={require('../Commons/Assets/Icons/question-mark.png')} /></LeftBarButton>
                </div>
                <div className="d-flex flex-column">
                    <LeftBarButton variant="primary" onClick={() => { setShowDiceModal(true) }} tooltip="Roll a dice"><img src={require('./Assets/dice.png')} /></LeftBarButton>
                    {gameOptions && (<LeftBarButton variant="primary" className="presented-toggle-button" onClick={() => { setToggleRemovePresented(!toggleRemovePresented) }} tooltip="Remove all cards presented"><img src={require('./Assets/remove-presented.png')} /></LeftBarButton>)}
                    {gameOptions && cardsLinks.length > 0 && (<LeftBarButton variant="primary" onClick={() => { setCardsLinks([]) }} tooltip="Delete all your card links"><img src={require('./Assets/cards-link-remove.png')} /></LeftBarButton>)}
                    {gameOptions && (<LeftBarButton variant="primary" onClick={() => { setShowTokensPanel(true) }} tooltip="Create token"><img src={require('./Assets/create-token.png')} /></LeftBarButton>)}
                </div>
                <div>
                    <LeftBarButton variant="primary" onClick={() => setShowExitModal(true)} tooltip="Exit"><img src={require('./Assets/exit.png')} /></LeftBarButton>
                </div>
            </div>
            <div className="d-flex flex-column h-100 w-100 position-relative" style={{ paddingRight: "32vh" }}>
                {playerTurnOrder.length <= 1 && (
                    <div className="position-relative h-50 w-100 d-flex align-items-center justify-content-center">
                        <div className='box-container position-relative align-items-center justify-content-center w-50 h-50'>
                            {!gameOptions && (<p>Use the <img src={require('../Commons/Assets/Icons/connect.png')} /> button to connect with other players</p>)}
                            {gameOptions && (<p>Game has started, new players can't join anymore.</p>)}
                        </div>
                    </div>
                )}
                {playerTurnOrder.length > 1 && (
                    <div className={"opponents position-relative h-50 w-100 total-" + (opponentsDisplayOrder.length)}>
                        {opponentsDisplayOrder.map((p, i) => {
                            if (p != peerInstance.current.id && opponentsData[p]) {
                                return (
                                    <OpponentSection
                                        opponentData={opponentsData[p]}
                                        setFocusedCard={setFocusedCard}
                                        pingCard={pingCard}
                                        stackRef={stackRef}
                                        isCurrentPlayer={currentPlayer == p}
                                        currentActionMode={currentActionMode}
                                        setCurrentActionMode={setCurrentActionMode}
                                        cardsLinks={cardsLinks}
                                        setCardsLinks={setCardsLinks}
                                        profileData={opponentsData[p].profileData}
                                        gameOptions={gameOptions}
                                        playerId={peerInstance.current.id}
                                        opponentId={p}
                                    />)
                            }
                        })}
                    </div>
                )}
                {opponentsDisplayOrder.map((p, i) => {
                    if (p != peerInstance.current.id && opponentsData[p]) {
                        return (
                            <div>
                                <HiddenSectionModal cards={opponentsData[p].visibleCards} section="Discard" show={showOpponentModals[i].discard} handleClose={() => setShowOpponentModal("discard", i, false)} />
                                <HiddenSectionModal cards={opponentsData[p].visibleCards} section="Exile" show={showOpponentModals[i].exile} handleClose={() => setShowOpponentModal("exile", i, false)} />
                            </div>)
                    }
                })}
                <PlayerSection
                    sendData={updatePlayerData}
                    turnCount={turnCount}
                    isYourTurn={isActivePlayer}
                    setFocusedCard={setFocusedCard}
                    pingCard={pingCard}
                    stackRef={stackRef}
                    opponentStackCards={opponentStackCards}
                    sendToHistory={sendToHistory}
                    opponentIds={opponentsDisplayOrder}
                    opponentsData={opponentsData}
                    givenCard={givenCard}
                    setGivenCard={setGivenCard}
                    giveCardTo={giveCardTo}
                    showTokensModal={showTokensPanel}
                    setShowTokensModal={setShowTokensPanel}
                    currentActionMode={currentActionMode}
                    setCurrentActionMode={setCurrentActionMode}
                    cardsLinks={cardsLinks}
                    setCardsLinks={setCardsLinks}
                    profileData={playerData.profileData}
                    gameOptions={gameOptions}
                    toggleRemovePresented={toggleRemovePresented}
                    playerId={peerInstance.current.id}
                    options={options}
                    removeTemporaryTokens={() => {
                        if (tokensAvailable) {
                            let tmp = { ...playerDataRef.current }
                            let hasChanged = false
                            tokensAvailable.forEach((t) => {
                                if (tmp.playerTokens[t.name] && t.leavesAtEndOfTurn) {
                                    delete tmp.playerTokens[t.name]
                                    hasChanged = true
                                }
                            })
                            if (hasChanged) {
                                playerDataRef.current = tmp
                                setPlayerData(tmp)
                            }
                        }
                    }}
                    setIsTextInputFocused={setIsTextInputFocused}
                />
                <History history={history} />
            </div>
            <div className='pe-none position-fixed top-0 start-0 w-100 h-100' style={{ zIndex: 90000 }}>
                {pings.map((ping) => {
                    return (<div className='position-fixed ping translate-middle' key={ping.id} style={{ left: ping.x, top: ping.y }}></div>)
                })}
            </div>
            <div className="d-flex flex-column position-absolute top-0 end-0">
                {opponentsDisplayOrder.map((p, i) => {
                    if (p != peerInstance.current.id && opponentsData[p]) {
                        return (<PlayerCounters
                            profileData={opponentsData[p].profileData}
                            counters={opponentsData[p].playerCounters}
                            setShowOpponentModal={setShowOpponentModal}
                            opponentIndex={i}
                            tokens={opponentsData[p].playerTokens}
                            tokensAvailable={tokensAvailable}
                            notes={opponentsData[p].playerNotes}
                        />)
                    }
                })}
            </div>
            <div className="position-absolute bottom-0" style={{ left: "4.1rem" }}>
                <PlayerCounters
                    profileData={playerData.profileData}
                    counters={playerData.playerCounters}
                    changeCounterValue={changeCounterValue}
                    tokens={playerData.playerTokens}
                    tokensAvailable={tokensAvailable}
                    toggleToken={toggleToken}
                    notes={playerData.playerNotes}
                    updateNotes={updateNotes}
                    setIsTextInputFocused={setIsTextInputFocused}
                />
            </div>
            {/*<div style={{width: "35vh"}} className="h-100"></div>*/}
            <ActionMode actionMode={currentActionMode} setActionMode={setCurrentActionMode} isTextInputFocused={isTextInputFocused} />
            <CardsLinks links={allCardsLinks} />
            <FocusedCard focusedCard={focusedCard} />
            <NewTurnMessage turnCount={turnCount} opponentsData={opponentsData} currentPlayer={currentPlayer} gameOptions={gameOptions} />
            <MultiplayerModal
                show={showMultiplayerPanel}
                handleClose={() => setShowMultiplayerPanel(false)}
                peerInstance={peerInstance}
                call={call}
                playerTurnOrder={playerTurnOrder}
                opponentsData={opponentsData}
                gameOptions={gameOptions}
            />
            <TurnOrderModal
                show={showTurnOrderModal}
                handleClose={() => setShowTurnOrderModal(false)}
                peerInstance={peerInstance}
                playerTurnOrder={playerTurnOrder}
                setPlayerTurnOrder={setPlayerTurnOrder}
                opponentsData={opponentsData}
                playerData={playerData}
                sendData={sendData}
                currentPlayer={currentPlayer}
            />
            <PreferenceModal
                show={showPreferencePanel}
                handleClose={() => setShowPreferencePanel(false)}
                playerData={playerData}
                setPlayerData={setPlayerData}
                options={options}
                setOptions={setOptions}
            />
            <DiceModal
                show={showDiceModal}
                handleClose={() => setShowDiceModal(false)}
                sendToHistory={sendToHistory}
            />
            <HelpModal
                show={showHelpModal}
                handleClose={() => setShowHelpModal(false)}
                gameData={gameData}
            />
            <ExitModal
                show={showExitModal}
                handleClose={() => setShowExitModal(false)}
                closeCall={closeCall}
            />
            <FormatPickerModal
                show={showFormatPickerModal}
                handleClose={() => setShowFormatPickerModal(false)}
                gameData={gameData}
                playerTurnOrder={playerTurnOrder}
                opponentsData={opponentsData}
                playerData={playerData}
                peerInstance={peerInstance}
                setGameOptions={updateGameOptions}
                gameOptions={gameOptions}
            />
        </Stack>
    )
}