import { Form } from "react-bootstrap"
import { useGameContext } from "../../Commons/GameContext"

export default function CardCounters(props) {
    const { cardSize } = useGameContext()
    let card = props.card

    if (!card.counters) { return }

    return (
        <div className={"card-counters position-absolute start-0 top-0 d-flex flex-column justify-content-center px-2 pe-none" + (props.isHorizontal ? " horizontal" : "")} style={{ zIndex: Math.abs(card.position.index), height: card.position.cardSize.height * cardSize.vh, width: card.position.cardSize.width * cardSize.vh }}>
            {card.counters.map((counter, i) => {
                if (counter.value !== undefined) {
                    return (<div className={"counter position-relative" + (i == 1 ? " secondary" : "")} key={i} >
                        <Form.Control
                            className='position-absolute top-0 start-0 w-100 h-100'
                            type="number"
                            value={counter.value}
                            readOnly={props.isOpponentCard}
                            onChange={(e) => {
                                if (props.repositionCards) {
                                    counter.value = e.target.value
                                    props.repositionCards() // FAIRE MIEUX
                                }
                            }}
                        />
                    </div>)
                }
            })}
        </div>
    )
}