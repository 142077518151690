import { useEffect, useRef, useState } from "react"
import { getArrow } from 'curved-arrows'

export default function CardsLinks(props) {
    const [links, setLinks] = useState([]);
    const linksRef = useRef([])

    useEffect(() => {
        if (props.links) {
            linksRef.current = props.links
        }
    }, [props.links])

    function updateLinks() {
        //console.time("executionTime");
        const newLinks = linksRef.current.map(link => createVisualLink(link.from, link.fromSection, link.to, link.toSection, link.id)).filter(Boolean);
        setLinks(newLinks);
        //console.timeEnd("executionTime");
    }

    useEffect(() => {
        const intervalId = setInterval(updateLinks, 20);
        return () => clearInterval(intervalId);
    }, []);

    // Fonction pour créer un lien visuel
    const createVisualLink = (id1, section1, id2, section2, linkId) => {
        const element1 = document.getElementById(id1);
        const element2 = document.getElementById(id2);

        if (element1 && element2) {
            if (!element1.classList.contains(section1) || !element2.classList.contains(section2)) {
                return null
            }
            if (element1.classList.contains("Stack") && !element1.classList.contains("reversed-index-0")) {
                return null
            }
            if (element2.classList.contains("Stack") && !element2.classList.contains("reversed-index-0")) {
                return null
            }

            const element1Card = document.getElementById(id1 + "-images");
            const element2Card = document.getElementById(id2 + "-images");

            const rect1 = element1Card.getBoundingClientRect();
            const rect2 = element2Card.getBoundingClientRect();

            const x1 = rect1.left + rect1.width / 2;
            const y1 = rect1.top + rect1.height / 2;
            const x2 = rect2.left + rect2.width / 2;
            const y2 = rect2.top + rect2.height / 2;

            return ({
                from: { x: x1, y: y1 },
                to: { x: x2, y: y2 },
                id: linkId
            })
        }

        return null;
    };


    return (<div className='position-fixed top-0 start-0 w-100 h-100 pe-none' style={{ zIndex: 1 }}>
        {links.map(link => (
            <Arrow key={link.id} data={link} />
        ))}
    </div>)

    function Arrow(props) {
        const p1 = { x: props.data.from.x, y: props.data.from.y }
        const p2 = { x: props.data.to.x, y: props.data.to.y }
        const arrowHeadSize = 9
        const color = 'white'
        const [sx, sy, c1x, c1y, c2x, c2y, ex, ey, ae] = getArrow(p1.x, p1.y, p2.x, p2.y, {
            padEnd: arrowHeadSize,
        })

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="card-link position-fixed top-0 start-0 w-100 h-100"
            >
                <path
                    d={`M ${sx} ${sy} C ${c1x} ${c1y}, ${c2x} ${c2y}, ${ex} ${ey}`}
                    stroke={color}
                    strokeWidth={arrowHeadSize / 2}
                    fill="none"
                />
                <polygon
                    points={`0,${-arrowHeadSize} ${arrowHeadSize *
                        2},0, 0,${arrowHeadSize}`}
                    transform={`translate(${ex}, ${ey}) rotate(${ae})`}
                    fill={color}
                />
            </svg>
        )
    }
}