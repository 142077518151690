import { Container } from "react-bootstrap"
import Navigation from "../Commons/Navigation/Navigation"
import "./Home.scss"
import Footer from "../Commons/Footer/Footer"
import { useEffect, useState } from "react"
import { } from "../Commons/JSONCleaning"

export default function HomePage() {
    return (
        <div className="homepage w-100">
            <Navigation />
            <HomePageContent />
        </div>
    )
}

export function HomePageContent() {
    const [justAppeared, setJustAppeared] = useState(true)
    useEffect(() => {
        // mtgCardFromMtgClean()
        setJustAppeared(false)
    }, [])

    const features = [
        {
            title: "Play",
            content: "Play with your friends for free on a Chrome or Firefox browser, no accounts needed! Up to 4 players.",
            image: "play.jpg"
        },
        {
            title: "Build",
            content: "Build decks with our deckbuilder or quickly import them from your favorite deckbuilding tool.",
            image: "deckbuilder.jpg"
        },
        {
            title: "Automatization",
            content: "The app will pay ressource cost for you and quickly place the cards you play in the right area.",
            image: "automatization.jpg"
        },
        {
            title: "Keep track",
            content: "Visual links between cards, pinging your opponent cards, ... Lots of features to help you show your opponents what you want to do.",
            image: "keep-track.jpg"
        }
    ]

    return (<>
        <div className="position-relative top-0 start-0 w-100 overflow-hidden" style={{ height: "100vh" }}>
            <div className="welcome w-100 h-100 position-relative below-navbar">
                <div className="background-color position-absolute top-0 start-0 w-100 h-100"></div>
                <div className="background position-absolute top-0 start-0 w-100 h-100"></div>
                <Container className="d-flex h-100">
                    <Container className="w-50 py-5 position-relative d-flex flex-column justify-content-center" style={{ height: "35vh" }}>
                        <h1 className="my-3">An easy to use simulator for <b>all your favorite TCGs</b></h1>
                        <h2 className="my-3">Build decks and play with up to 3 other players.</h2>
                    </Container>
                    <div className="w-50 h-100 d-flex justify-content-center align-items-center">
                        <div className={"d-flex flex-column cards w-100 h-100 align-items-center justify-content-center position-relative" + (justAppeared ? " just-appeared" : "")}>
                            <div className="z-1">
                                <div className="img-wrapper">
                                    <img src={require('./Assets/homepage_card_1.jpg')} />
                                </div>
                            </div>
                            <div className="z-2">
                                <div className="img-wrapper">
                                    <img src={require('./Assets/homepage_card_2.jpg')} />
                                </div>
                            </div>
                            <div className="z-3">
                                <div className="img-wrapper">
                                    <img src={require('./Assets/homepage_card_3.jpg')} />
                                </div>
                            </div>
                            <div className="z-2">
                                <div className="img-wrapper">
                                    <img src={require('./Assets/homepage_card_4.jpg')} />
                                </div>
                            </div>
                            <div className="z-1">
                                <div className="img-wrapper">
                                    <img src={require('./Assets/homepage_card_5.jpg')} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
        <div className="features py-5">
            <Container className="d-flex flex-column align-items-center">
                <h1 className="mb-3">Features</h1>
                {features.map((f, i) => {
                    let reverse = i % 2 == 1
                    return (<div key={i} className={"box-container w-50 p-4 text-start position-relative " + (reverse ? "flex-row-reverse" : "flex-row")}>
                        <div className="d-flex flex-column w-50">
                            <h4 className="mb-4">{f.title}</h4>
                            <p>{f.content}</p>
                        </div>
                        <div className="w-50 position-relative">
                            <img className="feature-image" style={reverse ? { translate: "-44%" } : {}} src={require('./Assets/' + f.image)} />
                        </div>
                    </div>)
                })}
            </Container>
        </div>
        <Footer></Footer>
    </>)
}