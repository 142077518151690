import { useEffect, useRef, useState } from 'react'
import './CardSearch.scss'
import { Button, ButtonGroup, Col, Container, Dropdown, FloatingLabel, Form, Modal, Pagination, Row, Spinner, ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { types } from 'sass'
import { useGameContext } from '../../Commons/GameContext'

export default function CardSearch(props) {
    const { gameCards, gameData } = useGameContext()

    // CARDS
    const cardsPerPage = 36
    const [cardsResults, setCardsResults] = useState([])
    const [displayedCards, setDisplayedCards] = useState([])
    const [currentPage, setCurrentPage] = useState({
        current: 1,
        max: 1
    })

    const DISPLAY_SIZES = {
        SMALL: 2,
        BIG: 4
    }
    const [resultsDisplaySize, setResultsDisplaySize] = useState(DISPLAY_SIZES.SMALL)

    // SEARCH
    const [searchTextInfo, setSearchTextInfo] = useState("All cards")
    const [filters, setFilters] = useState(false)
    const [showFilterModal, setShowFilterModal] = useState(false)
    const [searchInProgress, setSearchInProgress] = useState(false)

    useEffect(() => {
        let tmpFilters = {
            availableFilters: [],
            filters: {}
        }

        const addPropertyAndValue = (p, v) => {
            if (p != "name" && p != "id" && p != "image" && p !="isHorizontal" && p != "face") {
                if (!tmpFilters.availableFilters.includes(p)) {
                    tmpFilters.availableFilters.push(p)
                    tmpFilters.filters[p] = {
                        title: p,
                        type: "picker",
                        onlyOnePick: true,
                        values: [],
                        currentValue: "UNDEFINED"
                    }
                }

                if (v.constructor === Array) {
                    v.forEach((vs) => {
                        if (!tmpFilters.filters[p].values.includes(vs)) {
                            tmpFilters.filters[p].values.push(vs)
                        }
                        tmpFilters.filters[p].onlyOnePick = false
                        tmpFilters.filters[p].multiPickMode = "Exactly"
                        tmpFilters.filters[p].currentValue = []
                    })
                } else {
                    if (!tmpFilters.filters[p].values.includes(v)) {
                        tmpFilters.filters[p].values.push(v)
                    }
                }
            }
        }

        const tmpCards = Object.values(gameCards).filter(obj => !obj.hasOwnProperty('isToken'));

        tmpCards.forEach(c => {
            Object.keys(c).forEach((k) => {
                addPropertyAndValue(k, c[k])
            })
        })

        tmpFilters.availableFilters.forEach((f) => {
            let type = "picker"

            if (tmpFilters.filters[f].values.length == 2 && tmpFilters.filters[f].values[0] === !tmpFilters.filters[f].values[1]) {
                type = "boolean"
            } else {
                let areAllNumbers = true
                tmpFilters.filters[f].values.forEach((v) => {
                    if (!Number.isInteger(v)) {
                        areAllNumbers = false
                    }
                })
                if (areAllNumbers) {
                    type = "number"
                    tmpFilters.filters[f].values = []
                } else if (tmpFilters.filters[f].values.length > 10) {
                    type = "text"
                    tmpFilters.filters[f].values = []
                }
            }

            tmpFilters.filters[f].type = type
        })

        setFilters(tmpFilters)


        props.cardsListRef.current = tmpCards
        setNewCardsResults([...tmpCards])
    }, [])

    function updateSearchResults() {
        setSearchInProgress(true)
        setTimeout(() => {
            let query = document.getElementById("query").value.toLowerCase()
            let currentFilters = []
            console.log(filters.filters)
            filters.availableFilters.forEach((f, i) => {
                let filterVal = filters.filters[f].currentValue
                if (filters.filters[f].onlyOnePick && filters.filters[f].type != "picker") {
                    filterVal = filters.filters[f].currentValue.replace(/\s+/g, '');
                }
                if (filterVal !== "UNDEFINED" && filterVal.length != 0) {
                    let equality = "="
                    if (filters.filters[f].type == "number") {
                        if (filterVal.includes("<=")) {
                            equality = "<="
                            filterVal = filterVal.slice(2)
                        } else if (filterVal.includes(">=")) {
                            equality = ">="
                            filterVal = filterVal.slice(2)
                        } else if (filterVal.includes("<")) {
                            equality = "<"
                            filterVal = filterVal.slice(1)
                        } else if (filterVal.includes(">")) {
                            equality = ">"
                            filterVal = filterVal.slice(1)
                        }
                    } else if (filters.filters[f].type == "text") {
                        equality = "include"
                    }
                    currentFilters.push({
                        key: f,
                        equality: equality,
                        value: filterVal,
                        onlyOnePick: filters.filters[f].onlyOnePick,
                        multiPickMode: filters.filters[f].multiPickMode
                    })
                }
            })
            console.log(currentFilters)

            const testEquality = (val1, val2, equality) => {
                if (!val1 || !val2) { return false } // POUR LES BOOLEENS ?
                let v1 = val1.toString()
                let v2 = val2.toString()
                if (equality == "<=") {
                    return v1 <= v2
                } else if (equality == ">=") {
                    return v1 >= v2
                } else if (equality == "<") {
                    return v1 < v2
                } else if (equality == ">") {
                    return v1 > v2
                } else if (equality == "include") {
                    return (v1.toLowerCase().includes(v2.toLowerCase()))
                } else {
                    return v1 === v2
                }
            }

            let newCardsResults = []
            props.cardsListRef.current.forEach((c) => {
                let shouldAdd = false

                // Name
                if (query == "" || c.name.toLowerCase().includes(query)) {
                    shouldAdd = true
                }

                // Filters
                if (shouldAdd) {
                    currentFilters.every((f) => {
                        if (f.onlyOnePick) {
                            if (testEquality(c[f.key], f.value, f.equality)) { shouldAdd = true }
                            else { shouldAdd = false; return false }
                            return true
                        } else {
                            if (f.multiPickMode == "Including") {
                                shouldAdd = false
                                f.value.forEach((v) => {
                                    if (c[f.key].includes(v)) {
                                        shouldAdd = true
                                        return true
                                    }
                                })
                                return false
                            } else if (f.multiPickMode == "At-Most") {
                                shouldAdd = true
                                c[f.key].forEach((v) => {
                                    if (!f.value.includes(v)) {
                                        shouldAdd = false
                                        return false
                                    }
                                })
                                return true
                            } else {
                                shouldAdd = true
                                if (c[f.key].length != f.value.length) {
                                    shouldAdd = false
                                    return false
                                }
                                f.value.forEach((v) => {
                                    if (!c[f.key].includes(v)) {
                                        shouldAdd = false
                                        return false
                                    }
                                })
                            }
                        }
                    })
                }

                if (shouldAdd) {
                    newCardsResults.push(c)
                }
            })

            let text = "All cards"
            if (query != "") {
                text = "Cards with " + query + " in their name"
            }
            currentFilters.forEach((f, i) => {
                if (i == 0) { text += (query != "" ? " and " : " ") + "with " }
                else { text += ", " }
                text += f.key + f.equality + f.value
            })

            setNewCardsResults(newCardsResults)
            setSearchTextInfo(text)
            setSearchInProgress(false)
        }, 10)
    }

    function setNewCardsResults(newVal) {
        setCardsResults(newVal)
        setDisplayedCards(newVal.slice(0, cardsPerPage))
        setCurrentPage({
            current: 1,
            max: Math.ceil(newVal.length / cardsPerPage)
        })
    }

    return (
        <Container className="card-search position-relative">
            <Container>
                <Container className="p-0 mb-2">
                    <Row>
                        <Col className='position-relative d-flex'>
                            <div className='position-relative w-100'>
                                <Form.Control
                                    type="text"
                                    id="query"
                                    placeholder="Card name"
                                    aria-describedby="cardSearchQuery"
                                    onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                            e.preventDefault();
                                            updateSearchResults()
                                        }
                                    }}
                                />
                                <img style={{ height: "1rem" }} className="position-absolute end-0 top-50 me-1 translate-middle" src={require('../../Commons/Assets/Icons/search.png')} />
                            </div>
                            <Button className='ms-3' onClick={() => {
                                updateSearchResults()
                            }}>Search</Button>
                        </Col>
                    </Row>
                </Container>
                <Container className="p-0 my-2 d-flex">
                    <Row className='w-100'>
                        {filters && gameData.deckBuilding.mainFilters.map((af, i) => {
                            if (filters.filters[af]) {
                                return (
                                    <Col key={af}>
                                        <SearchFilter filter={af} fil={filters} setFilters={setFilters} updateSearchResults={updateSearchResults} />
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                    <Button className='ms-3' onClick={() => setShowFilterModal(true)}>
                        <img src={require('../../Commons/Assets/Icons/filter.png')} />
                    </Button>
                </Container>
            </Container>
            <Container>
                <div className="d-flex flex-row justify-content-between align-items-center my-2">
                    <p className="my-0">{searchTextInfo}</p>
                    <div className="d-flex align-items-center">
                        <p className="my-0 mx-3">Results {currentPage.current * cardsPerPage - cardsPerPage + 1} to {Math.min(currentPage.current * cardsPerPage, cardsResults.length)} of {cardsResults.length}</p>
                        <ToggleButtonGroup type="radio" name="display-size"
                            defaultValue={DISPLAY_SIZES.SMALL}
                            onChange={(val) => { setResultsDisplaySize(val); props.setFocusedCard(false) }}
                        >
                            <ToggleButton
                                id="tbg-btn-1"
                                value={DISPLAY_SIZES.SMALL}
                            >
                                <img src={require('../../Commons/Assets/Icons/grid_small.png')} />
                            </ToggleButton>
                            <ToggleButton
                                id="tbg-btn-2"
                                value={DISPLAY_SIZES.BIG}
                            >
                                <img src={require('../../Commons/Assets/Icons/grid_big.png')} />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>
                <Row className={"card-size-" + resultsDisplaySize}>
                    {searchInProgress && (<div className="d-flex align-items-center justify-content-center" style={{ height: "50vh" }}><Spinner animation="border" /></div>)}
                    {!searchInProgress && displayedCards.map((c, i) => {
                        return (<Col key={c.id} xs={resultsDisplaySize} className="p-0" id={c.id}>
                            <CardSearchResultCard
                                card={c}
                                addCard={props.addCardToDeckList}
                                setFocusedCard={(c) => {
                                    if (resultsDisplaySize === DISPLAY_SIZES.SMALL) {
                                        props.setFocusedCard(c)
                                    }
                                }}
                            />
                        </Col>)
                    })}
                </Row>
                {!searchInProgress && (<ResultsPagination />)}
                <FilterModal
                    show={showFilterModal}
                    handleClose={() => setShowFilterModal(false)}
                    filters={filters}
                    setFilters={setFilters}
                    updateSearchResults={updateSearchResults}
                />
            </Container>
        </Container >
    )

    function ResultsPagination() {
        function changePage(value) {
            if (value > 0 && value <= currentPage.max) {
                let tmp = { ...currentPage }
                tmp.current = value
                setCurrentPage(tmp)

                setDisplayedCards(cardsResults.slice((value - 1) * cardsPerPage, value * cardsPerPage))
            }
        }

        function PaginationItem(props) {
            return (<Pagination.Item onClick={() => {
                changePage(props.value)
            }}>{props.value}</Pagination.Item>)
        }

        return (
            <Pagination className='d-flex justify-content-center my-3'>
                {currentPage.current > 1 && (<Pagination.First onClick={() => {
                    changePage(1)
                }} />)}

                {currentPage.current > 1 && (<Pagination.Prev onClick={() => {
                    changePage(currentPage.current - 1)
                }} />)}

                {currentPage.current > 4 && (<PaginationItem value={1} />)}
                {currentPage.current > 5 && (<Pagination.Ellipsis disabled />)}

                {currentPage.current - 3 > 0 && (<PaginationItem value={currentPage.current - 3} />)}
                {currentPage.current - 2 > 0 && (<PaginationItem value={currentPage.current - 2} />)}
                {currentPage.current - 1 > 0 && (<PaginationItem value={currentPage.current - 1} />)}

                <Pagination.Item active>{currentPage.current}</Pagination.Item>

                {currentPage.current + 1 <= currentPage.max && (<PaginationItem value={currentPage.current + 1} />)}
                {currentPage.current + 2 <= currentPage.max && (<PaginationItem value={currentPage.current + 2} />)}
                {currentPage.current + 3 <= currentPage.max && (<PaginationItem value={currentPage.current + 3} />)}

                {currentPage.current <= currentPage.max - 5 && (<Pagination.Ellipsis disabled />)}
                {currentPage.current <= currentPage.max - 4 && (<PaginationItem value={currentPage.max} />)}


                {currentPage.current < currentPage.max && (<Pagination.Next onClick={() => {
                    changePage(currentPage.current + 1)
                }} />)}

                {currentPage.current < currentPage.max && (<Pagination.Last onClick={() => {
                    changePage(currentPage.max)
                }} />)}
            </Pagination>
        );
    }
}

function SearchFilter(props) {
    let value = props.fil.filters[props.filter].currentValue == "UNDEFINED" ? null : props.fil.filters[props.filter].currentValue
    if (props.fil.filters[props.filter].type == "picker") {
        if (props.fil.filters[props.filter].onlyOnePick) {
            return (
                <FloatingLabel controlId={"floatingSelect-" + props.fil.filters[props.filter].title} label={props.fil.filters[props.filter].title}>
                    <Form.Select aria-label="Default select"
                        onChange={(e) => {
                            let tmp = { ...props.fil }
                            props.fil.filters[props.filter].currentValue = e.currentTarget.value
                            props.setFilters(tmp)
                            props.updateSearchResults()
                        }}
                        value={value}
                    >
                        <option value={"UNDEFINED"}>{"None"}</option>
                        {props.fil.filters[props.filter].values.map((v, j) => {
                            if (v === true) {
                                return (<option key={v} value={v}>{"Yes"}</option>)
                            } else if (v === false) {
                                return (<option key={v} value={v}>{"No"}</option>)
                            }
                            return (<option key={v} value={v}>{v}</option>)
                        })}
                    </Form.Select>
                </FloatingLabel>
            )
        } else {
            return (
                <div className='multi-filter'>
                    <FloatingLabel className="w-50" controlId={"floatingSelect-" + props.fil.filters[props.filter].title} label={props.fil.filters[props.filter].title}>
                        <Form.Select aria-label="Default select"
                            onChange={(e) => {
                                props.fil.filters[props.filter].multiPickMode = e.currentTarget.value
                                props.setFilters(props.fil)
                                console.log(props.fil)
                                props.updateSearchResults()
                            }}
                            value={props.fil.filters[props.filter].multiPickMode}
                        >
                            <option value="Exactly">Exactly</option>
                            <option value="Including">Including</option>
                            <option value="At-Most">At most</option>
                        </Form.Select>
                    </FloatingLabel>
                    <Form.Select aria-label="Default select"
                        className='w-50'
                        onChange={(e) => {
                            let val = e.currentTarget.value
                            let index = props.fil.filters[props.filter].currentValue.indexOf(val)
                            if (index === -1) {
                                props.fil.filters[props.filter].currentValue.push(val)
                            } else {
                                props.fil.filters[props.filter].currentValue.splice(index, 1)
                            }
                            props.setFilters(props.fil)
                            props.updateSearchResults()
                        }}
                        value="placeholder"
                    >
                        <option value="placeholder" selected disabled>{props.fil.filters[props.filter].currentValue.join(', ')}</option>
                        {props.fil.filters[props.filter].values.map((v, j) => {
                            return (<option key={v} value={v}>{props.fil.filters[props.filter].currentValue.includes(v) ? v + " - Selected" : v}</option>)
                        })}
                    </Form.Select>
                </div>
            )
        }
    }

    // Pareil pour le moment
    if (props.fil.filters[props.filter].type == "boolean") {
        return (
            <FloatingLabel controlId={"floatingSelect-" + props.fil.filters[props.filter].title} label={props.fil.filters[props.filter].title}>
                <Form.Select aria-label="Default select"
                    onChange={(e) => {
                        let tmp = { ...props.fil }
                        props.fil.filters[props.filter].currentValue = e.currentTarget.value
                        props.setFilters(tmp)
                        props.updateSearchResults()
                    }}
                    value={value}
                >
                    <option value={"UNDEFINED"}>{"None"}</option>
                    {props.fil.filters[props.filter].values.map((v, j) => {
                        if (v === true) {
                            return (<option key={v} value={v}>{"Yes"}</option>)
                        } else if (v === false) {
                            return (<option key={v} value={v}>{"No"}</option>)
                        }
                        return (<option key={v} value={v}>{v}</option>)
                    })}
                </Form.Select>
            </FloatingLabel>
        )
    }

    if (props.fil.filters[props.filter].type == "number") {
        return (
            <FloatingLabel controlId={"floatingSelect-" + props.fil.filters[props.filter].title} label={props.fil.filters[props.filter].title}>
                <Form.Control aria-label="Default select"
                    type="text"
                    placeholder={props.fil.filters[props.filter].title}
                    onChange={(e) => {
                        let tmp = { ...props.fil }
                        props.fil.filters[props.filter].currentValue = e.currentTarget.value
                        if (e.currentTarget.value == "") {
                            props.fil.filters[props.filter].currentValue = "UNDEFINED"
                        }
                        props.setFilters(tmp)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            props.updateSearchResults()
                        }
                    }}
                    value={value}
                >
                </Form.Control>
            </FloatingLabel>
        )
    }

    if (props.fil.filters[props.filter].type == "text") {
        return (
            <FloatingLabel controlId={"floatingSelect-" + props.fil.filters[props.filter].title} label={props.fil.filters[props.filter].title}>
                <Form.Control aria-label="Default select"
                    type="text"
                    placeholder={props.fil.filters[props.filter].title}
                    onChange={(e) => {
                        let tmp = { ...props.fil }
                        props.fil.filters[props.filter].currentValue = e.currentTarget.value
                        if (e.currentTarget.value == "") {
                            props.fil.filters[props.filter].currentValue = "UNDEFINED"
                        }
                        props.setFilters(tmp)
                    }}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            props.updateSearchResults()
                        }
                    }}
                    value={value}
                >
                </Form.Control>
            </FloatingLabel>
        )
    }
}

function CardSearchResultCard(props) {
    const [cardClicked, setCardClicked] = useState(false)

    const triggerClickAnimation = () => {
        setCardClicked(false)
        setTimeout(() => {
            setCardClicked(true)
        }, 10)
    }

    return (
        <div className={"card-search-result-card position-relative" + (cardClicked ? " clicked" : "")}
            onAnimationEnd={() => setCardClicked(false)}
            onClick={() => { props.addCard(props.card); triggerClickAnimation() }}
            onPointerEnter={() => props.setFocusedCard(props.card)}
            onPointerLeave={() => props.setFocusedCard(false)}
        >
            <div className="user-select-none card-face position-aboslute top-0 start-0 w-100">
                <div className="position-absolute top-0 start-0 w-100 h-100">
                    <h1 className="position-absolute top-50 start-50 translate-middle">+</h1>
                </div>
                <img className="h-100 w-100" src={props.card.face.front.image} />
            </div>
        </div>
    )
}

function FilterModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.filters && props.filters.availableFilters.map((af, i) => {
                    return (
                        <Col key={af} className='my-2'>
                            <SearchFilter filter={af} fil={props.filters} setFilters={props.setFilters} updateSearchResults={props.updateSearchResults} />
                        </Col>
                    )
                })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    props.handleClose()
                }}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}