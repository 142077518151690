import { Container } from 'react-bootstrap'
import './Decks.scss'
import Footer from '../../Commons/Footer/Footer'
import { useGameContext } from '../../Commons/GameContext'

export default function DeckTopSection(props) {
    let { gameData } = useGameContext()
    return (
        <div className="top-section position-relative w-100">
            <div className="position-absolute top-0 start-0 w-100 h-100 background-image"
                style={{
                    backgroundImage: "url(" + gameData.menuBackgroundImage + ")"
                }}
            ></div>
            <div className="position-absolute top-0 start-0 w-100 h-100 background-color"></div>
            <div className="position-absolute top-0 start-0 w-100 h-100 background"></div>
            <div className='d-flex flex-column h-100 w-100'>
                <Container className="position-relative d-flex flex-column justify-content-end h-100">
                    {props.children}
                </Container>
                <hr></hr>
            </div>
        </div>
    )
}