import { createRef, useEffect, useRef, useState } from 'react'
import BoardSection from '../Board/BoardSection'
import Hand from '../Hand/Hand'
import './OpponentSection.scss'
import Card from '../Card/Card'
import { SECTIONS, defaultSections } from '../PlayerSection/PlayerSection'
import Deck from '../Deck/Deck'
import Discard from '../Discard/Discard'
import { ACTION_MODES } from '../ActionMode'
import { v4 as uuidv4 } from 'uuid';
import { useGameContext } from '../../Commons/GameContext'
import LayoutBoardSection from '../Board/LayoutBoardSection'
import { Button } from 'react-bootstrap'

export default function OpponentSection(props) {
    const [deck, setDeck] = useState([])
    const [visibleCards, setVisibleCards] = useState([])
    const visibleCardsRef = useRef([])

    const hasBeenInitializedRef = useRef(false) // TMP

    const cardDraggedRef = useRef(false)
    const handRef = useRef(null)
    const deckRef = useRef(null)
    const discardRef = useRef(null)
    const opponentSectionRef = useRef(null)

    const [sections, setSections] = useState(false)
    const sectionsCardsRef = useRef({})
    const { cardSize, gameData } = useGameContext()
    const [isZoomed, setIsZoomed] = useState(false)

    useEffect(() => {
        // update ref bounding rect
        if (!sections) { return }
        const currentTransform = opponentSectionRef.current.style.transform;
        opponentSectionRef.current.style.transform = 'none'
        let newSections = { ...sections }
        newSections.allSections.forEach((s, i) => {
            newSections.sectionsDict[s].sectionBoundingClientRect = newSections.sectionsDict[s].sectionRef.current.getBoundingClientRect()
        })
        setSections(newSections)
        opponentSectionRef.current.style.transform = currentTransform

        repositionCards()
    }, [cardSize, sections, isZoomed])

    useEffect(() => {
        if (props.gameOptions) {
            const sectionsFile = props.gameOptions.format.sections

            // SETUP SECTIONS
            let newSections = {
                allSections: [...sectionsFile.customSections, ...defaultSections.sections],
                customSections: [...sectionsFile.customSections],
                layout: { ...sectionsFile.layout },
                autoPlayFromHand: sectionsFile.autoPlayFromHand,
                autoPlayFromStack: sectionsFile.autoPlayFromStack,
                sectionsDict: {},
            }
            sectionsFile.sectionsDict = { ...defaultSections.sectionsDict, ...sectionsFile.sectionsDict }
            newSections.allSections.forEach((key) => {
                let section = sectionsFile.sectionsDict[key]
                let ref = null

                if (section.isDefaultSection) {
                    if (key == SECTIONS.HAND) {
                        ref = handRef
                    } else if (key == SECTIONS.DISCARD || key == SECTIONS.EXILE || key == SECTIONS.EXILE_HIDDEN) {
                        ref = discardRef
                    } else if (key == SECTIONS.DECK) {
                        ref = deckRef
                    } else if (key == SECTIONS.STACK) {
                        ref = props.stackRef
                    }
                } else {
                    ref = createRef()
                }

                newSections.sectionsDict[key] = {
                    title: section.title,
                    isHidden: section.isHidden,
                    sectionRef: ref,
                    sectionBoundingClientRect: false,
                    height: SECTIONS.SECTION_SIZES[section.height],
                    alignment: section.opponentAlignment ? SECTIONS.ALIGNMENTS[section.opponentAlignment] : SECTIONS.ALIGNMENTS[section.alignment],
                    cardHeight: SECTIONS.SECTION_SIZES[section.height],
                    cardWidth: SECTIONS.SECTION_SIZES[section.height] * 0.7,
                    autoPayFrom: section.autoPayFrom,
                    noAutoPayTo: section.noAutoPayTo,
                    quickActionButtons: section.quickActionButtons,
                    isHorizontalAllowed: section.isHorizontalAllowed,
                    isGroupForbidden: section.isGroupForbidden,
                    keepTappedNewTurn: section.keepTappedNewTurn
                }
            })

            setSections(newSections)
        }
    }, [props.gameOptions])

    useEffect(() => {
        let newCards = props.opponentData.visibleCards
        visibleCardsRef.current = newCards
        setDeck([...props.opponentData.deck])
        repositionCards()
    }, [props.opponentData])

    // Reposition all cards in visibleCards
    function repositionCards() {
        if (!sections) { return }

        sectionsCardsRef.current = {}
        sections.allSections.forEach((s) => {
            sectionsCardsRef.current[s] = []
        })

        let newVisibleCards = [...visibleCardsRef.current]
        newVisibleCards.forEach((c, i) => {
            if (c.position.section && sectionsCardsRef.current[c.position.section]) {
                sectionsCardsRef.current[c.position.section].push({ card: c, index: i })
            }
        })

        function setPositions(array, sectionRect, alignment) {
            if (!sectionRect) { return }
            let rect = sectionRect

            let n = 0
            array.forEach((data, i) => {
                if (data.card.position.index === undefined) {
                    array[i].card.position.index = 99999
                }
            })

            let totalWidth = 0
            array.forEach((c) => {
                if (!c.card.isTriggerEffect) {
                    if (c.card.isHorizontal && c.card.isTapped) {
                        totalWidth += c.card.position.cardSize.height
                    } else {
                        totalWidth += c.card.position.cardSize.width
                    }
                    if (c.card.grouppedCards) {
                        totalWidth += c.card.grouppedCards.length * (c.card.position.cardSize.width / 2)
                    }
                }
            })
            totalWidth = totalWidth * cardSize.vh
            // Alignment
            let alignmentOffset = 0
            if (alignment == SECTIONS.ALIGNMENTS.CENTER) {
                alignmentOffset = rect.width / 2 - Math.min(totalWidth, rect.width) / 2
            } else if (alignment == SECTIONS.ALIGNMENTS.END) {
                alignmentOffset = rect.width
            }

            // Too many cards
            let shrinkOffset = 0
            if (totalWidth > rect.width) {
                let tooMuch = totalWidth - rect.width
                shrinkOffset = tooMuch / (array.length - 1)
            }

            array.sort((a, b) => a.card.position.index - b.card.position.index);
            let cumulatedWidth = 0
            array.forEach((data, i) => {
                const card = newVisibleCards[data.index]

                const grouppedChildrenSpacing = (card.position.cardSize.width / 2) * cardSize.vh
                let childTotalWidth = 0
                if (card.grouppedCards) {
                    childTotalWidth = grouppedChildrenSpacing * card.grouppedCards.length
                }

                card.position.index = i
                card.position.reversedIndex = array.length - i - 1

                if (alignment == SECTIONS.ALIGNMENTS.NONE) {
                    card.position.pos = { left: rect.left, top: rect.top }
                } else if (alignment == SECTIONS.ALIGNMENTS.END) {
                    card.position.pos = { left: rect.left + alignmentOffset - cumulatedWidth + (i > 0 ? shrinkOffset * i : 0) - childTotalWidth - card.position.cardSize.width * cardSize.vh, top: rect.top }
                } else {
                    card.position.pos = { left: rect.left + cumulatedWidth + alignmentOffset - (i > 0 ? shrinkOffset * i : 0) + childTotalWidth, top: rect.top }
                }
                let cWidth = card.position.cardSize.width * cardSize.vh
                if (card.isHorizontal && card.isTapped) {
                    cWidth = card.position.cardSize.height * cardSize.vh
                }

                // Groupped cards
                if (card.grouppedCards) {
                    let cPos = { ...card.position.pos }
                    card.grouppedCards.forEach((child, j) => {
                        let childrenIndex = newVisibleCards.findIndex(cv => cv.id == child.id)
                        if (childrenIndex != -1) {
                            let c = newVisibleCards[childrenIndex]
                            if (alignment == SECTIONS.ALIGNMENTS.END) {
                                c.position.pos = {
                                    left: cPos.left + grouppedChildrenSpacing * (j + 1),
                                    top: cPos.top + card.position.cardSize.height * cardSize.vh * 0.1
                                }
                            } else {
                                c.position.pos = {
                                    left: cPos.left - grouppedChildrenSpacing * (j + 1),
                                    top: cPos.top + card.position.cardSize.height * cardSize.vh * 0.1
                                }
                            }
                            c.position.index = i - 10 - j
                        }
                    })
                }

                if (!card.isTriggerEffect) {
                    cWidth += childTotalWidth
                    cumulatedWidth += cWidth
                }
            })
        }

        sections.allSections.forEach((s) => {
            if (s != SECTIONS.STACK) {
                setPositions(sectionsCardsRef.current[s], sections.sectionsDict[s].sectionBoundingClientRect, sections.sectionsDict[s].alignment)
            }
        })

        setSections(sections)
        setVisibleCards(newVisibleCards)
        visibleCards.current = newVisibleCards
    }

    function moveCard() {
        console.log("fuck moving card")
    }

    if (!sections) {
        return (
            <div className={"opponent-section d-flex flex-column w-100 h-100 position-absolute top-0 start-0 pe-none unzoomed" + (props.isCurrentPlayer ? " current-player" : "")}>
                {props.profileData && (<div id={"playmat-" + props.opponentId} className="background position-absolute start-0 top-0 w-100 h-100" style={{ backgroundImage: "url(" + props.profileData.background + ")" }}></div>)}
            </div>
        )
    }

    return (
        <div className={"opponent-section d-flex flex-column w-100 h-100 position-absolute top-0 start-0 pe-none" + (props.isCurrentPlayer ? " current-player" : "") + (isZoomed ? " zoomed" : " unzoomed")}>
            {props.profileData && (<div id={"playmat-" + props.opponentId} className="background position-absolute start-0 top-0 w-100 h-100" style={{ backgroundImage: "url(" + props.profileData.background + ")" }}></div>)}
            <div className='pe-auto d-flex flex-column position-relative w-100 h-100' ref={opponentSectionRef}>
                <div className="position-relative d-flex flex-column w-100 h-100 justify-content-after">
                    <div style={{ height: '38vh' }}>
                        <LayoutBoardSection
                            sectionLayout={sections.layout}
                            cardDraggedRef={cardDraggedRef}
                            moveCard={moveCard}
                            sections={sections}
                            sectionsCardsRef={sectionsCardsRef}
                            repositionCards={repositionCards}
                            isOpponentSection={true}
                        />
                    </div>
                    <Hand cardDraggedRef={cardDraggedRef} moveCard={moveCard} handRef={handRef} repositionCards={repositionCards} />
                    <Deck
                        deck={deck}
                        deckRef={deckRef}
                        draw={() => console.log("fuck draw")}
                        repositionCards={repositionCards}
                        playerId={props.playerId}
                        topCardHiddenTo={props.opponentData.deckTopCardHiddenTo}
                        setFocusedCard={props.setFocusedCard}
                    />
                    <Discard cardDraggedRef={cardDraggedRef} discardRef={discardRef} moveCard={moveCard} repositionCards={repositionCards} sectionCards={sectionsCardsRef.current[SECTIONS.DISCARD]} />
                </div>
                <div className='visible-cards position-fixed start-0 top-0 w-100 h-100 pe-none'>
                    {visibleCards.map((c, i) => {
                        return (<Card
                            key={c.id}
                            card={c}
                            cardDraggedRef={cardDraggedRef}
                            clickCard={() => {
                                if (props.currentActionMode.action === ACTION_MODES.PING) {
                                    props.pingCard(c)
                                } else if (props.currentActionMode.action == ACTION_MODES.LINK_FROM || props.currentActionMode.action == ACTION_MODES.LINK_TO) {
                                    if (!props.currentActionMode.actionData) {
                                        props.setCurrentActionMode({
                                            action: ACTION_MODES.LINK_TO,
                                            actionData: {
                                                id: uuidv4(),
                                                fromCardName: c.cardData.name,
                                                fromSection: c.position.section,
                                                from: c.id,
                                                to: false,
                                                toSection: false
                                            }
                                        })
                                    } else {
                                        let linkData = props.currentActionMode.actionData
                                        linkData.to = c.id
                                        linkData.toSection = c.position.section
                                        let tmp = [...props.cardsLinks]
                                        tmp.push(linkData)
                                        props.setCardsLinks(tmp)
                                        if (linkData.isFromQuickAction) {
                                            props.setCurrentActionMode({
                                                action: ACTION_MODES.NONE,
                                                actionData: false
                                            })
                                        } else {
                                            props.setCurrentActionMode({
                                                action: ACTION_MODES.LINK_FROM,
                                                actionData: false
                                            })
                                        }
                                    }
                                } else if (props.currentActionMode.action == ACTION_MODES.NONE) {
                                    // Default action
                                    props.pingCard(c)
                                }
                            }}
                            deck={deck}
                            cards={visibleCards}
                            index={i}
                            repositionCards={repositionCards}
                            setCurrentActionMode={() => console.log("fuck")}
                            setFocusedCard={props.setFocusedCard}
                            isOpponentCard={true}
                            interactionFobidden={true}
                            currentActionMode={props.currentActionMode}
                            playerId={props.playerId}
                        />)
                    })}
                </div>
            </div>
            {/*<Button className='position-absolute bottom-0 start-50 pe-auto translate-x-middle'
                style={{ zIndex: "50000", scale: 2 }}
                onClick={() => {
                    setIsZoomed(!isZoomed)
                }}
            >{isZoomed ? "Unzoom" : "Zoom"}</Button>*/}
        </div>
    )
}