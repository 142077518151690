import { Button, Modal } from "react-bootstrap"
import { PreferenceSection } from "../../Home/Preference"

export default function PreferenceModal(props) {
    return (
        <Modal className="fullscreen-modal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Preference</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <PreferenceSection
                    profileData={props.playerData.profileData}
                    setProfileData={(v) => {
                        let tmp = { ...props.playerData }
                        tmp.profileData = v
                        props.setPlayerData(tmp)
                    }} 
                    options={props.options}
                    setOptions={props.setOptions}
                    />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}