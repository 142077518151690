import { Button, Modal } from "react-bootstrap"
import { useNavigate } from "react-router"

export default function ExitModal(props) {
    const navigate = useNavigate()
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Exit</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Are you sure you want to return to the main menu ? Game progress will be lost.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="danger" onClick={() => {
                    navigate("/")
                    props.closeCall()
                    props.handleClose()
                }}>
                    Exit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}