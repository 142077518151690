import { Button, Dropdown, DropdownButton, Form, Modal } from 'react-bootstrap'
import { shuffleArray } from '../../Commons/Functions'
import { SECTIONS } from '../PlayerSection/PlayerSection'
import { v4 as uuidv4 } from 'uuid';
import { ACTION_MODES } from '../ActionMode';
import { use, useState } from 'react';

export default function CardQuickActions(props) {
    const [showNotesModal, setShowNotesModal] = useState(false)

    function ungroupCard(c) {
        if (c.grouppedCards) {
            c.grouppedCards.forEach((child, j) => {
                if (child.position) {
                    delete child.grouppedToId
                    child.position.section = c.position.section
                    child.position.index = 99999
                }
            })
            delete c.grouppedCards
        }

        if (c.grouppedToId !== undefined) {
            // Remove from parent
            let index = props.cards.findIndex((a) => a.id === c.grouppedToId)
            let parent = index >= 0 ? props.cards[index] : false
            if (parent) {
                if (parent.grouppedCards) {
                    let newGrouppedCards = [...parent.grouppedCards]
                    const index = newGrouppedCards.findIndex((c) => c.id === c.id)
                    if (index !== -1) {
                        newGrouppedCards.splice(index, 1)
                    }
                    if (newGrouppedCards.length === 0) {
                        delete parent.grouppedCards
                    } else {
                        parent.grouppedCards = newGrouppedCards
                    }
                }
                //cardSection = parent.position.section // In case of children, use the parent section value
            }
            delete c.grouppedToId
        }
    }

    function sendToDeckTop() {
        let c = { ...props.cards.splice(props.index, 1)[0] }
        ungroupCard(c)
        props.deck.push(c)
        props.repositionCards()
        props.sendToHistory("put at the top of this deck", props.card)
    }

    function sendToDeckBottom() {
        let c = { ...props.cards.splice(props.index, 1)[0] }
        ungroupCard(c)
        props.deck.unshift(c)
        props.repositionCards()
        props.sendToHistory("put at the bottom of this deck", props.card)
    }

    function shuffleIntoDeck() {
        let c = { ...props.cards.splice(props.index, 1)[0] }
        ungroupCard(c)
        props.deck.push(c)
        shuffleArray(props.deck)
        props.repositionCards()
        props.sendToHistory("shuffled into his deck", props.card)
    }

    function flip() {
        const c = props.cards[props.index]
        const isFlipped = !c.isFlipped

        c.isFlipped = isFlipped
        props.cards[props.index].isHorizontal = isFlipped ? c.cardData.face.back.isHorizontal : c.cardData.face.front.isHorizontal

        props.moveCard(c, c.position.section)
        props.repositionCards()
    }

    function hide(val) {
        if (val == "yes") {
            [...props.opponentIds, props.playerId].forEach(id => {
                props.card.hiddenTo[id] = true
            })
            props.sendToHistory("hid a card")
        } else if (val == "opponent-only") {
            props.card.hiddenTo = {}
            props.opponentIds.forEach(id => {
                props.card.hiddenTo[id] = true
            })
            props.sendToHistory("hid a card from other players")
        } else if (val == "no") {
            props.card.hiddenTo = {}
            props.sendToHistory("revealed", props.card)
        } else {
            if (props.card.hiddenTo[val]) {
                delete props.card.hiddenTo[val]
                props.sendToHistory("hid a card from one player", props.card)
            } else {
                props.card.hiddenTo[val] = true
                props.sendToHistory("revealed a card to one player", props.card)
            }
        }
        props.moveCard(props.card, props.card.position.section)
        props.repositionCards()
    }

    function group() {
        props.setCurrentActionMode({
            action: ACTION_MODES.GROUP,
            actionData: {
                cardToGroupIndex: props.index,
                cardName: props.cards[props.index].cardData.name
            }
        })
    }

    function link() {
        const card = props.cards[props.index]
        props.setCurrentActionMode({
            action: ACTION_MODES.LINK_TO,
            actionData: {
                id: uuidv4(),
                fromCardName: card.cardData.name,
                fromSection: card.position.section,
                from: card.id,
                to: false,
                toSection: false,
                isFromQuickAction: true
            }
        })
    }

    function toggleCounter(index) {
        if (props.card.counters[index].value !== undefined) {
            props.card.counters[index] = {}
        } else {
            props.card.counters[index] = {
                value: 0
            }
        }
        console.log(props.card.counters)
        props.repositionCards()
    }

    function exile(exileSection) {
        let c = props.cards[props.index]
        props.moveCard(c, exileSection)
        props.repositionCards()
    }

    function duplicate() {
        let c = JSON.parse(JSON.stringify(props.cards[props.index]))
        c.id = uuidv4()
        if (c.grouppedToId) {
            let index = props.cards.findIndex((a) => a.id === c.grouppedToId)
            if (index >= 0) {
                c.position.section = props.cards[index].position.section
                c.position.index = 9999
            }
        }
        delete c.grouppedToId
        delete c.grouppedCards
        props.cards.push(c)
        props.repositionCards()
    }

    return (
        <div className='wrapper card-images-and-wrapper w-100 h-100 position-absolute top-0 start-0 pe-none'>
            {(props.card.position.section != SECTIONS.STACK || props.card.position.reversedIndex == 0) && (<Button className="link-button position-absolute start-0" onClick={() => link()}><img src={require('../Assets/cards-links.png')} /></Button>)}
            <DropdownButton onClick={props.onClick} className="position-absolute top-0 start-0 quick-actions" title="" drop={props.card.position.section == SECTIONS.STACK ? "start" : "auto"}>
                {!props.card.isToken && (<DropdownButton drop={props.card.position.section == SECTIONS.STACK ? "start" : "end"} title="To deck" className="submenu w-100">
                    <Dropdown.Item href="" onClick={() => sendToDeckTop()}>Top</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => sendToDeckBottom()}>Bottom</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => shuffleIntoDeck()}>Shuffle</Dropdown.Item>
                </DropdownButton>)}
                <Dropdown.Item href="" onClick={() => exile(SECTIONS.EXILE)}>Remove</Dropdown.Item>
                <Dropdown.Item href="" onClick={() => exile(SECTIONS.EXILE_HIDDEN)}>Remove hidden</Dropdown.Item>
                <Dropdown.Divider />
                {props.cards[props.index].cardData.face.back && (<Dropdown.Item href="" onClick={() => flip()}>Flip</Dropdown.Item>)}
                <DropdownButton drop={props.card.position.section == SECTIONS.STACK ? "start" : "end"} title="Visible by" className="submenu w-100">
                    <Dropdown.Item href="" onClick={() => hide("no")}>All</Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => hide("yes")}>No one </Dropdown.Item>
                    <Dropdown.Item href="" onClick={() => hide("opponent-only")}>Only you</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className={!props.card.hiddenTo[props.playerId] ? "selected" : ""} href="" onClick={() => hide(props.playerId)}>You</Dropdown.Item>
                    {props.opponentIds.map((o, i) => {
                        if (props.opponentsData && props.opponentsData[o]) {
                            return (<Dropdown.Item className={!props.card.hiddenTo[o] ? "selected" : ""} key={o} onClick={() => hide(o)}>{props.opponentsData[o].profileData.name}</Dropdown.Item>)
                        }
                    })}
                </DropdownButton>
                {!props.card.grouppedCards && (<Dropdown.Item href="" onClick={() => group()}>Group</Dropdown.Item>)}
                <Dropdown.Item href="" onClick={() => duplicate()}>Duplicate</Dropdown.Item>
                <Dropdown.Divider />
                {props.card.counters && (<Dropdown.Item className={props.card.counters[0].value === undefined ? "" : " selected"} onClick={() => toggleCounter(0)}>Toggle counter 1</Dropdown.Item>)}
                {props.card.counters && (<Dropdown.Item className={props.card.counters[1].value === undefined ? "" : " selected"} onClick={() => toggleCounter(1)}>Toggle counter 2</Dropdown.Item>)}
                <Dropdown.Item href="" onClick={() => setShowNotesModal(true)}>Edit notes</Dropdown.Item>
                <Dropdown.Divider />
                <DropdownButton drop={props.card.position.section == SECTIONS.STACK ? "start" : "end"} title="Give to player" className="submenu w-100">
                    {props.opponentIds.map((o, i) => {
                        if (props.opponentsData && props.opponentsData[o]) {
                            return (<Dropdown.Item key={o} onClick={() => {
                                ungroupCard(props.card)
                                props.giveCardAtIndexTo(props.index, o)
                            }}>{props.opponentsData[o].profileData.name}</Dropdown.Item>)
                        }
                    })}
                </DropdownButton>
            </DropdownButton>
            <EditCardNotesModal
                card={props.card}
                show={showNotesModal}
                handleClose={() => setShowNotesModal(false)}
                repositionCards={() => props.repositionCards()}
                setIsTextInputFocused={props.setIsTextInputFocused}
            />
        </div>
    )
}

function EditCardNotesModal(props) {
    const [notes, setNotes] = useState("")
    return (
        <Modal className="" onEnter={() => setNotes(props.card.notes)} show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit notes</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <Form.Control
                    className="text-start position-relative"
                    as="textarea"
                    placeholder="Notes..."
                    defaultValue={notes}
                    rows={3}
                    onChange={(e) => {
                        setNotes(e.currentTarget.value)
                    }}
                    style={{ resize: "none" }}
                    onFocus={() => props.setIsTextInputFocused(true)}
                    onBlur={() => props.setIsTextInputFocused(false)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => {
                    props.handleClose()
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    props.card.notes = notes
                    props.handleClose()
                    props.repositionCards()
                }}>
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    )
}