import { Button, Col, Container, Form, Row } from "react-bootstrap"
import Navigation from "../Commons/Navigation/Navigation"
import "./Home.scss"
import { useEffect, useState } from "react"
import Footer from "../Commons/Footer/Footer"
import { FeedbackButton } from "../Commons/Functions"
import { useGameContext } from "../Commons/GameContext"


export default function Games() {
    const { currentGame, setCurrentGame, switchGame, gameData } = useGameContext()

    //const [loadedGames, setLoadedGames] = useState([])
    const [showcasedGameFiles, setShowcasedGameFiles] = useState([
        {
            name: "Magic the Gathering",
            image: "https://i.imgur.com/zV2lcZW.jpeg",
            description: "Up to date",
            url: "https://valcur.github.io/JSON/Game_MTG.json"
        }, {
            name: "Lorcana",
            image: "https://www.asdesjeux.com/cdn/shop/collections/F3xRtyuXoAAGE17.jpg?v=1704470537",
            description: "Up to date",
            url: "https://valcur.github.io/JSON/Game_Lorcana.json"
        }, {
            name: "Star Wars Unlimited",
            image: "https://i.imgur.com/FMrbNER.jpeg",
            description: "Up to date",
            url: "https://valcur.github.io/JSON/Game_SWU.json"
        }
    ])


    function addGame(game) {
        let tmp = { ...currentGame }
        let index = tmp.available.findIndex(a => a.name === game.name)
        if (index != -1) { return }

        tmp.available.push({
            name: game.name,
            image: game.image,
            url: game.url
        })
        setCurrentGame(tmp)
        setTimeout(() => {
            if (tmp.available.length == 1) {
                switchGame(tmp.available[0].name)
            }
        }, 100)
    }

    function removeGame(index) {
        // NEED TO CHANGE SELECTED GAME
        let tmp = { ...currentGame }
        tmp.available.splice(index, 1)
        setCurrentGame(tmp)
    }

    return (
        <div className="w-100 position-relative games">
            <Navigation currentPage="Games" />
            <div className="position-relative below-navbar">
                {gameData && (<div className="background w-100 h-100 position-absolute top-0 start-0" style={{ backgroundImage: "url(" + gameData.menuBackgroundImage + ")" }}></div>)}
                <div className="background-color w-100 h-100 position-absolute top-0 start-0"></div>
                <Container className="py-5 position-relative">
                    <h1 className="my-4 text-start">Your games</h1>
                    <Row>
                        {currentGame.available.length == 0 && (<div className="w-50 mx-auto my-5">
                            <div className="box-container flex-column p-5">
                                <p>You don't have any games yet. To play you need to add games first.</p>
                                <p>Add games below.</p>
                            </div>
                        </div>)}

                        {currentGame.available.length > 0 && currentGame.available.map((game, i) => {
                            return (
                                <Col xs={3}>
                                    <div className="d-flex flex-column">
                                        <div className="showcased-game d-flex flex-column w-100">
                                            <img src={game.image} className="" />
                                            <div className="d-flex flex-column justify-content-between p-3 position-relative text-start">
                                                <div className="gradient position-absolute w-100 start-0"></div>
                                                <h3>{game.name}</h3>
                                                <p>{game.description}</p>
                                                <div className="d-flex w-100 mt-auto justify-content-end">
                                                    <Button variant="primary" onClick={() => { removeGame(i) }}><img src={require("../Commons/Assets/Icons/delete.png")} /></Button>
                                                    {/*<Button className="">Update</Button>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>)
                        })}
                    </Row>
                </Container>
            </div>
            <div className="more-games py-5">
                <Container className="">
                    <h1 className="my-4 text-start">Find more games</h1>
                    <Row>
                        {/* <Col xs={3}>
                            <div className="showcased-game d-flex flex-column w-100 p-3">
                                <h3 className="text-start">Custom game</h3>
                                <p>Import custom game</p>

                                <Form.Control type="file" />

                                <FeedbackButton feedBack="Importing ...">Import</FeedbackButton>
                            </div>
                        </Col>*/}
                        {showcasedGameFiles.map((game) => {
                            if (!currentGame.available) { return }
                            let index = currentGame.available.findIndex(a => a.name == game.name)
                            return (<Col xs={3}>
                                <div className={"showcased-game d-flex flex-column w-100 mb-4" + (index == -1 ? "" : " disabled")}>
                                    <img src={game.image} className="" />
                                    <div className="d-flex flex-column justify-content-between p-3 position-relative text-start">
                                        <div className="gradient position-absolute w-100 start-0"></div>
                                        <h3>{game.name}</h3>
                                        <p>{game.description}</p>
                                        {game.url && (<Button className="ms-auto" onClick={() => {
                                            addGame(game)
                                        }}>Add to your games</Button>)}
                                        {!game.url && (<p>Coming soon</p>)}
                                    </div>
                                </div>
                            </Col>)
                        })}
                        <Col xs={3}>
                            <div className="showcased-game d-flex flex-column w-100 mb-4">
                                <img src={"https://exalts-table.com/static/media/background.5de9d5ed876a0078740e.jpg"} className="" />
                                <div className="d-flex flex-column justify-content-between p-3 position-relative text-start">
                                    <div className="gradient position-absolute w-100 start-0"></div>
                                    <h3>Altered</h3>
                                    <p>Dedicated web app for Altered</p>
                                    <Button className="ms-auto" target="_blank" variant="secondary" href="http://www.exalts-table.com">Play on Exalts Table</Button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={3} className="d-flex align-items-center">
                            <div className="showcased-game d-flex flex-column w-100 justify-content-center mb-4" style={{ height: "15rem" }}>
                                <h3 className="text-center">More to come...</h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer></Footer>
        </div >
    )
}