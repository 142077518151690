import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import Footer from "../Commons/Footer/Footer";
import Navigation from "../Commons/Navigation/Navigation";
import { useEffect, useState } from "react";
import { FeedbackButton } from "../Commons/Functions";
import { useGameContext } from "../Commons/GameContext";
import '../Commons/Commons.scss'

export function defaultPlayerData() {
    return {
        name: "Player-" + (Math.floor(Math.random() * 9000) + 1000),
        avatar: "",
        background: ""
    }
}

export function defaultOptionsData() {
    return {
        costAutoPay: true,
        visiblePlayArea: true
    }
}

export default function Preferences() {
    const [profile, setProfile] = useState(false)
    const [options, setOptions] = useState(false)

    useEffect(() => {
        let profileJSON = localStorage.getItem('user_preference');

        if (profileJSON) {
            const profileData = JSON.parse(profileJSON);
            setProfile(profileData)
        } else {
            let defaultData = defaultPlayerData()
            localStorage.setItem('user_preference', JSON.stringify(defaultData));
            setProfile(defaultData)
        }

        let optionsJSON = localStorage.getItem('user_options')

        if (optionsJSON) {
            const optionsData = JSON.parse(optionsJSON);
            setOptions(optionsData)
        } else {
            let defaultData = defaultOptionsData()
            localStorage.setItem('user_options', JSON.stringify(defaultData));
            setOptions(defaultData)
        }
    }, [])

    return (
        <div className="w-100">
            <Navigation currentPage="Preferences" />
            <div className="below-navbar">
                <Container className="p-5" style={{ minHeight: "85vh" }}>
                    {profile && (<PreferenceSection
                        profileData={profile}
                        setProfileData={setProfile}
                        options={options}
                        setOptions={setOptions}
                    />)}
                </Container>
            </div>
            <Footer></Footer>
        </div>
    )
}

export function PreferenceSection(props) {
    const { gameData } = useGameContext()
    function updateProfileField(field, value) {
        let tmp = { ...props.profileData }
        tmp[field] = value
        props.setProfileData(tmp)
        localStorage.setItem('user_preference', JSON.stringify(tmp));
    }

    return (<div className="d-flex flex-column preferences" style={{ minHeight: "70vh" }}>
        <Row>
            <Col xs={6}>
                {props.options && (<Form className="d-flex flex-column box-container text-start mb-4">
                    <h5>Options</h5>
                    <Form.Check
                        type="switch"
                        id="visible-area-switch"
                        checked={props.options.visiblePlayArea}
                        label="Keep card play areas visible at all time"
                        onChange={(e) => {
                            let tmp = { ...props.options }
                            tmp.visiblePlayArea = e.target.checked
                            props.setOptions(tmp)
                            localStorage.setItem('user_options', JSON.stringify(tmp));
                        }}
                    />
                    <Form.Check
                        type="switch"
                        id="auto-pay-switch"
                        checked={props.options.costAutoPay}
                        label="Auto pay ressource cost when playing cards"
                        onChange={(e) => {
                            let tmp = { ...props.options }
                            tmp.costAutoPay = e.target.checked
                            props.setOptions(tmp)
                            localStorage.setItem('user_options', JSON.stringify(tmp));
                        }}
                    />
                </Form>)}
                <Form.Group className="d-flex flex-column box-container">
                    <h5 className="text-start">Username</h5>
                    <div className="d-flex">
                        <Form.Control
                            type="text"
                            id="profile-username"
                            className='w-75'
                            defaultValue={props.profileData.name}
                        />
                        <FeedbackButton className="w-25" onClick={() => {
                            let val = document.getElementById("profile-username").value
                            updateProfileField("name", val)
                        }} feedBack="Changed !">Change</FeedbackButton>
                    </div>

                    <hr className="my-5"></hr>

                    <div className="d-flex justify-content-between mb-2 ms-0">
                        <div className="d-flex flex-column text-start">
                            <h5>Avatar</h5>
                            <p className="ms-2 me-4">Your avatar will be displayed next to your counters. For a better look, try to find a squared image.</p>
                        </div>
                        {props.profileData.avatar == "" ?
                            (
                                <p style={{ width: "10vh", height: "10vh" }}>No avatar</p>
                            ) :
                            (
                                <Image style={{ width: "10vh", height: "10vh", objectFit: "cover", borderRadius: "var(--bs-border-radius)" }} src={props.profileData.avatar} />
                            )}
                    </div>

                    <div className="d-flex">
                        <Form.Control
                            type="text"
                            id="profile-image"
                            className='w-75'
                            defaultValue={props.profileData.avatar}
                        />
                        <FeedbackButton className="w-25" onClick={() => {
                            let val = document.getElementById("profile-image").value
                            updateProfileField("avatar", val)
                        }} feedBack="Changed !">Change</FeedbackButton>
                    </div>
                </Form.Group>
            </Col>
            <Col xs={6}>
                <Form.Group className="d-flex flex-column box-container">
                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex flex-column text-start">
                            <h5>Playmat</h5>
                            <p className="ms-2 me-4">To ensure that the playmat will look great on your board, try to find a 16:9 image with a resolution of at least 1080p.</p>
                        </div>
                        <Image style={{ width: "35vh", height: "15vh", objectFit: "cover", borderRadius: "0.5rem" }} src={props.profileData.background} />
                    </div>

                    <div className="d-flex">
                        <Form.Control
                            type="text"
                            id="background-image"
                            className='w-75'
                            defaultValue={props.profileData.background}
                        />
                        <FeedbackButton className="w-25" onClick={() => {
                            let val = document.getElementById("background-image").value
                            updateProfileField("background", val)
                        }} feedBack="Changed !">Change</FeedbackButton>
                    </div>

                    {gameData && (
                        <div className="d-flex flex-column m-0">
                            <h5 className="text-start mt-4">Default playmats for {gameData.name}</h5>
                            <Row className="me-0">
                                {gameData.defaultRessources && gameData.defaultRessources.backgrounds && gameData.defaultRessources.backgrounds.map((image) => {
                                    return (<Col xs="6"><img key={image} src={image} className="w-100 preference-default-image" onClick={() => {
                                        updateProfileField("background", image)
                                    }} /></Col>)
                                })}
                            </Row>
                        </div>
                    )}
                </Form.Group>
            </Col>
        </Row>
    </div>)
}