import BoardSection from "./BoardSection"

export default function LayoutBoardSection(props) {
    const sectionLayout = props.sectionLayout

    let style = {}
    if (sectionLayout.style) {
        style = { ...sectionLayout.style }
        if (style.width) {
            style.maxWidth = style.width
            style.minWidth = style.width
        }
    }

    if (sectionLayout.direction) {
        return (
            <div className={'layout-board-section position-relative h-100 d-flex flex-' + sectionLayout.direction + (props.isOpponentSection && sectionLayout.isSymetricalForOpponents ? "-reverse" : "")} style={style}>
                {sectionLayout.content.map((sl, i) => (
                    <LayoutBoardSection
                        key={i}
                        sectionLayout={sl}
                        cardDraggedRef={props.cardDraggedRef}
                        moveCard={props.moveCard}
                        sections={props.sections}
                        sectionsCardsRef={props.sectionsCardsRef}
                        repositionCards={props.repositionCards}
                        isOpponentSection={props.isOpponentSection}
                        sendToHistory={props.sendToHistory}
                        playerId={props.playerId}
                        opponentIds={props.opponentIds}
                    />
                ))}
            </div>
        )
    }

    const section = sectionLayout.section

    if (!props.sections.sectionsDict[section]) { return }

    return (
        <div className="w-100" style={{ ...style, padding: "0.5vh" }}>
            <BoardSection
                key={section}
                cardDraggedRef={props.cardDraggedRef}
                moveCard={props.moveCard}
                section={props.sections.sectionsDict[section]}
                sectionCards={props.sectionsCardsRef.current}
                repositionCards={props.repositionCards}
                isOpponentSection={props.isOpponentSection}
                sendToHistory={props.sendToHistory}
                playerId={props.playerId}
                opponentIds={props.opponentIds}
            />
        </div>
    )
}