import { useEffect, useRef, useState } from "react"
import Card from "../Card/Card"
import './Stack.scss'
import { SECTIONS } from "../PlayerSection/PlayerSection"
import MouseDropableSection from "../MouseDropableSection"
import { Button } from "react-bootstrap"

export default function Stack(props) {
    function playLatestStackCard() {
        let card = props.latestStackCard

        if (card) {
            let type = card.isFlipped ? card.cardData.face.back.type : card.cardData.face.front.type
            let destination = props.sections.autoPlayFromStack[type]
            if (destination) {
                props.moveCard(card, destination)
                props.repositionCards()
            }
        }
    }

    return (
        <MouseDropableSection
            className="stack"
            sectionRef={props.stackRef}
            section={SECTIONS.STACK}
            cardDraggedRef={props.cardDraggedRef}
            moveCard={props.moveCard}
            repositionCards={props.repositionCards}
            displayedTitle={props.displayedTitle}
        >
            {props.latestStackCard && (<div className="buttons w-100 position-absolute d-flex flex-column">
                <Button onClick={() => playLatestStackCard()}>Play</Button>
            </div>)}
        </MouseDropableSection>
    )
}