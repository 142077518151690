import logo from './logo.svg';
import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Game from './Game/Game';
import Deckbuilder from './DeckBuilder/Deckbuilder';
import Games from './Home/Games';
import HomePage, { HomePageContent } from './Home/HomePage';
import { BrowserRouter, Route, Routes } from 'react-router';
import Decks from './DeckBuilder/Decks/Decks';
import Preference from './Home/Preference';
import { GameProvider } from './Commons/GameContext';
import { MobileNavbar } from './Commons/Navigation/Navigation';
import GameBuilder from './Home/GameBuilder/GameBuilder';

function App() {
  return (
    <GameProvider>
      <div className="App">
        <header className="App-header">
          <div className='mobile-warning homepage w-100'>
            <MobileNavbar />
            <HomePageContent />
          </div>
          <div className='w-100 app-content overflow-hidden'>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/games" element={<Games />} />
                <Route path="/decks" element={<Decks />} />
                <Route path="/deck/:deckId" element={<Deckbuilder />} />
                <Route path="/play" element={<Game />} />
                <Route path="/preferences" element={<Preference />} />
                <Route path="/build" element={<GameBuilder />} />
              </Routes>
            </BrowserRouter>
          </div>
        </header>
      </div>
    </GameProvider>
  );
}

export default App;
