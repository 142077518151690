import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"

export default function TokensModal(props) {
    const [searchQuery, setSearchQuery] = useState("")
    const [recentPick, setRecentPick] = useState([])
    const [displayedResults, setDisplayedResults] = useState([])

    useEffect(() => {
        if (searchQuery !== "") {
            const maxResults = 8
            let tmp = []
            for (let i = 0; i < props.tokens.length && tmp.length < maxResults; i++) {
                let c = props.tokens[i]
                if (c.name.toLowerCase().includes(searchQuery)) {
                    tmp.push({ ...c })
                }
            }
            console.log(tmp)

            setDisplayedResults(tmp)
        }
    }, [searchQuery])

    function createToken(c) {
        if (!recentPick.some(recent => recent.id === c.id)) {
            let tmp = [...recentPick]
            tmp.push(c)
            setRecentPick(tmp)
        }
        props.createToken(c)
    }

    return (
        <Modal className="fullscreen-modal modal-game-panel" show={props.show} onHide={props.handleClose} centered
            onExited={() => {
                setSearchQuery("")
            }}>
            <Modal.Header>
                <Modal.Title>Tokens</Modal.Title>
                <div className="d-flex w-25 ms-auto align-items-center">
                    <p className="m-0 me-3">Search: </p>
                    {props.tokens.length > 0 && (<Form.Control
                        type="text"
                        defaultValue={searchQuery}
                        placeholder="Token name"
                        className=''
                        onChange={(e) => {
                            setSearchQuery(e.target.value.toLowerCase())
                        }}
                    />)}
                </div>
            </Modal.Header>
            {props.tokens.length > 0 && (
                <Modal.Body>
                    <div className="d-flex flex-row h-100 w-100 overflow-x-auto">
                        {searchQuery == "" ? (
                            <div className="d-flex flex-row position-relative h-100">
                                {recentPick.map((c) => {
                                    return (<Token createToken={createToken} key={c.id} card={c} />)
                                })}
                                {recentPick.length == 0 && (<div>
                                    <p>No tokens recently picked. Search a token by name in the top right corner.</p>
                                </div>)}
                            </div>) : (
                            <div className="d-flex flex-row position-relative h-100">
                                {displayedResults.map((c) => {
                                    return (<Token createToken={createToken} key={c.id} card={c} />)
                                })}
                            </div>
                        )}
                    </div>
                </Modal.Body>
            )}
            {props.tokens.length == 0 && (
                <Modal.Body>
                    <div className="d-flex flex-row h-100 w-100">
                        <div className='box-container mx-auto my-auto'>
                            <p>This game doesn't have any token</p>
                        </div>
                    </div>
                </Modal.Body>
            )}
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )

    function Token(props) {
        return (
            <div className="modal-panel-card position-relative d-flex flex-column justify-content-between pb-4">
                <div className='position-relative h-75 w-100 mb-2'>
                    <img src={props.card.face.front.image} className='h-100' />
                </div>
                <div className="w-100 box-container flex-column m-0">
                    <div className='d-flex w-100'>
                        <Button className="w-100 m-1" onClick={() => { props.createToken(props.card) }}>Play</Button>
                    </div>
                </div>
            </div>
        )
    }
}