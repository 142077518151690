import { Alert, Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import DeckTopSection from "./Decks/DeckTopSection";
import { CustomTimeAgo, FeedbackButton } from "../Commons/Functions";
import { useEffect, useRef, useState } from "react";
import { useGameContext } from "../Commons/GameContext";

export default function DeckBuilderTopSection(props) {
    const { currentGame } = useGameContext()
    const [showImportModal, setShowImportModal] = useState(false)
    const [importNotFound, setImportNotFound] = useState([])
    const [showExportModal, setShowExportModal] = useState(false)
    const [showEditNameModal, setShowEditNameModal] = useState(false)
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)
    const [lastSavedDecklist, setLastSavedDecklist] = useState({})
    const [lastSavedName, setLastSavedName] = useState("")

    useEffect(() => {
        setLastSavedDecklist(props.deck.deckList)
        setLastSavedName(props.deck.title)
        const beforeUnload = async (e) => {
            if (props.hasUnsavedChangesRef.current) {
                e.preventDefault()
                return (e.returnValue = "Deck changes will be lost.")
            }
        }
        window.addEventListener('beforeunload', beforeUnload)
        return () => {
            window.removeEventListener("beforeunload", beforeUnload);
        };
    }, [])

    useEffect(() => {
        if (props.deck.deckList !== lastSavedDecklist || props.deck.title != lastSavedName) {
            setHasUnsavedChanges(true)
        } else {
            setHasUnsavedChanges(false)
        }
    }, [props.deck])

    useEffect(() => {
        props.hasUnsavedChangesRef.current = hasUnsavedChanges
    }, [hasUnsavedChanges])

    function save() {
        let deck = { ...props.deck }
        deck.lastModifiedAt = new Date()
        props.setDeck(deck)

        const deckData = JSON.parse(JSON.stringify(deck))
        deckData.deckList.categoriesOrder.forEach(cat => {
            deckData.deckList[cat].forEach((card, i) => {
                deckData.deckList[cat][i] = { count: card.count, id: card.id }
            })
        })

        const deckDataJSON = JSON.stringify(deckData);
        localStorage.setItem("user_decks_" + currentGame.selected + "_" + deck.id, deckDataJSON)
        setHasUnsavedChanges(false)
        setLastSavedDecklist(props.deck.deckList)
        setLastSavedName(props.deck.title)
    }

    function importList(input, shouldReplaceDeck) {
        const lignes = input.trim().split('\n');

        const tableau = lignes.map(ligne => {
            const [count, ...cardParts] = ligne.split(' ');
            const cardName = cardParts.join(' ').toLowerCase();
            return { count: parseInt(count), cardName };
        });

        for (let i = 0; i < tableau.length; i++) {
            if (tableau[i].cardName.length <= 1) {
                tableau.splice(i, 1)
                i -= 1
            }
        }


        let cardsList = props.cardsListRef.current

        const comparableCardName = (str) => {
            return str.replace(/[^a-zA-Z0-9 ]/g, '')   // Remove everything except a-z, A-Z, 0-9, and spaces
                .replace(/\s+/g, ' ')                 // Replace multiple spaces with a single one
                .trim();
        }

        tableau.forEach((e) => {
            for (let i = 0; i < cardsList.length; i++) {
                if (comparableCardName(cardsList[i].name.toLowerCase()) === comparableCardName(e.cardName)) {
                    e.card = { ...cardsList[i] }
                }
            }
            // If not found strict, search for part
            if (!e.card) {
                for (let i = 0; i < cardsList.length; i++) {
                    if (comparableCardName(cardsList[i].name.toLowerCase()).includes(comparableCardName(e.cardName))) {
                        e.card = { ...cardsList[i] }
                    }
                }
            }
        })

        let notFound = []

        tableau.forEach((e) => {
            if (!e.card && e.cardName) {
                notFound.push(e.cardName)
            }
        })

        console.log(tableau, notFound)
        setImportNotFound(notFound)

        if (notFound.length == 0) {
            let tmp = { ...props.deck.deckList }
            if (shouldReplaceDeck) {
                tmp = { categoriesOrder: [] }
            }
            props.addCardsToDeckList(tableau, tmp)
            return true
        }
        return false
    }

    function changeDeckName(newName) {
        let tmp = { ...props.deck }
        tmp.title = newName
        props.setDeck(tmp)
    }

    return (
        <DeckTopSection>
            <div className="d-flex mb-2">
                <Button onClick={() => setShowEditNameModal(true)}><img src={require('../Commons/Assets/Icons/edit.png')} /></Button>
                <h1 className="ms-3 mb-0 text-start">{props.deck.title}</h1>
            </div>
            <Row>
                <Col>
                    <div className="d-flex flex-row">
                        <p className="me-4">Created <CustomTimeAgo date={props.deck.createdAt} /></p>
                        <p>Last modified <CustomTimeAgo date={props.deck.lastModifiedAt} /></p>
                    </div>
                </Col>
                <Col className="d-flex flex-row justify-content-end">
                    {hasUnsavedChanges && (<FeedbackButton variant="secondary" onClick={() => save()} feedBack="Saved !">Save</FeedbackButton>)}
                    {/*<Button variant="secondary" className="mx-2" onClick={() => setShowExportModal(true)}>Export</Button>*/}
                    <Button className="ms-2" variant="secondary" onClick={() => setShowImportModal(true)}>Import</Button>
                </Col>
            </Row>
            <ImportModal show={showImportModal} handleClose={() => setShowImportModal(false)} importList={importList} notFound={importNotFound} />
            <ExportModal show={showExportModal} handleClose={() => setShowExportModal(false)} />
            <EditNameModal show={showEditNameModal} handleClose={() => setShowEditNameModal(false)} changeDeckName={changeDeckName} deckName={props.deck.title} />
        </DeckTopSection>
    )
}

function ImportModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Import</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.notFound.map((cardName) => {
                    return (<Alert key={cardName} variant="danger">Card not found {cardName}</Alert>)
                })}
                <Form.Control id="import-decklist" as="textarea" placeholder="X Card name" rows={15} autoFocus />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    props.handleClose()
                }}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    let input = document.getElementById('import-decklist').value;
                    if (props.importList(input, false)) {
                        props.handleClose()
                    }
                }}>
                    Import and add
                </Button>
                <Button variant="primary" onClick={() => {
                    let input = document.getElementById('import-decklist').value;
                    if (props.importList(input, true)) {
                        props.handleClose()
                    }
                }}>
                    Import and replace
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function ExportModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Export</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Control id="import-decklist" as="textarea" value="erzerr\nzerzer" rows={3} readOnly autoFocus />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    //props.createNewDeck()
                    props.handleClose()
                }}>
                    Close
                </Button>
                <Button variant="secondary" onClick={() => {
                    //props.createNewDeck()
                    props.handleClose()
                }}>
                    Copy
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

function EditNameModal(props) {
    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit deck name</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-row align-items-center justify-content-between'>
                    <p className="m-0">Name</p>
                    <div className="d-flex flex-row box-container w-75">
                        <Form.Control
                            type="text"
                            id="deck-title"
                            defaultValue={props.deckName}
                            placeholder="Deck name"
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    props.handleClose()
                }}>
                    Close
                </Button>
                <Button variant="primary" onClick={() => {
                    let input = document.getElementById('deck-title').value;
                    props.changeDeckName(input)
                    props.handleClose()
                }}>
                    Change name
                </Button>
            </Modal.Footer>
        </Modal>
    )
}