import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import ReactTimeago from "react-timeago";

export function shuffleArray(array) {
    for (var i = array.length - 1; i >= 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

export function FeedbackButton(props) {
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setLoading(false)
            }, 1000)
        }
    }, [isLoading]);

    const handleClick = () => setLoading(true);

    return (
        <Button
            variant={props.variant}
            disabled={isLoading || props.disabled}
            className={props.className}
            onClick={() => {
                if (!isLoading) {
                    handleClick()
                    props.onClick()
                }
            }}
        >
            {isLoading ? props.feedBack : props.children}
        </Button>
    );
}

export function CustomTimeAgo({ date }) {
    const now = new Date();
    const diffInSeconds = Math.floor((now - new Date(date)) / 1000);

    if (diffInSeconds < 60) {
        return <span>recently</span>;
    }

    return <ReactTimeago date={date} />;
}

export function hideCard(card, val, playerId, opponentIds) {
    card.hiddenTo = {}
    if (val == "yes") {
        [...opponentIds, playerId].forEach(id => {
            card.hiddenTo[id] = true
        })
    } else if (val == "opponent-only") {
        opponentIds.forEach(id => {
            card.hiddenTo[id] = true
        })
    }
}

export function isCardHidden(card, playerId, opponentIds) {
    let isHidden = "opponent-only"
    if (card.hiddenTo[playerId]) {
        isHidden = "yes"
    }
    opponentIds.forEach(id => {
        if (!card.hiddenTo[id]) {
            isHidden = "no"
        }
    })
    return isHidden
}

export function BlurredBackground() {
    return (<div className="position-absolute blurred-background w-100 h-100 start-0 top-0">
        <div className="blur position-absolute top-0 start-0 h-100 w-100"></div>
        <div className="background position-absolute top-0 start-0 h-100 w-100"></div>
    </div>)
}