export default function NewTurnMessage(props) {
    let currentOpponent = props.opponentsData[props.currentPlayer]

    if (!props.gameOptions) { return }

    return (
        <div key={props.turnCount} className={"new-turn-message pe-none position-fixed top-0 translate-middle" + (currentOpponent ? "" : " your-turn")}>
            <p class="m-0">{currentOpponent ? currentOpponent.profileData.name + "'s" : "Your"} turn</p>
        </div>
    )
}