import { Alert, Button, Container, Offcanvas } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";

export const ACTION_MODES = {
    NONE: "NONE",
    GROUP: "GROUP",
    PING: "PING",
    LINK_FROM: "LINK_FROM",
    LINK_TO: "LINK_TO",
    PRESENT: "PRESENT",
    DISCARD: "DISCARD",
    REMOVE: "REMOVE",
    FREE_PLAY: "FREE_PLAY",
    CARD_EFFECT: "CARD_EFFECT"
}

export default function ActionMode(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => props.setActionMode({ action: ACTION_MODES.NONE });
    const handleShow = () => setShow(true);
    const [actionDescription, setActionDescription] = useState("")
    const isTextInputFocusedRef = useRef(false)

    useEffect(() => {
        isTextInputFocusedRef.current = props.isTextInputFocused
    }, [props.isTextInputFocused])

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isTextInputFocusedRef.current) { return }
            if (event.repeat) return;
            if (event.key === "h") {
                props.setActionMode({ action: ACTION_MODES.PING })
            }
            if (event.key === "l") {
                props.setActionMode({ action: ACTION_MODES.LINK_FROM })
            }
            if (event.key === "p") {
                props.setActionMode({ action: ACTION_MODES.PRESENT })
            }
            if (event.key === "d") {
                props.setActionMode({ action: ACTION_MODES.DISCARD })
            }
            if (event.key === "r") {
                props.setActionMode({ action: ACTION_MODES.REMOVE })
            }
            if (event.key === "f") {
                props.setActionMode({ action: ACTION_MODES.FREE_PLAY })
            }
            if (event.key === "t") {
                props.setActionMode({ action: ACTION_MODES.CARD_EFFECT })
            }
        };
        const handleKeyUp = (event) => {
            // Pour l'instant
            props.setActionMode({ action: ACTION_MODES.NONE })
        };
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        if (props.actionMode.action != ACTION_MODES.NONE) {
            switch (props.actionMode.action) {
                case ACTION_MODES.GROUP:
                    setActionDescription("Choose the card you want to put " + props.actionMode.actionData.cardName + " under")
                    break;
                case ACTION_MODES.LINK_FROM:
                    setActionDescription("Choose the source")
                    break;
                case ACTION_MODES.LINK_TO:
                    setActionDescription("Choose the target for " + props.actionMode.actionData.fromCardName)
                    break;
                case ACTION_MODES.PING:
                    setActionDescription("Choose a card to ping")
                    break;
                case ACTION_MODES.PRESENT:
                    setActionDescription("Choose a card to present to your opponents")
                    break;
                case ACTION_MODES.DISCARD:
                    setActionDescription("Choose the card to discard")
                    break;
                case ACTION_MODES.REMOVE:
                    setActionDescription("Choose the card to remove from the game")
                    break;
                case ACTION_MODES.FREE_PLAY:
                    setActionDescription("Cards will be played for free")
                    break;
                case ACTION_MODES.CARD_EFFECT:
                    setActionDescription("Choose a card to add its effect to the pile")
                    break;
                default:
                    setActionDescription("Unknown action")
            }
        }
    }, [props.actionMode])

    return (
        <Offcanvas className="action-mode pe-none" show={props.actionMode.action != ACTION_MODES.NONE} onHide={handleClose} placement="top" scroll={true} backdrop={false} >
            <Container>
                <Alert variant="secondary pe-auto d-flex justify-content-center align-items-center" className="m-4">
                    <p className="m-0 me-auto">{actionDescription}</p>
                    <Button onClick={handleClose}>Cancel</Button>
                </Alert>
            </Container>
        </Offcanvas>
    )
}