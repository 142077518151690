import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Button, Form, Spinner } from 'react-bootstrap';
import { useGameContext } from '../GameContext';
import { useNavigate } from 'react-router';
import './Navigation.scss'
import { useEffect, useState } from 'react';

export default function Navigation(props) {
    const { currentGame, switchGame, isLoadingGameData } = useGameContext()
    const [deckCount, setDeckCount] = useState(0)
    const navigate = useNavigate()
    const currentPage = props.currentPage
    const hasUnsavedChangesRef = props.hasUnsavedChangesRef

    useEffect(() => {
        let decks = localStorage.getItem("user_decks_" + currentGame.selected);
        if (decks) {
            decks = JSON.parse(decks)
            setDeckCount(decks.length)
        }
    }, [currentGame.selected, props.toggle])

    return (
        <Navbar className="bg-body-tertiary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand><NavLink href="/">
                    TCG
                    <img
                        alt=""
                        src={require('../Assets/Icons/appicon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        style={{ marginRight: "-0.4rem", marginLeft: "0.1rem" }}
                    />
                    rena
                </NavLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink value="Games" currentPage={currentPage} href="/games">
                            Games
                            {currentGame.available.length == 0 && currentPage != "Games" && (
                                <div className='position-absolute tuto-message'>
                                    <div className='position-absolute'></div>
                                    You don't have any games loaded yet. Add some here.
                                </div>)}
                        </NavLink>
                        {!isLoadingGameData && currentGame.available.length > 0 && (<Form.Select aria-label="Default select example"
                            onChange={(e) => {
                                // ONLY SHOW THE SELECT IF NOT ON DECKS
                                switchGame(e.currentTarget.value)
                            }}
                            disabled={currentPage == "DeckList"}
                            value={currentGame.selected}
                        >
                            {currentGame && currentGame.available.map((g) => {
                                return (<option key={g.name} value={g.name}>{g.name}</option>)
                            })}
                        </Form.Select>)}

                        {isLoadingGameData && (<div>
                            <Spinner animation="border" />
                        </div>)}


                        <NavLink value="Decks" currentPage={currentPage} disabled={currentGame.selected === false} href="/decks">
                            Decks
                            {currentGame.available.length > 0 && deckCount == 0 && currentPage != "Decks" && (
                                <div className='position-absolute tuto-message'>
                                    <div className='position-absolute'></div>
                                    You don't have any deck for this game. Create one here !
                                </div>)}
                        </NavLink>
                        <NavLink value="Play" currentPage={currentPage} disabled={currentGame.selected === false || deckCount <= 0} href="/play">Play</NavLink>
                        <NavLink value="Preferences" currentPage={currentPage} href="/preferences">Preferences</NavLink>
                        {/*<NavLink href="#link">Help</NavLink>
                        <NavLink href="#link">Discord</NavLink>
                        <NavLink href="#link">Support</NavLink>*/}
                    </Nav>
                    <Nav className="">
                        <Nav.Link className="ms-auto" target="_blank" href="https://discord.gg/H6DA35zxPB">
                            <img
                                src={require('../Assets/Icons/discord.png')} />
                            Discord
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )

    function NavLink(props) {
        return (<Nav.Link className={props.currentPage == props.value ? "current-page" : ""} disabled={props.disabled} href="" onClick={() => {
            if (hasUnsavedChangesRef && hasUnsavedChangesRef.current) {
                if (window.confirm('You have unsaved changes. Are you sure you want to leave the deck builder ? (All unsaved changes will be lost)')) {
                    navigate(props.href)
                }
            } else {
                navigate(props.href)
            }
        }}>{props.children}</Nav.Link>)
    }
}

export function MobileNavbar() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary" data-bs-theme="dark">
            <Container>
                <Navbar.Brand><Nav.Link href="/">
                    TCG
                    <img
                        alt=""
                        src={require('../Assets/Icons/appicon.png')}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                        style={{ marginRight: "-0.4rem", marginLeft: "0.1rem" }}
                    />
                    rena
                </Nav.Link></Navbar.Brand>
                <p className='w-100 m-0 text-start'>This app is not available on mobile</p>
            </Container>
        </Navbar>
    )
}