import { useEffect, useRef, useState } from 'react'
import Card from '../Card/Card'
import './BoardSection.scss'
import { SECTIONS } from '../PlayerSection/PlayerSection'
import MouseDropableSection from '../MouseDropableSection'
import { Button, Dropdown, DropdownButton } from 'react-bootstrap'
import { hideCard } from '../../Commons/Functions'

export default function BoardSection(props) {
    const [quickActionButtons, setQuiActionsButtons] = useState(props.section.quickActionButtons ? " button-" + props.section.quickActionButtons.join(" button-") : "")
    const manaToggleUpRef = useRef(true)
    const manaToggleDownRef = useRef(true)


    //--------------------- BUTTONS ---------------------

    useEffect(() => {
        if (props.section.title == "Mana" && !props.isOpponentSection) {
            const handleKeyDown = (event) => {
                if (event.repeat) { return }
                if (event.key === 'ArrowUp') {
                    manaToggleUpRef.current = !manaToggleUpRef.current
                } else if (event.key === 'ArrowDown') {
                    manaToggleDownRef.current = !manaToggleDownRef.current
                }
            }

            window.addEventListener('keydown', handleKeyDown);

            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [])

    useEffect(() => {
        if (props.sectionCards[props.section.title]) {
            tapOne()
        }
    }, [manaToggleDownRef.current])

    useEffect(() => {
        if (props.sectionCards[props.section.title]) {
            untapOne()
        }
    }, [manaToggleUpRef.current])

    function setAllIsTappedTo(val) {
        const sectionCards = props.sectionCards[props.section.title]
        sectionCards.forEach((c, i) => {
            c.card.isTapped = val
        })
        props.repositionCards()
    }

    function allToSection(val) {
        const sectionCards = props.sectionCards[props.section.title]
        sectionCards.forEach((c, i) => {
            if (c.card.grouppedCards) {
                c.card.grouppedCards.forEach((child, j) => {
                    props.moveCard(child, val)
                })
                delete c.card.grouppedCards
            }
            props.moveCard(c.card, val)
        })
        console.log(sectionCards)
        props.repositionCards()
    }

    function setAllIsHiddenTo(val) {
        const sectionCards = props.sectionCards[props.section.title]
        sectionCards.forEach((c, i) => {
            hideCard(c.card, val, props.playerId, props.opponentIds)
        })

        if (val == "yes") {
            props.sendToHistory("hid all cards in " + props.section.title)
        } else if (val == "opponent-only") {
            props.sendToHistory("hid from other players all cards in " + props.section.title)
        } else if (val == "no") {
            props.sendToHistory("revealed all cards in " + props.section.title, props.card)
        }
        props.repositionCards()
    }

    function tapOne() {
        const sectionCards = props.sectionCards[props.section.title]
        let success = false
        sectionCards.forEach((c, i) => {
            if (!c.card.isTapped && !success) {
                c.card.isTapped = true
                success = true
            }
        })
        props.repositionCards()
    }

    function untapOne() {
        const sectionCards = props.sectionCards[props.section.title]
        let success = false
        sectionCards.reverse().forEach((c, i) => {
            if (c.card.isTapped && !success) {
                c.card.isTapped = false
                success = true
            }
        })
        props.repositionCards()
    }

    return (
        <div className={"board-section position-relative" + " alignment-" + props.section.alignment} style={{ height: props.section.height + "vh", width: "100%" }}>
            <div className='position-absolute top-0 start-0 w-100 h-100 blurred-background'></div>
            <div className="position-absolute top-0 end-0 section-top-visual"></div>
            <MouseDropableSection
                className="board-section w-100 h-100"
                sectionRef={props.section.sectionRef}
                section={props.section.title}
                cardDraggedRef={props.cardDraggedRef}
                moveCard={props.moveCard}
                repositionCards={props.repositionCards}
                displayedTitle={props.section.displayedTitle}
            >
            </MouseDropableSection>
            {!props.section.noQuickActions && (<div className={"buttons d-flex flex-row position-absolute" + quickActionButtons}>
                {props.section.title == "Mana" && props.sectionCards[props.section.title] && (<div className="text mx-2 ressource-count">{props.sectionCards[props.section.title].filter((m) => !m.card.isTapped).length}/{props.sectionCards[props.section.title].length}</div>)}
                <DropdownButton onClick={props.onClick} className="" title="">
                    <Dropdown.Item className="tap-all" onClick={() => setAllIsTappedTo(true)}>Tap all</Dropdown.Item>
                    <Dropdown.Item className="untap-all" onClick={() => setAllIsTappedTo(false)}>Untap all</Dropdown.Item>
                    <Dropdown.Divider></Dropdown.Divider>
                    <Dropdown.Item className="discard-all" onClick={() => allToSection(SECTIONS.DISCARD)}>Discard all</Dropdown.Item>
                    <Dropdown.Item className="hand-all" onClick={() => allToSection(SECTIONS.HAND)}>To hand all</Dropdown.Item>
                    <Dropdown.Item className="exile-all" onClick={() => allToSection(SECTIONS.EXILE)}>Remove all</Dropdown.Item>
                    <Dropdown.Divider></Dropdown.Divider>
                    <Dropdown.Item className="reveal-all" onClick={() => setAllIsHiddenTo("opponent-only")}>Reveal all to you</Dropdown.Item>
                    <Dropdown.Item className="reveal-all" onClick={() => setAllIsHiddenTo("no")}>Reveal all</Dropdown.Item>
                    <Dropdown.Item className="hide-all" onClick={() => setAllIsHiddenTo("yes")}>Hide all</Dropdown.Item>
                </DropdownButton>
                <Button className="tap-one" onClick={() => tapOne()}>Ressource -</Button>
                <Button className="untap-one" onClick={() => untapOne()}>Ressource +</Button>
            </div>)}
        </div>
    )
}