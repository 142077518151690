import { Button, Modal } from "react-bootstrap";
import { FeedbackButton } from "../../Commons/Functions";
import { useState } from "react";

export default function DiceModal(props) {
    const [result, setResult] = useState(0)

    function rollDice(faces) {
        let res = Math.floor(Math.random() * faces) + 1;
        setResult(res)
        props.sendToHistory("rolled a " + res + "/" + faces)
    }

    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Dice</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Choose a dice to roll:</p>
                <div className="d-flex flex-column">
                    {[2, 4, 6, 8, 10, 12, 20].map(faces => (
                        <FeedbackButton key={faces} className='my-1' onClick={() => rollDice(faces)} feedBack={"You rolled " + result}>{faces}</FeedbackButton>
                    ))}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={props.handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}