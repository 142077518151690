import { useEffect, useState } from "react"
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap"

export default function FormatPickerModal(props) {
    const [gameOptions, setGameOptions] = useState({
        format: false,
        startingPlayer: { name: "", id: "" }
    })

    useEffect(() => {
        let tmp = { ...props.gameData.deckBuilding.formats[0] }
        if (props.gameData.gameplay[tmp.gameplay]) {
            tmp = { ...tmp, ...props.gameData.gameplay[tmp.gameplay] }
            setGameOptions({
                format: tmp,
                startingPlayer: { name: "Random", id: "RANDOM_STARTING_PLAYER" }
            })
        } else {
            console.log("Error, no gameplay called " + tmp.gameplay + " found")
        }
    }, [])

    function changeOptionValue(option, val) {
        let tmp = { ...gameOptions }
        tmp[option] = val
        if (option === "format") {
            const gampeplayData = props.gameData.gameplay[val.gameplay]
            if (gampeplayData) {
                tmp["format"] = { ...tmp["format"], ...gampeplayData }
                setGameOptions(tmp)
            } else {
                console.log("Error, no gameplay called " + val.gameplay + " found")
            }
        } else {
            setGameOptions(tmp)
        }
    }

    return (
        <Modal className="" show={props.show} onHide={props.handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Start the game</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {!props.gameOptions && (<p>Once options have been chosen, new players won't be able to join the room.</p>)}
                {props.gameOptions && (<p>End current game and start a new one.</p>)}
            </Modal.Body>
            <Modal.Body>
                <h5>Game options</h5>
                <FloatingLabel className="" controlId={"format-selection"} label={"Format"}>
                    <Form.Select aria-label="Format select"
                        defaultValue={gameOptions.format.title}
                        onChange={(e) => {
                            let newFormat = false
                            props.gameData.deckBuilding.formats.forEach(f => {
                                if (f.title == e.currentTarget.value) {
                                    newFormat = { ...f }
                                }
                            })
                            changeOptionValue("format", newFormat)
                        }}
                    >
                        {props.gameData.deckBuilding.formats.map((v, j) => {
                            return (<option key={v.title} value={v.title}>{v.title}</option>)
                        })}
                    </Form.Select>
                </FloatingLabel>
                <FloatingLabel className="mt-2" controlId={"format-selection"} label={"Starting player"}>
                    <Form.Select aria-label="Format select"
                        defaultValue={gameOptions.startingPlayer.id}
                        onChange={(e) => {
                            let val = { name: "", id: "" }
                            let newId = e.currentTarget.value
                            if (newId == "RANDOM_STARTING_PLAYER") {
                                val.name = "Random"
                            } else {
                                val.name = props.peerInstance.current.id === newId ? props.playerData.profileData.name : props.opponentsData[newId].profileData.name
                            }
                            val.id = newId
                            changeOptionValue("startingPlayer", val)
                        }}
                    >
                        <option key="RANDOM_STARTING_PLAYER" value="RANDOM_STARTING_PLAYER">Random</option>
                        {props.playerTurnOrder.map((p, j) => {
                            if (props.peerInstance.current.id === p) {
                                return (<option key={p} value={p}>{props.playerData.profileData.name}</option>)
                            } else if (props.opponentsData[p]) {
                                return (<option key={p} value={p}>{props.opponentsData[p].profileData.name}</option>)
                            }
                        })}
                    </Form.Select>
                </FloatingLabel>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => {
                    props.setGameOptions(gameOptions)
                    props.handleClose()
                }}>
                    Start
                </Button>
            </Modal.Footer>
        </Modal>
    )
}