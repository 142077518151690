import { createRef, useEffect, useRef, useState } from 'react'
import './PlayerSection.scss'
import Hand from '../Hand/Hand'
import BoardSection from '../Board/BoardSection'
import { v4 as uuidv4 } from 'uuid';
import Card from '../Card/Card';
import Deck from '../Deck/Deck';
import Discard from '../Discard/Discard';
import Stack from '../Stack/Stack';
import { hideCard, isCardHidden, shuffleArray } from '../../Commons/Functions';
import { useGameContext } from '../../Commons/GameContext';
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap';
import { ACTION_MODES } from '../ActionMode';
import LayoutBoardSection from '../Board/LayoutBoardSection';
import DeckPickerModal from './Modals/DeckPickerModal';
import TokensModal from './Modals/TokensModal';

const defaultCard = {
    id: false,
    position: {
        section: "NONE",
        index: -1,
        reversedIndex: -1
    },
    hiddenTo: {},
    isTapped: false,
    isFlipped: false,
    cardData: {
        name: "ezfzfzef",
        id: "tergegr",
    },
    counters: [],
    notes: ""
}

export const SECTIONS = {
    HAND: "Hand",
    DISCARD: "Discard",
    STACK: "Stack",
    DECK: "Deck",
    EXILE: "Exile",
    EXILE_HIDDEN: "ExileHidden",
    ALIGNMENTS: {
        START: "Start",
        END: "End",
        CENTER: "Center",
        NONE: "None",
        STACK: "Stack"
    },
    SECTION_SIZES: {
        SMALL: 13,
        DEFAULT: 15,
        MEDIUM: 20,
        BIG: 26,
        HUGE: 40
    }
}

export const defaultSections = {
    sections: [
        "Hand",
        "Discard",
        "Deck",
        "Stack",
        "Exile",
        "ExileHidden"
    ],
    sectionsDict: {
        "Hand": {
            "isDefaultSection": true,
            "title": "Hand",
            "isHidden": "opponent-only",
            "height": "DEFAULT",
            "alignment": "CENTER",
            "autoPayFrom": true,
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        },
        "Discard": {
            "isDefaultSection": true,
            "title": "Discard",
            "isHidden": "no",
            "height": "DEFAULT",
            "alignment": "NONE",
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        },
        "Deck": {
            "isDefaultSection": true,
            "title": "Deck",
            "isHidden": "yes",
            "height": "DEFAULT",
            "alignment": "NONE",
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        },
        "Stack": {
            "isDefaultSection": true,
            "title": "Stack",
            "isHidden": "no",
            "height": "HUGE",
            "alignment": "NONE",
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        },
        "Exile": {
            "isDefaultSection": true,
            "title": "Exile",
            "isHidden": "yes",
            "height": "DEFAULT",
            "alignment": "NONE",
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        },
        "ExileHidden": {
            "isDefaultSection": true,
            "title": "ExileHidden",
            "isHidden": "yes",
            "height": "DEFAULT",
            "alignment": "NONE",
            "isHorizontalAllowed": false,
            "isGroupForbidden": true
        }
    }
}

export default function PlayerSection(props) {
    const { gameCards, gameData, currentGame, cardSize } = useGameContext()
    const [deck, setDeck] = useState([])
    const [topCardHiddenTo, setTopCardHiddenTo] = useState({})
    const [visibleCards, setVisibleCards] = useState([])
    const visibleCardsRef = useRef([])

    const cardDraggedRef = useRef(false)
    const handRef = useRef(null)
    const deckRef = useRef(null)
    const discardRef = useRef(null)

    const [sections, setSections] = useState(false)
    const sectionsCardsRef = useRef({})

    const [opponentStackCards, setOpponentStackCards] = useState([])
    const [latestStackCard, setLatestSackCard] = useState(false)

    const [availableTokens, setAvailableTokens] = useState([])

    const [showDeckPickerModal, setShowDeckPickerModal] = useState(false)

    const [hoverCardCost, setHoverCardCost] = useState(0)

    function createGameCardFromCardData(c) {
        let newCard = { ...defaultCard }
        newCard.cardData = { ...c }
        newCard.hiddenTo = JSON.parse(JSON.stringify({}))
        newCard.isHorizontal = false
        delete newCard.cardData.count
        newCard.counters = [{}, {}]
        newCard.id = uuidv4()
        return newCard
    }

    /*------------------------------- USE EFFECTS -------------------------------*/

    useEffect(() => {
        if (props.gameOptions) {
            const sectionsFile = props.gameOptions.format.sections

            // SETUP SECTIONS
            let newSections = {
                allSections: [...sectionsFile.customSections, ...defaultSections.sections],
                customSections: [...sectionsFile.customSections],
                layout: { ...sectionsFile.layout },
                autoPlayFromHand: sectionsFile.autoPlayFromHand,
                autoPlayFromStack: sectionsFile.autoPlayFromStack,
                sectionsDict: {},
            }
            sectionsFile.sectionsDict = { ...defaultSections.sectionsDict, ...sectionsFile.sectionsDict }
            newSections.allSections.forEach((key) => {
                let section = sectionsFile.sectionsDict[key]
                let ref = null

                if (section.isDefaultSection) {
                    if (key == SECTIONS.HAND) {
                        ref = handRef
                    } else if (key == SECTIONS.DISCARD || key == SECTIONS.EXILE || key == SECTIONS.EXILE_HIDDEN) {
                        ref = discardRef
                    } else if (key == SECTIONS.STACK) {
                        ref = props.stackRef
                    } else if (key == SECTIONS.DECK) {
                        ref = deckRef
                    }
                } else {
                    ref = createRef()
                }

                newSections.sectionsDict[key] = {
                    title: section.title,
                    isHidden: section.isHidden,
                    sectionRef: ref,
                    sectionBoundingClientRect: false,
                    height: SECTIONS.SECTION_SIZES[section.height],
                    alignment: SECTIONS.ALIGNMENTS[section.alignment],
                    cardHeight: SECTIONS.SECTION_SIZES[section.height],
                    cardWidth: SECTIONS.SECTION_SIZES[section.height] * 0.7,
                    autoPayFrom: section.autoPayFrom,
                    noAutoPayTo: section.noAutoPayTo,
                    enterTapped: section.enterTapped,
                    quickActionButtons: section.quickActionButtons,
                    isHorizontalAllowed: section.isHorizontalAllowed,
                    isGroupForbidden: section.isGroupForbidden,
                    keepTappedNewTurn: section.keepTappedNewTurn,
                    displayedTitle: section.displayedTitle,
                    showHiddenCardInHistory: section.showHiddenCardInHistory,
                    noQuickActions: section.noQuickActions
                }
            })

            setSections(newSections)
            setShowDeckPickerModal(true)
            visibleCardsRef.current = []
            sectionsCardsRef.current = {}
            setDeck([])

            let deckTopHidden = {}
            let tmp = [...props.opponentIds, props.playerId]
            tmp.forEach(id => {
                deckTopHidden[id] = true
            })
            setTopCardHiddenTo(deckTopHidden)
            sendData([], [], deckTopHidden)
        }
    }, [props.gameOptions])

    useEffect(() => {
        if (props.turnCount > 1) {
            newTurn()
        }
    }, [props.turnCount])

    useEffect(() => {
        if (props.givenCard) {
            let newCard = { ...props.givenCard }
            let rect = sections.sectionsDict[SECTIONS.STACK].sectionBoundingClientRect
            let stackSectionData = sections.sectionsDict[SECTIONS.STACK]

            let allCards = [...visibleCardsRef.current, ...opponentStackCards]
            let stackCount = 0
            allCards.forEach((c, i) => {
                if (c.position.section == SECTIONS.STACK) {
                    stackCount += 1
                }
            })

            newCard.position = {
                section: SECTIONS.STACK,
                pos: { left: rect.left, top: rect.top },
                cardSize: {
                    width: newCard.isHorizontal ? stackSectionData.cardHeight : stackSectionData.cardWidth,
                    height: newCard.isHorizontal ? stackSectionData.cardWidth : stackSectionData.cardHeight
                }
            }

            newCard.stackIndex = stackCount + 1

            visibleCardsRef.current.push(newCard)
            repositionCards([SECTIONS.STACK])
            props.setGivenCard(false)
        }
    }, [props.givenCard])

    useEffect(() => {
        visibleCardsRef.current.forEach(c => {
            delete c.isPresented
        })
        repositionCards()
    }, [props.toggleRemovePresented])

    useEffect(() => {
        // PEUT ETRE FAIRE MIEUX ?
        let tmp = []
        props.opponentStackCards.forEach((c) => {
            let card = JSON.parse(JSON.stringify(c))
            if (!c.id.includes("STACK-")) {
                card.id = "STACK-" + c.id
            }
            tmp.push(card)
        })
        setOpponentStackCards(tmp)
    }, [props.opponentStackCards.length])

    useEffect(() => {
        // update ref bounding rect
        if (!sections) { return }
        let newSections = { ...sections }
        newSections.allSections.forEach((s, i) => {
            newSections.sectionsDict[s].sectionBoundingClientRect = newSections.sectionsDict[s].sectionRef.current.getBoundingClientRect()
        })
        setSections(newSections)

        repositionCards()
    }, [cardSize, sections])

    useEffect(() => {
        visibleCardsRef.current = visibleCards
        //sendData(visibleCardsRef.current, deck)
    }, [visibleCards, deck])

    useEffect(() => {
        sendData(visibleCardsRef.current, deck, topCardHiddenTo)
    }, [topCardHiddenTo])

    useEffect(() => {
        let newTopCardHiddenTo = { ...topCardHiddenTo }
        if (!newTopCardHiddenTo["KEEP"]) {
            const tmp = [...props.opponentIds, props.playerId]
            tmp.forEach(id => {
                newTopCardHiddenTo[id] = true
            })
        }
        setTimeout(() => {
            setTopCardHiddenTo(newTopCardHiddenTo)
        }, 100)
    }, [deck, deck.length])

    useEffect(() => {
        repositionCards([SECTIONS.STACK])
    }, [opponentStackCards.length])

    useEffect(() => {
        if (gameCards) {
            let tokens = []
            Object.entries(gameCards).map(([k, v]) => {
                if (v.isToken) {
                    tokens.push({ ...v })
                }
            })
            setAvailableTokens(tokens)
        }
    }, [gameCards])

    useEffect(() => {
        if (sectionsCardsRef.current["Mana"] && sectionsCardsRef.current["Mana"].length > 0) {
            // Cancel highlight
            sectionsCardsRef.current["Mana"].forEach((c, i) => {
                delete c.card.isManaCostHightlight
            })

            // Add highlight
            if (props.options.costAutoPay) {
                let manaHighlight = 0
                sectionsCardsRef.current["Mana"].forEach((c, i) => {
                    if (!c.card.isTapped && manaHighlight < hoverCardCost) {
                        c.card.isManaCostHightlight = true
                        manaHighlight += 1
                    }
                })
            }
            //refreshVisibleCards()
            repositionCards(["Mana"])
        }
    }, [hoverCardCost])

    /*-------------------------- PLAYER SECTION FUNCTIONS --------------------------*/

    function sendData(vc, d, dt) {
        props.sendData({
            visibleCards: vc,
            deck: d,
            deckTopCardHiddenTo: dt
        })
    }

    function newTurn() {
        // Only during your turn
        if (props.isYourTurn || props.gameOptions.format.newTurn.sharedTurn) {
            visibleCardsRef.current.forEach((c, i) => {
                if (c.position.section && !sections.sectionsDict[c.position.section].keepTappedNewTurn) {
                    c.isTapped = false
                }
            })
            if (props.gameOptions && props.gameOptions.format.newTurn.drawPerTurn) {
                draw(props.gameOptions.format.newTurn.drawPerTurn)
            } else {
                draw(1)
            }
            props.removeTemporaryTokens()
        }

        // When any player turn end
        visibleCardsRef.current.forEach((c, i) => {
            delete c.isPresented
        })
        setTimeout(() => {
            // TODO: Remove the timeout
            props.setCardsLinks([])
        }, 100)
    }

    function moveCard(card, targetSection) {
        let sectionData = sections.sectionsDict[targetSection]
        if (!sectionData) { return }
        let isHidden = sectionData.isHidden
        let isCardCurrentlyHidden = isCardHidden(card, props.playerId, props.opponentIds)

        const shouldCardAppearInHistory = (isCardCurrentlyHidden == "no" || isHidden == "no") || sectionData.showHiddenCardInHistory
        if (isCardCurrentlyHidden == "yes" && card.position.section != SECTIONS.DECK && card.position.section != SECTIONS.EXILE) {
            isHidden = "yes"
        }

        const movedWording = () => {
            if (targetSection == SECTIONS.DISCARD) {
                return "discarded"
            } else if (targetSection == SECTIONS.EXILE) {
                return "removed"
            } else if (targetSection == SECTIONS.EXILE_HIDDEN) {
                return "removed face down"
            } else if (card.position.section == SECTIONS.DISCARD || card.position.section == SECTIONS.EXILE) {
                if (targetSection == SECTIONS.HAND) {
                    return "returned"
                } else {
                    return "played"
                }
            } else if (card.position.section == SECTIONS.DECK && targetSection == SECTIONS.HAND) {
                return "drawed"
            } else {
                return "played"
            }
        }
        if (targetSection == card.position.section) {
            // Aucun historique si la carte n'a pas bougé
        } else if (card.position.section == SECTIONS.HAND) {
            props.sendToHistory(movedWording() + " from hand", shouldCardAppearInHistory ? card : false)
        } else if (card.position.section == SECTIONS.DISCARD) {
            props.sendToHistory(movedWording() + " from discard", card)
        } else if (card.position.section == SECTIONS.EXILE) {
            props.sendToHistory(movedWording() + " from remove", card)
        } else if (card.position.section == SECTIONS.EXILE) {
            props.sendToHistory(movedWording() + " from remove face down", card)
        } else if (card.position.section == SECTIONS.DECK && !card.isToken) {
            if (targetSection == SECTIONS.HAND) {
                props.sendToHistory(movedWording() + " from deck")
            } else {
                props.sendToHistory(movedWording() + " from deck", card)
            }
        } else if (card.position.section != SECTIONS.STACK) {
            if (targetSection == SECTIONS.DISCARD || targetSection == SECTIONS.EXILE) {
                props.sendToHistory(movedWording(), card)
            } else if (targetSection == SECTIONS.HAND) {
                props.sendToHistory("returned to hand", shouldCardAppearInHistory ? card : false)
            } else if (targetSection == SECTIONS.EXILE_HIDDEN) {
                props.sendToHistory(movedWording(), shouldCardAppearInHistory ? card : false)
            }
        }


        // Delete if token/effect getting out of the board
        if (card.isToken) {
            if ([SECTIONS.EXILE, SECTIONS.EXILE_HIDDEN, SECTIONS.DECK, SECTIONS.HAND, SECTIONS.DISCARD].includes(targetSection)) {
                let index = visibleCardsRef.current.findIndex((vc) => vc.id === card.id)
                if (index != -1) {
                    visibleCardsRef.current.splice(index, 1)
                    handleGrouppedCardMove(card, sectionData)
                    return
                }
            }
        }
        if (card.isTriggerEffect && targetSection != SECTIONS.STACK) {
            let index = visibleCardsRef.current.findIndex((vc) => vc.id === card.id)
            if (index != -1) {
                visibleCardsRef.current.splice(index, 1)
                return
            }
        }

        // Remove counters if leaving the board
        if ([SECTIONS.EXILE, SECTIONS.DECK, SECTIONS.HAND, SECTIONS.DISCARD].includes(targetSection)) {
            card.counters = [{}, {}]
        }


        let w = sectionData.cardWidth
        let h = sectionData.cardHeight

        const isCardHorizontal = (card.isFlipped ? card.cardData.face.back.isHorizontal : card.cardData.face.front.isHorizontal) && sectionData.isHorizontalAllowed
        const cardSize = {
            width: isCardHorizontal ? h : w,
            height: isCardHorizontal ? w : h
        }

        handleGrouppedCardMove(card, sectionData)

        const originalSection = card.position.section
        // Keep position if its just a refresh
        if (originalSection == targetSection) {
            card.position = { ...card.position }
            card.position.cardSize = cardSize
        } else {
            card.position = {
                section: targetSection,
                cardSize: cardSize
            }
        }
        if (targetSection != originalSection) {
            hideCard(card, isHidden, props.playerId, props.opponentIds)
        }
        card.isHorizontal = isCardHorizontal
        if (isHidden === "yes") {
            card.isHorizontal = false
            card.position.cardSize = {
                width: w,
                height: h
            }
        }
        if (!card.isTapped) {
            card.isTapped = sectionData.enterTapped
        }

        if (targetSection == SECTIONS.STACK) {
            let allCards = [...visibleCardsRef.current, ...opponentStackCards]
            let stackCount = 0
            allCards.forEach((c, i) => {
                if (c.position.section == SECTIONS.STACK) {
                    stackCount += 1
                }
            })

            card.isPresented = false
            card.stackIndex = stackCount
        } else {
            delete card.stackIndex
        }

        // Untap if its leaving the board
        if ([SECTIONS.HAND, SECTIONS.DISCARD, SECTIONS.EXILE].includes(targetSection)) {
            card.isTapped = false
            delete card.isPresented
        }


        cardDraggedRef.current = false


        // TAP MANA
        if (props.options.costAutoPay && props.currentActionMode.action != ACTION_MODES.FREE_PLAY && originalSection != targetSection && (originalSection && sections.sectionsDict[originalSection].autoPayFrom && !sectionData.noAutoPayTo) && sectionsCardsRef.current["Mana"] && sectionsCardsRef.current["Mana"].length > 0) {
            let manaTapped = 0
            sectionsCardsRef.current["Mana"].forEach((c, i) => {
                if (!c.card.isTapped && manaTapped < card.cardData.cost) {
                    c.card.isTapped = true
                    manaTapped += 1
                }
            })
            /*
                        visibleCardsRef.current.forEach((c, i) => {
                            if (manaToTap.includes(c.id)) {
                                console.log("tapping")
                                visibleCardsRef.current[i].isTapped = true
                            }
                        })*/
        }
        setHoverCardCost(0)
        //sections.sectionsCards["Mana"][0].isTapped = true
    }

    function handleGrouppedCardMove(card, sectionData) {
        const w = sectionData.cardWidth
        const h = sectionData.cardHeight
        const isCardHorizontal = (card.isFlipped ? card.cardData.face.back.isHorizontal : card.cardData.face.front.isHorizontal) && sectionData.isHorizontalAllowed
        const cardSize = {
            width: isCardHorizontal ? h : w,
            height: isCardHorizontal ? w : h
        }

        // If groupped to someone, remove it
        if (card.grouppedToId !== undefined) {
            let index = visibleCardsRef.current.findIndex((a) => a.id === card.grouppedToId)
            let parent = index >= 0 ? visibleCardsRef.current[index] : false
            if (parent && parent.grouppedCards) {
                let index = parent.grouppedCards.findIndex(child => child.id == card.id)
                if (index !== -1) {
                    parent.grouppedCards.splice(index, 1)
                    if (parent.grouppedCards.length == 0) {
                        delete parent.grouppedCards
                    }
                }
            }
            delete card.grouppedToId
            repositionCards()
        }

        if (card.grouppedCards) {
            if (sectionData.isGroupForbidden) {
                card.grouppedCards.forEach((child, j) => {
                    delete child.grouppedToId
                    if (child.position) {
                        child.position.section = card.position.section
                        child.position.index = 99999
                    }
                })
                delete card.grouppedCards
            } else {
                card.grouppedCards.forEach((child, j) => {
                    const isChildHorizontal = child.isHorizontal && sectionData.isHorizontalAllowed
                    let childCardSize = {
                        width: isChildHorizontal ? h : w,
                        height: isChildHorizontal ? w : h
                    }
                    if (child.position) {
                        child.position.cardSize = childCardSize
                    }
                })
            }
        }
    }

    // Reposition all cards in visibleCards
    function repositionCards(changedSections) {
        //props.sendToHistory("REPOSITIONNING CARDS")
        if (!sections) { return }
        sectionsCardsRef.current = {}
        sections.allSections.forEach((s) => {
            sectionsCardsRef.current[s] = []
        })

        let newVisibleCards = [...visibleCardsRef.current]
        let allCards = [...newVisibleCards, ...opponentStackCards]

        allCards.forEach((c, i) => {
            if (c.position.section) {
                sectionsCardsRef.current[c.position.section].push({ card: c, index: i })
            }
        })

        function setPositions(array, sectionRect, alignment) {
            if (!sectionRect) { return }
            let rect = sectionRect

            let n = 0
            array.forEach((data, i) => {
                if (data.card.position.index === undefined) {
                    array[i].card.position.index = 99999
                }
            })

            let totalWidth = 0
            array.forEach((c) => {
                if (c.card.isHorizontal && c.card.isTapped) {
                    totalWidth += c.card.position.cardSize.height
                } else {
                    totalWidth += c.card.position.cardSize.width
                }
                if (c.card.grouppedCards) {
                    totalWidth += c.card.grouppedCards.length * (c.card.position.cardSize.width / 2)
                }
            })
            totalWidth = totalWidth * cardSize.vh
            // Alignment
            let alignmentOffset = 0
            if (alignment == SECTIONS.ALIGNMENTS.CENTER) {
                alignmentOffset = rect.width / 2 - Math.min(totalWidth, rect.width) / 2
            } else if (alignment == SECTIONS.ALIGNMENTS.END) {
                alignmentOffset = rect.width
            }

            // Too many cards
            let shrinkOffset = 0
            if (totalWidth > rect.width) {
                let tooMuch = totalWidth - rect.width
                shrinkOffset = tooMuch / (array.length - 1)
            }

            array.sort((a, b) => a.card.position.index - b.card.position.index);
            let cumulatedWidth = 0

            array.forEach((data, i) => {
                const card = allCards[data.index]
                const grouppedChildrenSpacing = (card.position.cardSize.width / 2) * cardSize.vh
                let childTotalWidth = 0
                if (card.grouppedCards) {
                    childTotalWidth = grouppedChildrenSpacing * card.grouppedCards.length
                }

                card.position.index = i
                card.position.reversedIndex = array.length - i - 1
                if (card.position.section == SECTIONS.STACK) {
                    card.position.reversedIndex = array.length - card.stackIndex
                    card.position.index = 100 + card.stackIndex
                }

                if (alignment == SECTIONS.ALIGNMENTS.NONE) {
                    card.position.pos = { left: rect.left, top: rect.top }
                } else if (alignment == SECTIONS.ALIGNMENTS.END) {
                    card.position.pos = { left: rect.left + alignmentOffset - cumulatedWidth + (i > 0 ? shrinkOffset * i : 0) - childTotalWidth - card.position.cardSize.width * cardSize.vh, top: rect.top }
                } else {
                    card.position.pos = { left: rect.left + cumulatedWidth + alignmentOffset - (i > 0 ? shrinkOffset * i : 0) + childTotalWidth, top: rect.top }
                }
                let cWidth = card.position.cardSize.width * cardSize.vh
                if (card.isHorizontal && card.isTapped) {
                    cWidth = card.position.cardSize.height * cardSize.vh
                }

                // Groupped cards
                if (card.grouppedCards) {
                    let cPos = { ...card.position.pos }
                    card.grouppedCards.forEach((c, j) => {
                        if (alignment == SECTIONS.ALIGNMENTS.END) {
                            c.position.pos = {
                                left: cPos.left + grouppedChildrenSpacing * (j + 1),
                                top: cPos.top + card.position.cardSize.height * cardSize.vh * 0.1
                            }
                        } else {
                            c.position.pos = {
                                left: cPos.left - grouppedChildrenSpacing * (j + 1),
                                top: cPos.top + card.position.cardSize.height * cardSize.vh * 0.1
                            }
                        }
                        c.position.index = i - 10 - j
                    })
                }

                cWidth += childTotalWidth
                cumulatedWidth += cWidth
            })
        }

        sections.allSections.forEach((s) => {
            if (!changedSections || changedSections.includes(s)) {
                // Reorganise le stackindex
                if (s == SECTIONS.STACK) {
                    // sort
                    sectionsCardsRef.current[s].sort((a, b) => a.card.stackIndex - b.card.stackIndex)
                    sectionsCardsRef.current[s].forEach((c, i) => {
                        c.card.stackIndex = i + 1
                    })
                }
                setPositions(sectionsCardsRef.current[s], sections.sectionsDict[s].sectionBoundingClientRect, sections.sectionsDict[s].alignment)
                // Update pour le bouton play latest from stack
                if (s == SECTIONS.STACK) {
                    let latest = false
                    sectionsCardsRef.current[s].forEach((c) => {
                        if (c.card.position.reversedIndex == 0) {
                            let destination = sections.autoPlayFromStack[c.card.cardData.type]
                            let isOpponent = c.card.id.includes("STACK")

                            if (!isOpponent && destination) {
                                latest = c.card
                            }
                        }
                    })
                    setLatestSackCard(latest)
                }
            }
        })

        setSections(sections)
        setVisibleCards(newVisibleCards)
        visibleCardsRef.current = newVisibleCards
        sendData(visibleCardsRef.current, deck, topCardHiddenTo)
    }

    function refreshVisibleCards() {
        setVisibleCards([...visibleCardsRef.current])
    }

    function draw(x, customDeck) {
        let newDeck = customDeck ? [...customDeck] : [...deck]  // A virer quand le draw initial sera avec un bouton
        let newCards = newDeck.splice(-x)
        setDeck(newDeck)

        let rect = sections.sectionsDict[SECTIONS.DECK].sectionBoundingClientRect

        let handSectionData = sections.sectionsDict[SECTIONS.HAND]

        newCards.forEach((c, i) => {
            let newCard = { ...c }
            newCard.position = {
                section: SECTIONS.DECK,
                pos: { left: rect.left, top: rect.top },
                cardSize: {
                    width: handSectionData.cardWidth,
                    height: handSectionData.cardHeight
                }
            }
            hideCard(newCard, "yes", props.playerId, props.opponentIds)

            visibleCardsRef.current.push(newCard)
            newCards[i] = newCard
        })
        repositionCards([])

        setTimeout(() => {
            newCards.forEach((c) => {
                hideCard(c, "opponent-only", props.playerId, props.opponentIds)
                c.position = {
                    section: SECTIONS.HAND,
                    pos: c.position.pos,
                    cardSize: c.position.cardSize
                }
            })
            repositionCards([SECTIONS.HAND])
        }, 100)
        if (newCards.length > 0) {
            props.sendToHistory("draw " + newCards.length)
        }
    }

    function clickCard(cardIndex) {
        let card = visibleCardsRef.current[cardIndex]
        const cardSection = card.position.section
        if (props.currentActionMode.action == ACTION_MODES.PING) {
            // PING
            props.pingCard(card)
        } else if (props.currentActionMode.action == ACTION_MODES.LINK_FROM || props.currentActionMode.action == ACTION_MODES.LINK_TO) {
            // LINK BETWEEN CARDS
            if (!props.currentActionMode.actionData) {
                props.setCurrentActionMode({
                    action: ACTION_MODES.LINK_TO,
                    actionData: {
                        id: uuidv4(),
                        fromCardName: card.cardData.name,
                        fromSection: card.position.section,
                        from: card.id,
                        to: false,
                        toSection: false
                    }
                })
            } else {
                let linkData = props.currentActionMode.actionData
                linkData.to = card.id
                linkData.toSection = card.position.section
                let tmp = [...props.cardsLinks]
                tmp.push(linkData)
                props.setCardsLinks(tmp)
                if (linkData.isFromQuickAction) {
                    props.setCurrentActionMode({
                        action: ACTION_MODES.NONE,
                        actionData: false
                    })
                } else {
                    props.setCurrentActionMode({
                        action: ACTION_MODES.LINK_FROM,
                        actionData: false
                    })
                }
            }
        } else if (props.currentActionMode.action == ACTION_MODES.PRESENT) {
            // PRESENT CARD TO OPPOENENTS
            if (![SECTIONS.HAND, SECTIONS.DISCARD, SECTIONS.EXILE, SECTIONS.STACK].includes(card.position.section)) {
                card.isPresented = !card.isPresented
                repositionCards([cardSection])
            }
        } else if (props.currentActionMode.action == ACTION_MODES.DISCARD) {
            // DISCARD
            moveCard(card, SECTIONS.DISCARD)
            repositionCards([cardSection, SECTIONS.DISCARD])
        } else if (props.currentActionMode.action == ACTION_MODES.REMOVE) {
            // REMOVE
            moveCard(card, SECTIONS.EXILE)
            repositionCards([cardSection, SECTIONS.EXILE])
        } else if (props.currentActionMode.action == ACTION_MODES.CARD_EFFECT) {
            // CARD EFFECT
            let c = JSON.parse(JSON.stringify(card))
            c.id = uuidv4()
            c.isTriggerEffect = true
            delete c.tokenCount
            delete c.counters
            let tmp = [...visibleCardsRef.current]
            tmp.push(c)
            setVisibleCards(tmp)
            setTimeout(() => {
                moveCard(c, SECTIONS.STACK)
                repositionCards([cardSection, SECTIONS.STACK])
            }, 100)
        } else if (card.position.section == SECTIONS.HAND) {
            let type = card.isFlipped ? card.cardData.face.back.type : card.cardData.face.front.type
            let destination = sections.autoPlayFromHand[type]
            if (destination) {
                moveCard(card, destination)
                repositionCards([destination, SECTIONS.HAND])
            }
        } else if (card.position.section == SECTIONS.STACK) {

        } else if (card.position.section == SECTIONS.DISCARD) {
        } else {
            // PASSER EN REF
            if (props.currentActionMode.action == ACTION_MODES.GROUP) {
                let childCard = visibleCardsRef.current[props.currentActionMode.actionData.cardToGroupIndex]

                if (childCard.id == card.id) { return } // Pas de groupe avec sois même
                if (card.grouppedCards) {
                    let alreadyAChildren = false
                    card.grouppedCards.forEach((child) => {
                        if (child.id == childCard.id) {
                            alreadyAChildren = true
                        }  
                    })
                    if (alreadyAChildren) { return }
                }
                if (!card.position.section) { return }

                handleGrouppedCardMove(childCard, sections.sectionsDict[card.position.section])

                // A VOIR POUR HORIZONTAL
                const cardSize = {
                    width: card.isHorizontal != childCard.isHorizontal ? card.position.cardSize.height : card.position.cardSize.width,
                    height: card.isHorizontal != childCard.isHorizontal ? card.position.cardSize.width : card.position.cardSize.height
                }

                childCard.position = {
                    section: false,
                    cardSize: cardSize
                }

                childCard.grouppedToId = card.id

                if (card.grouppedCards) {
                    card.grouppedCards.push(childCard)
                } else {
                    card.grouppedCards = [childCard]
                }

                props.setCurrentActionMode({
                    action: ACTION_MODES.NONE,
                    actionData: false
                })
                repositionCards()
            } else {
                visibleCardsRef.current[cardIndex].isTapped = !visibleCardsRef.current[cardIndex].isTapped
                repositionCards()
                /*
                visibleCardsRef.current[cardIndex].position.cardSize = {
                    width: visibleCardsRef.current[cardIndex].position.cardSize.height,
                    height: visibleCardsRef.current[cardIndex].position.cardSize.width
                }*/
            }
        }
    }

    function createToken(c) {
        let rect = sections.sectionsDict[SECTIONS.DECK].sectionBoundingClientRect
        let handSectionData = sections.sectionsDict[SECTIONS.DECK]
        let newCard = createGameCardFromCardData(c)
        newCard.isToken = true
        newCard.tokenCount = 1

        newCard.position = {
            section: SECTIONS.DECK,
            pos: { left: rect.left, top: rect.top },
            cardSize: {
                width: handSectionData.cardWidth,
                height: handSectionData.cardHeight
            }
        }
        hideCard(newCard, "yes", props.playerId, props.opponentIds)

        visibleCardsRef.current.push(newCard)

        setTimeout(() => {
            let destination = sections.autoPlayFromStack[newCard.cardData.type]
            destination = destination ? destination : SECTIONS.STACK
            moveCard(newCard, destination)
            repositionCards([destination])
        }, 100)

        props.sendToHistory("created token " + newCard.cardData.name)
    }

    function giveCardAtIndexTo(cardIndex, destination) {
        let card = visibleCardsRef.current[cardIndex]
        if (!card) { return }
        //let cardSection = card.position.section
        
        props.giveCardTo(card, destination)
        visibleCardsRef.current.splice(cardIndex, 1)
        repositionCards()
    }






    if (!sections) {
        return (
            <div className={"d-flex flex-column player-section w-100 h-50 position-relative" + (props.isYourTurn ? " current-player" : "")}>
                {props.profileData && (<div id={"playmat-" + props.playerId} className="background player-playmat position-absolute start-0 top-0 w-100 h-100" style={{ backgroundImage: "url(" + props.profileData.background + ")" }}></div>)}
            </div>
        )
    }

    return (
        <div className={"d-flex flex-column player-section w-100 h-50 position-relative" + (props.isYourTurn ? " current-player" : "")}>
            {props.profileData && (<div id={"playmat-" + props.playerId} className="background player-playmat position-absolute start-0 top-0 w-100 h-100" style={{ backgroundImage: "url(" + props.profileData.background + ")" }}></div>)}
            <div className="position-relative d-flex flex-column w-100 h-100 justify-content-after">
                <div style={{ height: '38vh' }}>
                    <LayoutBoardSection
                        sectionLayout={sections.layout}
                        cardDraggedRef={cardDraggedRef}
                        moveCard={moveCard}
                        sections={sections}
                        sectionsCardsRef={sectionsCardsRef}
                        repositionCards={repositionCards}
                        sendToHistory={props.sendToHistory}
                        playerId={props.playerId}
                        opponentIds={props.opponentIds}
                    />
                </div>
                <Hand
                    cardDraggedRef={cardDraggedRef}
                    moveCard={moveCard}
                    handRef={handRef}
                    repositionCards={repositionCards}
                    sectionCards={sectionsCardsRef.current[SECTIONS.HAND]}
                    sendToHistory={props.sendToHistory}
                    playerId={props.playerId}
                    opponentIds={props.opponentIds}
                />
                <Deck
                    deck={deck}
                    setDeck={setDeck}
                    deckRef={deckRef}
                    draw={draw}
                    repositionCards={repositionCards}
                    moveCard={moveCard}
                    sections={sections}
                    visibleCardsRef={visibleCardsRef}
                    sendToHistory={props.sendToHistory}
                    playerId={props.playerId}
                    opponentIds={props.opponentIds}
                    currentActionMode={props.currentActionMode}
                    opponentsData={props.opponentsData}
                    setFocusedCard={props.setFocusedCard}
                    topCardHiddenTo={topCardHiddenTo}
                    setTopCardHiddenTo={setTopCardHiddenTo}
                />
                <Discard
                    cardDraggedRef={cardDraggedRef}
                    discardRef={discardRef}
                    moveCard={moveCard}
                    repositionCards={repositionCards}
                    sectionCards={sectionsCardsRef.current[SECTIONS.DISCARD]}
                    exileSectionCard={sectionsCardsRef.current[SECTIONS.EXILE]}
                    exileHiddenSectionCard={sectionsCardsRef.current[SECTIONS.EXILE_HIDDEN]}
                />
                <Stack
                    cardDraggedRef={cardDraggedRef}
                    stackRef={props.stackRef}
                    moveCard={moveCard}
                    repositionCards={repositionCards}
                    latestStackCard={latestStackCard}
                    sections={sections}
                    displayedTitle={sections.sectionsDict[SECTIONS.STACK].displayedTitle}
                />
            </div>
            <div className='visible-cards position-fixed start-0 top-0 w-100 h-100 pe-none'>
                {visibleCards.map((c, i) => {
                    return (<Card
                        key={c.id}
                        card={c}
                        cardDraggedRef={cardDraggedRef}
                        clickCard={() => clickCard(i)}
                        deck={deck}
                        cards={visibleCardsRef.current}
                        index={i}
                        repositionCards={repositionCards}
                        setCurrentActionMode={props.setCurrentActionMode}
                        setFocusedCard={props.setFocusedCard}
                        opponentIds={props.opponentIds}
                        opponentsData={props.opponentsData}
                        giveCardAtIndexTo={giveCardAtIndexTo}
                        moveCard={moveCard}
                        setHoverCardCost={setHoverCardCost}
                        refreshVisibleCards={refreshVisibleCards}
                        currentActionMode={props.currentActionMode}
                        sendToHistory={props.sendToHistory}
                        playerId={props.playerId}
                        setIsTextInputFocused={props.setIsTextInputFocused}
                        gameOptions={props.gameOptions}
                    />)
                })}
                {opponentStackCards.map((c, i) => {
                    return (<Card
                        key={c.id}
                        card={c}
                        cardDraggedRef={cardDraggedRef}
                        clickCard={() => { }}
                        isOpponentCard={true}
                        setFocusedCard={props.setFocusedCard}
                        currentActionMode={props.currentActionMode}
                    />)
                })}
            </div>
            <TokensModal createToken={createToken} tokens={availableTokens} show={props.showTokensModal} handleClose={() => { props.setShowTokensModal(false) }} />
            <DeckPickerModal
                show={showDeckPickerModal}
                handleClose={() => { setShowDeckPickerModal(false) }}
                currentGame={currentGame}
                gameData={gameData}
                createGameCardFromCardData={createGameCardFromCardData}
                sendToHistory={props.sendToHistory}
                start={(newDeck, handSize, cardsOnBoard) => {
                    setDeck(newDeck)
                    draw(handSize, newDeck)

                    cardsOnBoard.forEach((c, i) => {
                        let rect = sections.sectionsDict[c.section].sectionBoundingClientRect
                        let sectionData = sections.sectionsDict[c.section]

                        let newCard = { ...c.card }
                        newCard.position = {
                            section: c.section,
                            pos: { left: rect.left, top: rect.top },
                            cardSize: {
                                width: sectionData.cardWidth,
                                height: sectionData.cardHeight
                            }
                        }
                        hideCard(newCard, sectionData.isHidden, props.playerId, props.opponentIds)
                        newCard.isHorizontal = c.card.cardData.face.front.isHorizontal
                        if (newCard.isHorizontal) {
                            newCard.position.cardSize = {
                                width: sectionData.cardHeight,
                                height: sectionData.cardWidth
                            }
                        }

                        visibleCardsRef.current.push(newCard)
                        cardsOnBoard[i] = newCard
                    })

                    repositionCards()

                    setTimeout(() => {
                        sendData(visibleCardsRef.current, newDeck, topCardHiddenTo)
                    }, 100)
                }}
                started={() => {
                    if (props.gameOptions && props.gameOptions.format.newTurn.drawOnStart) {
                        newTurn()
                    }
                }}
                gameOptions={props.gameOptions}
            />
        </div>
    )
}